import { useState, useEffect } from "react"

export interface DX {
    id: number
    code: string
    description: string
}

export const useDXList = () => {
    const [dxs, setDXs] = useState<DX[]>([])
    const [err, setErr] = useState<string | undefined>()

    useEffect(() => {
        const doFetch = async () => {
            try {
                const res = await fetch(`/v2/dx`)
                const json = await res.json()

                setDXs(json)
            } catch (e) {
                setErr("something went wrong")
            }
        }

        doFetch()
    }, [])

    return {
        dxs
        , err
    }
}