import { useState, useEffect, useCallback } from "react"
import { dispatchEvent } from "./hooks/useEvent"
import { getCookie } from "./common/jwt"

export const useAuth = () => {
    const [initialized, setInitialized] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)

    const logout = useCallback(() => {
        localStorage.removeItem("chargebook_last_activity")
        window.location.href = `/v2/auth/logout`
    }, [])

    const getAccessToken = () => {
        return new Token(getCookie("access_token"))
    }

    useEffect(() => {
        const doEffect = async () => {
            setInitialized(true)
            setLoggedIn(getCookie("access_token") !== "")

            const restLastActivity = () => {
                localStorage.setItem("chargebook_last_activity", Date.now().toString())
                dispatchEvent("clearTimeoutOverlay", {})
            }

            document.addEventListener("mousemove", restLastActivity, false);
            document.addEventListener("mousedown", restLastActivity, false);
            document.addEventListener("keypress", restLastActivity, false);
            document.addEventListener("touchmove", restLastActivity, false);

            window.setInterval(async () => {
                const lastActivityStr = localStorage.getItem("chargebook_last_activity")

                if (lastActivityStr) {
                    const inactive = (Date.now() - parseInt(lastActivityStr))

                    if (inactive > 840 * 1000) {
                        dispatchEvent("displayTimeoutOverlay", {})

                        if (inactive > 900 * 1000) {
                            logout()
                        }
                    } else {
                        dispatchEvent("clearTimeoutOverlay", {})
                    }
                }
            }, 1000)

            window.setInterval(async () => {
                console.log("refreshing tokens")

                const res = await fetch(`/v2/auth/heartbeat`)

                if (res.status !== 200) {
                    logout()
                }
            }, 30 * 1000)
        }

        doEffect()
    }, [])

    return {
        logout
        , getAccessToken
        , loggedIn
        , initialized
    }
}

export class Token {
    tokenString: string = ""

    constructor(tokenString: string) {
        if (tokenString && tokenString.split(".").length === 3) {
            this.tokenString = tokenString
        }
    }

    payload(): any | null {
        const segments = this.tokenString.split(".")

        if (segments.length !== 3) {
            return null
        }

        return JSON.parse(atob(segments[1]))
    }

    tenantId(): number {
        return Number(localStorage.getItem("tenant_id")!)
    }

    isValid(): boolean {
        if (this.tokenString === "") {
            return false
        }

        if (this.payload()?.exp - Math.floor(Date.now() / 1000) < 1) {
            return false
        }

        return true
    }
}