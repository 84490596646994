import Axios from "../common/axios"
import * as _ from "lodash"

export enum AlertAction {
	DISMISS_WAITING = "AlertAction.DISMISS_WAITING"
	, DISMISS_COMPLETED = "AlertAction.DISMISS_COMPLETED"
	, DISMISS_ERROR = "AlertAction.DISMISS_ERROR"
}

export interface AlertPayload {
	type: AlertAction
	, data?: any
}

export const dismiss = (alert: any) => (dispatch: (payload: AlertPayload) => AlertPayload, getState: () => any) => {
	console.log("dismiss waiting")
	dispatch({ type: AlertAction.DISMISS_WAITING })

	const clonedAlert = _.cloneDeep(alert)
	clonedAlert.attributes.dismissed = true;

	Axios.post("/api/alert/" + clonedAlert.id, { data: clonedAlert })
	.then(() => {
		console.log("dismiss completed")
		dispatch({ type: AlertAction.DISMISS_COMPLETED, data: clonedAlert })
	})
}
