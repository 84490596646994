import React from "react"
import { connect } from "react-redux"
import { Props as SelectProps } from "react-select"
import Select from "./Select"
import { AppState } from "../../reducers"

interface Props {
	onChangeModality?: (modality: any) => void
	withLabel?: boolean
	required?: boolean
}

interface StateProps {
	modalities: any[]
}

const ModalitySelect = (props: Props & StateProps & SelectProps) => {
	const { modalities, onChangeModality, withLabel, ...restProps } = props

	const handleChange = (insurance: any) => {
		if (onChangeModality) {
			onChangeModality(insurance)
		}
	}

	return <Select required items={modalities} label={withLabel ? "Modality" : ""} onChange={handleChange} {...restProps} />
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => {
	return {
		modalities: state.modality.data
	}
}

export default connect(mapStateToProps)(ModalitySelect)