import { ChargePayload, ChargeAction } from "../actions/ChargeActions"

export interface ChargeState {
    loading: boolean
    saveWaiting: boolean
    filter: any
    pagination: any
    data: any[]
    included: any[]
}

export const defaultChargeState: ChargeState = {
    loading: false
    , saveWaiting: false
    , filter: {}
    , pagination: {
        limit: 0
        , offset: 0
        , count: 0
    }
    , data: []
    , included: []
}

const reducer = (state: ChargeState, payload: ChargePayload): ChargeState => {
    const nextState = { ...state }

    switch (payload.type) {
        case ChargeAction.FETCH_CHARGES_WAITING:
            nextState.loading = true
            nextState.filter = { ...payload.filter }
            break
        case ChargeAction.FETCH_CHARGES_COMPLETED:
            nextState.loading = false
            nextState.data = [...payload.response.data.data]
            nextState.included = [...payload.response.data.included]
            nextState.pagination = { ...payload.response.data.pagination }
            break
        case ChargeAction.FETCH_MORE_COMPLETED:
            nextState.loading = false

            nextState.data = nextState.data.concat([...payload.data.response.data])
            nextState.included = nextState.included.concat([...payload.data.response.included])
            nextState.pagination = { ...payload.data.response.pagination }
            break


        case ChargeAction.SET_STATUS_COMPLETED:
            for (const charge of nextState.data) {
                if (payload.response.chargeIds.indexOf(charge.id) !== -1) {
                    charge.relationships.status.data.id = payload.response.statusId
                }
            }
            break
        case ChargeAction.ADD_CHARGE_WAITING:
        case ChargeAction.UPDATE_CHARGE_WAITING:
            nextState.saveWaiting = true
            break
        case ChargeAction.ADD_CHARGE_COMPLETED:
        case ChargeAction.UPDATE_CHARGE_COMPLETED:
            nextState.saveWaiting = false
            const updatedData = [...payload.response.data.data]

            for (let d of updatedData) {
                const index = nextState.data.findIndex((dd: any) => dd.id === d.id && dd.type === d.type)
    
                if (index === -1) {
                    nextState.data.push(d)
                } else {
                    nextState.data.splice(index, 1, d)
                }
            }

            const updatedIncluded = [...payload.response.data.included]

            for (let i of updatedIncluded) {
                const index = nextState.included.findIndex((ii: any) => ii.id === i.id && ii.type === i.type)
    
                if (index === -1) {
                    nextState.included.push(i)
                } else {
                    nextState.included.splice(index, 1, i)
                }
            }

            nextState.pagination = { ...payload.response.data.pagination }
            break
        case ChargeAction.TRANSITION_CHARGE_COMPLETED:
            const processes = [...payload.response.data.data]

            for (let process of processes) {
                nextState.included.splice(nextState.included.findIndex((i: any) => { return i.id === process.id && i.type === "Process" }), 1, process)
            }
            break
    }

    return nextState
}

export default reducer
