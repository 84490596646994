import React from "react"
import { connect } from "react-redux"
import { Props as SelectProps } from "react-select"
import Select from "./Select"
import { AppState } from "../../reducers"

interface Props {
	onChangePhysician: (physician: any) => void
	withLabel?: boolean
}

interface StateProps {
	physicians: any[]
}

const PhysicianSelect = (props: Props & StateProps & SelectProps) => {
	const { physicians, onChangePhysician, withLabel, ...restProps } = props

	const handleChange = (physician: any) => {
		onChangePhysician(physician)
	}

	return <Select items={physicians} label={withLabel ? "Physician" : ""} onChange={handleChange} {...restProps} />
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => {
	return {
		physicians: state.physician.data
	}
}

export default connect(mapStateToProps)(PhysicianSelect)