import Axios from "../common/axios";

export enum PhaseAction {
    FETCH_PHASES_WAITING = "PhaseAction.FETCH_PHASES_WAITING"
    , FETCH_PHASES_COMPLETED = "PhaseAction.FETCH_PHASES_COMPLETED"
    , FETCH_PHASES_ERROR = "PhaseAction.FETCH_PHASES_ERROR"

    , UPDATE_PHASE_WAITING = "PhaseAction.UPDATE_PHASE_WAITING"
    , UPDATE_PHASE_COMPLETED = "PhaseAction.UPDATE_PHASE_COMPLETED"
    , UPDATE_PHASE_ERROR = "PhaseAction.UPDATE_PHASE_ERROR"
}

export interface PhasePayload {
    type: PhaseAction
    , data: any
}

export const updateRaw = (tenantId: number, phase: any) => {
    return Axios.post(`/api/${tenantId}/phase/${phase.id}`, phase)
}

export const deleteRaw = (tenantId: number, phase: any) => {
    return Axios.delete(`/api/${tenantId}/phase/${phase.id}`)    
}

export const createRaw = (tenantId: number, phase: any, treatmentId: number) => {
    return Axios.post(`/api/${tenantId}/treatment/${treatmentId}/relationships/phase`, phase)
}

export class PhaseActions {
    fetch(tenantId: number, filter: any) {
        return (dispatch: (payload: PhasePayload) => PhasePayload, getState: () => any) => {
            const state = getState().phase;

            if (state.loading || (state.fetched && state.filter === filter)) {
                return;
            }

            dispatch({ type: PhaseAction.FETCH_PHASES_WAITING, data: { filter: filter } });

            return Axios.get(`/api/${tenantId}/phase`, {
                params: {
                    filter: filter
                }
            })
                .then((response: any) => {
                    dispatch({ type: PhaseAction.FETCH_PHASES_COMPLETED, data: { response: response, filter: filter } });
                })
                .catch((e: any) => {
                    dispatch({ type: PhaseAction.FETCH_PHASES_ERROR, data: e });
                    console.error(e);
                });
        }
    }

    update(url: string, data: any) {
        return (dispatch: (payload: PhasePayload) => PhasePayload, getState: () => any) => {
            dispatch({ type: PhaseAction.UPDATE_PHASE_WAITING, data: null });

            return Axios({
                method: 'post'
                , url: url
                , data: data
                , transformRequest: (data: any) => {
                    return JSON.stringify(data);
                }
            })
                .then((response: any) => {
                    dispatch({ type: PhaseAction.UPDATE_PHASE_COMPLETED, data: { response: response } });
                })
                .catch((e: any) => {
                    dispatch({ type: PhaseAction.UPDATE_PHASE_ERROR, data: e });
                    console.error(e);
                });
        }
    }
}