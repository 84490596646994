import React, { Attributes, useContext } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Table, Nav, Button } from "react-bootstrap"
import moment from 'moment'
import { AppState } from "../reducers"
import * as modalActions from "../actions/ModalActions"
import ChargeCell from "./ChargeCell"

import { byId, byRelationshipId } from "../common/jsonapi-filters"
import TreatmentContext from "../contexts/TreatmentContext"
import ChargeListContext from "../contexts/ChargeListContext"

interface PhaseProps {
	phase: any
	showNewPhase?: boolean
}

const MIPS = () => {
    const [charges] = useContext(ChargeListContext)
	const [, included] = useContext(TreatmentContext)
	const physicianList = useSelector((state: AppState) => state.physician)

	const byCategoryId = (categoryId: number) => (item: any) => {
		return item.meta.category_id == categoryId
	}


	return <div>
		<Table bordered hover style={{ marginBottom: 0 }} className="charge-table">
			<thead>
				<tr>
					<th colSpan={6}>
						<div className="clearfix">MIPS</div>
					</th>
				</tr>
				<tr>
					<th>Abbr</th>
					<th>DOS</th>
					<th>CPT</th>
					<th>MD</th>
					<th>Units</th>
				</tr>
			</thead>
			<tbody>
				{
					charges
						.filter(byCategoryId(13))
						.filter(byRelationshipId("Status", 3, false))
						.sort((a: any, b: any): number => new Date(a.attributes.dos).getTime() - new Date(b.attributes.dos).getTime())
						.map((charge: any, index: number) => {
							const physician = physicianList.data.find(byId(charge.relationships.physician.data.id))

							return <tr key={index}>
								<td>{charge.attributes.cpt.abbr}</td>
								<td>{moment(charge.attributes.dos).format("MM/DD/YYYY")}</td>
								<ChargeCell charge={charge} included={included} />
								<td>{physician.attributes.code}</td>
								<td>{charge.attributes.units}</td>
							</tr>
						})
				}
			</tbody>
		</Table>
	</div>
}

export default MIPS