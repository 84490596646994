export interface ReferringProvider {
    id: number
    name: string
    code: string
    externalId: number
}

export interface IReferringProviderRepo {
    listReferringProviders: (tenantId: number, search: string) => Promise<ReferringProvider[]>
}

export const defaultReferringProvider: ReferringProvider = {
    id: 0
    , name: ''
    , code: ''
    , externalId: 0
}