import React, { useContext } from "react"
import { connect, useDispatch } from "react-redux"
import { BsFillExclamationTriangleFill, BsFillExclamationOctagonFill, BsFillExclamationCircleFill } from "react-icons/bs"
import { FormCheck, OverlayTrigger, Popover } from "react-bootstrap"
import { AppState } from "../reducers"

import { select, unselect } from "../actions/ChargeMultiEditActions"
import { open } from "../actions/ModalActions"
import { byId, byType } from "../common/jsonapi-filters"
import { cloneDeep } from "lodash"
import AstriaContext from "../contexts/AstriaContext"

const CHARGE_STATUS_HELD = 1;
const CHARGE_STATUS_COMPLETE = 2;


export function colorForStateId(stateId: number, flags: number) : string {
    if(stateId === CHARGE_STATUS_HELD) {
        return "rgb(255, 235, 170)";
    }

    if(stateId === CHARGE_STATUS_COMPLETE && (flags & 1) === 1) {
        return "rgb(238, 238, 238)";
    }

    return "none";
}

const modifiers = (charge: any, cptModifierList: any) : string[] => {
    const either = (lh: string, rh: string) => lh ? lh : rh

    return [
        either(cptModifierList.data.find(byId(charge.relationships.modifiers.mod1.data?.id))?.attributes.name, charge.attributes.cpt.mod1)
        , either(cptModifierList.data.find(byId(charge.relationships.modifiers.mod2.data?.id))?.attributes.name, charge.attributes.cpt.mod2)
        , either(cptModifierList.data.find(byId(charge.relationships.modifiers.mod3.data?.id))?.attributes.name, charge.attributes.cpt.mod3)
        , either(cptModifierList.data.find(byId(charge.relationships.modifiers.mod4.data?.id))?.attributes.name, charge.attributes.cpt.mod4)
    ].filter((mod: string) => mod ? true : false)
}

interface ChargeCellProps {
    charge: any

    included?: any[]

    document?: any
}

interface ChargeCellStateProps {
    selectedChargeIds: number[]

    chargeStatusList: any
    facilityList: any
    insuranceList: any
    icd10List: any
    cptModifierList: any
}

const ChargeCell = (props: ChargeCellProps & ChargeCellStateProps) => {
    const dispatch = useDispatch()
    const [errors] = useContext(AstriaContext)

    const { charge, included, selectedChargeIds, facilityList, insuranceList, cptModifierList } = props

    if (!charge) {
        return <td></td>
    }

    let myErrors = []
    let myWarnings = []
    let myNotices = []

    for (let e of errors) {
        if (charge.id === e.id) {
            switch (e.type) {
                case "Notice":
                    myNotices.push(e)
                    break
                case "Warning":
                    myWarnings.push(e)
                    break
                default:
                    myErrors.push(e)
            }
        }
    }

    const selected = selectedChargeIds.findIndex((chargeId: number) => chargeId === charge.id) !== -1

    const background = colorForStateId(charge.relationships.status.data.id, charge.attributes.flags)
    const toggle = (e: any) => e.target.checked ? dispatch(select(charge.id)) : dispatch(unselect(charge.id))

   

    const facility = facilityList.data.find(byId(charge.relationships.facility.data.id))
    const insurance = insuranceList.data.find(byId(charge.relationships.insurance.data.id))
    const mods = modifiers(charge, cptModifierList)

    const openModal = (e: any) => {
        if (e.altKey) {
            const nextCharge = cloneDeep(charge)
            nextCharge.dos = [
                { date: null, ordinal: 1 }
            ]

            dispatch(open("NewCharge", { charge: nextCharge, phases: included!.filter(byType("Phase")), admissions: included!.filter(byType("Admission")) }))
            return
        }

        dispatch(open("EditCharge", { charge, phases: included!.filter(byType("Phase")), admissions: included!.filter(byType("Admission")) }))
    }

    return <td style={{ background: background }}>
        <div>
            <FormCheck inline checked={selected} onChange={toggle} className="code-checkbox" />
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover id="popover-positioned-scrolling-top">
                <ul className="popover-list" style={{ listStyle: "none", padding: "10px" }}>
                    <li><strong>Facility:</strong> {facility?.attributes?.code}</li>
                    <li><strong>Ins:</strong> {insurance?.attributes?.code}</li>
                    <li><strong>Units:</strong> {charge?.attributes?.units}</li>
                    <li><strong>Dx:</strong> {charge?.attributes?.diagnosis?.join(", ")}</li>
                </ul>
            </Popover>}>
                <span className="a text-primary" style={{ cursor: "pointer" }} onClick={openModal}>{charge.attributes.cpt.code}{mods.length ? "-" : ""}{mods.join(",")}{charge.attributes.otv ? " [OTV]" : null}</span>
            </OverlayTrigger>

            {
                myErrors.length ?
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover id="popover-positioned-scrolling-top">
                        <ul style={{ listStyle: "none", padding: "10px" }}>
                            {
                                myErrors.map((e: any) => <li key={e.name}>{e.name}</li>)
                            }
                        </ul>
                    </Popover>}>
                        <BsFillExclamationOctagonFill style={{ float: "right", marginTop: "4px", color: "rgb(229, 67, 67)" }} />
                    </OverlayTrigger> : null
            }

            {
                myWarnings.length ?
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover id="popover-positioned-scrolling-top">
                        <ul style={{ listStyle: "none", padding: "10px" }}>
                            {
                                myWarnings.map((e: any) => <li key={e.name}>{e.name}</li>)
                            }
                        </ul>
                    </Popover>}>
                        <BsFillExclamationTriangleFill style={{ float: "right", marginTop: "4px", color: "rgb(241, 180, 0)" }} />
                    </OverlayTrigger> : null
            }

            {
                myNotices.length ?
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover id="popover-positioned-scrolling-top">
                        <ul style={{ listStyle: "none", padding: "10px" }}>
                            {
                                myNotices.map((e: any) => <li key={e.name}>{e.name}</li>)
                            }
                        </ul>
                    </Popover>}>
                        <BsFillExclamationCircleFill style={{ float: "right", marginTop: "4px", color: "rgb(22, 135, 255)" }} />
                    </OverlayTrigger> : null
            }

        </div>
    </td>
}

const mapStateToProps = (state: AppState, ownProps: ChargeCellProps): ChargeCellStateProps => {
    return {
        selectedChargeIds: state.chargeMultiEdit.chargeIds
        , chargeStatusList: state.chargeStatus
        , facilityList: state.facility
        , insuranceList: state.insurance
        , icd10List: state.icd10
        , cptModifierList: state.cptModifier
    }
}

export default connect(mapStateToProps)(ChargeCell)