import Dropdown from "../Dropdown"
import withData from "../withData"

export default withData(`/v2/practices`, item => {
    const { id, name, code } = item
    return {
        value: id
        , name
        , code
    }
})(Dropdown)