import { Action, Payload } from "../../actions/modal/NewReferringPhysician"
import * as _ from "lodash"

export interface ReferringPhysicianAttributes {
	name: string
	externalId: string
}

export interface State {
	show: boolean
	referringPhysician: ReferringPhysicianAttributes
}

export const defaultState: State = {
	show: false
	, referringPhysician: {
		name: ""
		, externalId: ""
	}
}

export const reducer = (state: State = defaultState, payload: Payload): State => {
	let nextState = _.cloneDeep(state)

	switch (payload.type) {
		case Action.OPENED:
			nextState.show = true
			break
		case Action.CLOSED:
			nextState = _.cloneDeep(defaultState)
			break
		case Action.SET_NAME:
			nextState.referringPhysician.name = payload.data.name
			break
		case Action.SET_EXTERNAL_ID:
			nextState.referringPhysician.externalId = payload.data.externalId
			break
	}

	return nextState
}