interface Params {
    status?: string | null
    facilityName?: string | null
    sort?: string | null
    completedBenefitCheck?: string | null
    simulationDateStart?: string | null
    simulationDateEnd?: string | null
    search?: string | null
}

export function getState(location: string = window.location.search): Params {
    const searchParams = new URLSearchParams(location);

    const params: Params = {}

    if (searchParams.get('status')) {
        params.status = searchParams.get('status')
    }

    if (searchParams.get('facilityName')) {
        params.facilityName = searchParams.get('facilityName')
    }

    if (searchParams.get('sort')) {
        params.sort = searchParams.get('sort')
    }

    if (searchParams.get('completedBenefitCheck')) {
        params.completedBenefitCheck = searchParams.get('completedBenefitCheck')
    }

    if (searchParams.get('simulationDateStart')) {
        params.simulationDateStart = searchParams.get('simulationDateStart')
    }

    if (searchParams.get('simulationDateEnd')) {
        params.simulationDateEnd = searchParams.get('simulationDateEnd')
    }

    if (searchParams.get('search')) {
        params.search = searchParams.get('search')
    }

    return params
}

export function setState(params: Params): string {
    const searchParams = new URLSearchParams();

    if (params.status) {
        searchParams.set("status", String(params.status))
    }

    if (params.facilityName) {
        searchParams.set("facilityName", String(params.facilityName))
    }

    if (params.sort) {
        searchParams.set("sort", String(params.sort))
    }

    if (params.completedBenefitCheck) {
        searchParams.set("completedBenefitCheck", String(params.completedBenefitCheck))
    }

    if (params.search) {
        searchParams.set("search", String(params.search))
    }

    if (params.simulationDateStart) {
        searchParams.set("simulationDateStart", String(params.simulationDateStart))
    }

    if (params.simulationDateEnd) {
        searchParams.set("simulationDateEnd", String(params.simulationDateEnd))
    }

    // Current URL: https://my-website.com/page_a
    const nextURL = '/Preauths/?' + searchParams.toString();
    const nextTitle = 'My new page title';
    const nextState = { additionalInformation: 'Updated the URL with JS' };

    // This will create a new entry in the browser's history, without reloading
    window.history.pushState(nextState, nextTitle, nextURL);

    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState(nextState, nextTitle, nextURL);

    return searchParams.toString();
}