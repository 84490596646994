import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Modal, Button, ButtonGroup } from "react-bootstrap"
import { updateRaw } from "../../actions/TreatmentActions"
import { updated as didUpdate } from "../../actions/EventTimeActions"
import moment from "moment"

import { AppState } from "../../reducers"
import { close } from "../../actions/ModalActions"
import DateRange from "../DateRange"
import { useTokens } from "../../useAuthorizationCodeFlow"
import { getTenantId } from "../../common/jwt"

interface AdmissionsModalStateProps {
	show: boolean
	admissions: any[]
	treatment: any
}

const AdmissionsModal = (props: AdmissionsModalStateProps) => {
	const { show, treatment } = props
	const dispatch = useDispatch()
	const { getAccessToken } = useTokens()
	const [waiting, setWaiting] = useState(false)
	const [updates, setUpdates] = useState({ admissions: props.admissions })

	const { admissions } = updates

	useEffect(() => {
		if (show && props.admissions) {
			setUpdates({ admissions: [...props.admissions] })
		}
	}, [show, props.admissions])

	const cancel = () => {
		dispatch(close())
	}

	const save = async () => {
		setWaiting(true)

		const updateList = []

		for (let a of admissions) {
			const id = null
			const { admit, discharge } = a.attributes

			updateList.push({ id, admit: admit ? moment(admit).format("MM/DD/YYYY") : null, discharge: discharge ? moment(discharge).format("MM/DD/YYYY") : null })
		}


		console.log({ id: treatment.id, admissions: updateList })

		const { status } = await updateRaw(getTenantId(), { id: treatment.id, admissions: updateList })
		setWaiting(false)

		if (status - 200 < 100) {
			dispatch(didUpdate("treatment"))
		}

		dispatch(close())
	}

	const addAdmission = () => {
		const a = [...admissions]
		a.push({
			id: null
			, type: "Admission"
			, attributes: {
				admit: null
				, discharge: null
			}
		})

		setUpdates({ admissions: a })
	}

	const removeAdmission = () => {
		const a = [...admissions]
		a.splice(a.length - 1, 1)
		setUpdates({ admissions: [...a] })
	}

	const handleChangeStartDate = (date: string, index: number) => {
		const a = [...admissions]
		a[index].attributes.admit = date
		setUpdates({ admissions: [...a] })
	}

	const handleChangeEndDate = (date: string, index: number) => {
		const a = [...admissions]
		a[index].attributes.discharge = date
		setUpdates({ admissions: [...a] })
	}

	if (!admissions) {
		return null
	}

	return (
		<Modal show={show} onHide={cancel}>
			<Modal.Header>
				<Modal.Title>Edit Admissions</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					admissions.map((a: any, index: number) => <DateRange
						key={index}
						startDate={a.attributes.admit}
						endDate={a.attributes.discharge}
						onChangeStartDate={(date: string) => handleChangeStartDate(date, index)}
						onChangeEndDate={(date: string) => handleChangeEndDate(date, index)} />)
				}
				<ButtonGroup>
					<Button size="sm" onClick={addAdmission}>Add</Button>
					<Button size="sm" onClick={removeAdmission}>Remove</Button>
				</ButtonGroup>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={cancel}>Cancel</Button>
				<Button type="submit" disabled={waiting} onClick={save}>{waiting ? "Saving" : "Save"}</Button>
			</Modal.Footer>
		</Modal>
	)
}

const mapStateToProps = (state: AppState): AdmissionsModalStateProps => {
	const { modal } = state

	return {
		show: modal.modalIdentifier === "EditAdmissions" && modal.isOpen,
		...modal.modalProps
	}
}

export default connect(mapStateToProps)(AdmissionsModal)
