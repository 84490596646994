import Axios from "../../common/axios"

export enum Action {
	FETCH_WAITING = "preauth.PhysicianAction.FETCH_WAITING"
	, FETCH_COMPLETED = "preauth.PhysicianAction.FETCH_COMPLETED"
	, FETCH_ERROR = "preauth.PhysicianAction.FETCH_ERROR"

	, CREATE_WAITING = "preauth.PhysicianAction.CREATE_WAITING"
	, CREATE_COMPLETED = "preauth.PhysicianAction.CREATE_COMPLETED"
	, CREATE_ERROR = "preauth.PhysicianAction.CREATE_ERROR"
}

export const fetch = (tenantId: number) => async (dispatch: Function, getState: Function) => {
	const uri = `/remote-preauth/api/${localStorage.getItem("tenant)_id")!}/physician`
	const cache = sessionStorage.getItem(uri)

	if (cache) {
		return dispatch({ type: Action.FETCH_COMPLETED, payload: cache })
	}

	dispatch({ type: Action.FETCH_WAITING })

	try {
		const { data, request } = await Axios.get(uri)

		if (request.responseURL.endsWith("/login")) {
			throw new Error("redirected to /login")
		}

		sessionStorage.setItem(uri, JSON.stringify(data))
		dispatch({ type: Action.FETCH_COMPLETED, payload: data })
	} catch (error) {
		dispatch({ type: Action.FETCH_ERROR, payload: error })
	}
}

export const create = (tenantId: number, name: string, address: string = "") => async (dispatch: Function, getState: Function) => {
	dispatch({ type: Action.CREATE_WAITING })

	const data = {
		type: "physician"
		, attributes: {
			name
			, address
		}
	}

	try {
		Axios({
			method: 'post'
			, url: `/remote-preauth/api/${localStorage.getItem("tenant)_id")!}/physician`
			, data: JSON.stringify({ data })
		})

		dispatch({ type: Action.CREATE_COMPLETED, payload: data })
	}
	catch (error) {
		dispatch({ type: Action.CREATE_ERROR, payload: error })
	}
}