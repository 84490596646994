import Dropdown from "../Dropdown"
import { withCustomData } from "../withData"
import { Token } from "../../useAuthorizationCodeFlow"

function getProviderURL(): Promise<string> {
    return new Promise<string>((resolve) => {
        const waitForAccessToken = () => {
            const token = new Token(localStorage.getItem("chargebook_access_token")!)

            if (token.tenantId() === -1) {
                setTimeout(waitForAccessToken, 200)
            } else {
                resolve(`/api/${token.tenantId()}/physician`)
            }
        }

        waitForAccessToken()
    })
}

export default withCustomData(getProviderURL(), item => {
    return {
        value: item.id
        , name: item.attributes.name
    }
})(Dropdown)