import Axios from "../../common/axios"

export enum Action {
	FETCH_WAITING = "preauth.StatusAction.FETCH_WAITING"
	, FETCH_COMPLETED = "preauth.StatusAction.FETCH_COMPLETED"
	, FETCH_ERROR = "preauth.StatusAction.FETCH_ERROR"
}

export const fetch = () => async (dispatch: Function, getState: Function) => {
	const uri = "/remote-preauth/api/status"
	const cache = sessionStorage.getItem(uri)

	if (cache) {
		return dispatch({ type: Action.FETCH_COMPLETED, payload: cache })
	}

	dispatch({ type: Action.FETCH_WAITING })

	try {
		const { data, request } = await Axios.get(uri)

		if (request.responseURL.endsWith("/login")) {
			throw new Error("redirected to /login")
		}

		sessionStorage.setItem(uri, JSON.stringify(data))
		dispatch({ type: Action.FETCH_COMPLETED, payload: data })
	} catch (error) {
		dispatch({ type: Action.FETCH_ERROR, payload: error })
	}
}