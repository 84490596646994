import Axios from "../common/axios"
import * as _ from "lodash"
import { ModalState, ModalAction, ModalType, close } from "./ModalActions"
import { AppState } from '../reducers'
import { byType } from '../common/jsonapi-filters'

enum Type {
    SAVE_WAITING = "TreatmentModalAction.SAVE_WAITING"
    , SAVE_COMPLETED = "TreatmentModalAction.SAVE_COMPLETED"
    , SAVE_ERROR = "TreatmentModalAction.SAVE_ERROR"

    , SET_MODALITY = "TreatmentModalAction.SET_MODALITY"
    , SET_INSURANCE = "TreatmentModalAction.SET_INSURANCE"
    , SET_INSURANCE_AUTH_NUMBER = "TreatmentModalAction.SET_INSURANCE_AUTH_NUMBER"
    , SET_FACILITY = "TreatmentModalAction.SET_FACILITY"
    , SET_REFERRING_PHYSICIAN = "TreatmentModalAction.SET_REFERRING_PHYSICIAN"
    , SET_ASSIGNEE = "TreatmentModalAction.SET_ASSIGNEE"
    , SET_NOTE = "TreatmentModalAction.SET_NOTE"
    , SET_STATUS = "TreatmentModalAction.SET_STATUS"

    , SET_PATIENT_MRN = "TreatmentModalAction.SET_PATIENT_MRN"
    , SET_PATIENT_DOB = "TreatmentModalAction.SET_PATIENT_DOB"
    , SET_PATIENT_LAST_NAME = "TreatmentModalAction.SET_PATIENT_LAST_NAME"
    , SET_PATIENT_FIRST_NAME = "TreatmentModalAction.SET_PATIENT_FIRST_NAME"
    , SET_PATIENT_MIDDLE_INITIAL = "TreatmentModalAction.SET_PATIENT_MIDDLE_INITIAL"
    , SET_PATIENT_GENDER = "TreatmentModalAction.SET_PATIENT_GENDER"

    , NEW_PHASE = "TreatmentModalAction.NEW_PHASE"
    , DEL_PHASE = "TreatmentModalAction.DEL_PHASE"
    , SET_PHASE_NAME = "TreatmentModalAction.SET_PHASE_NAME"
    , SET_PHASE_FRACTIONS = "TreatmentModalAction.SET_PHASE_FRACTIONS"
    , SET_PHASE_IGRT = "TreatmentModalAction.SET_PHASE_IGRT"

    , NEW_ADMISSION = "TreatmentModalAction.NEW_ADMISSION"
    , DEL_ADMISSION = "TreatmentModalAction.DEL_ADMISSION"
    , SET_ADMISSION_ADMIT = "TreatmentModalAction.SET_ADMISSION_ADMIT"
    , SET_ADMISSION_DISCHARGE = "TreatmentModalAction.SET_ADMISSION_DISCHARGE"

    , NEW_DIAGNOSIS = "TreatmentModalAction.NEW_DIAGNOSIS"
    , DEL_DIAGNOSIS = "TreatmentModalAction.DEL_DIAGNOSIS"
    , SET_DIAGNOSIS = "TreatmentModalAction.SET_DIAGNOSIS"

    , SET_VALIDATION = "TreatmentModalAction.SET_VALIDATION"
}

export interface Action {
    type: string
    payload: any
}

export const save = () => {
    return async (dispatch: (action: Action) => Action, getState: () => AppState) => {
        dispatch({ type: Type.SAVE_WAITING, payload: {} })

        const state = getState().modal

        const { validation } = state.modalProps
        let valid = true

        if (state.modalProps.patient.attributes.mrn === "") {
            validation.patient.mrn = "error"
            valid = false
        }

        if (state.modalProps.patient.attributes.lastName === "") {
            validation.patient.lastName = "error"
            valid = false
        }

        if (state.modalProps.patient.attributes.firstName === "") {
            validation.patient.firstName = "error"
            valid = false
        }

        if (!valid) {
            dispatch({ type: Type.SET_VALIDATION, payload: { validation } })
            return
        }

        const op = (obj: any) => {
            if (obj.id) {
                return "update"
            } else {
                return "add"
            }
        }

        const ref = (type: string, obj: any) => {
            let r: any = {
                type: type
            }

            if (obj.id) {
                r["id"] = obj.id
            } else if (obj.lid) {
                r["lid"] = obj.lid
            }

            return r
        }

        const operations = state.modalProps.operations

        if (state.modalProps.didUpdate.patient) {
            operations.push({
                op: op(state.modalProps.patient)
                , ref: ref("Patient", state.modalProps.patient)
                , data: state.modalProps.patient
            })
        }

        if (state.modalProps.didUpdate.treatment) {
            operations.push({
                op: op(state.modalProps.treatment)
                , ref: ref("Treatment", state.modalProps.treatment)
                , data: state.modalProps.treatment
            })
        } else {
            operations.push({
                op: "noop"
                , ref: ref("Treatment", state.modalProps.treatment)
                , data: state.modalProps.treatment
            })
        }

        if (state.modalProps.didUpdate.phase) {
            for (const phase of state.modalProps.phases) {
                operations.push({
                    op: op(phase)
                    , ref: ref("Phase", phase)
                    , data: phase
                } as any)
            }
        }

        if (state.modalProps.didUpdate.admission) {
            for (const admission of state.modalProps.admissions) {
                operations.push({
                    op: op(admission)
                    , ref: ref("Admission", admission)
                    , data: admission
                } as any)
            }
        }

        if (state.modalProps.didUpdate.diagnosis) {
            for (const diagnosis of state.modalProps.diagnosis) {
                operations.push({
                    op: op(diagnosis)
                    , ref: ref("Diagnosis", diagnosis)
                    , data: diagnosis
                } as any)
            }
        }

        console.log("operations:", operations)

        try {
            const response = await Axios.post("/api/treatment/operations", JSON.stringify({ operations }))

            dispatch({ type: Type.SAVE_COMPLETED, payload: { response, operations } })
            dispatch(close())
        }
        catch (err: any) {
            (window as any).sourceMappedStackTrace.mapStackTrace(err.stack, function (mappedStack: any) {
                console.error("unable to save treatment:", { err, mappedStack, operations })
            })

            dispatch({ type: Type.SAVE_ERROR, payload: { err } })
        }
    }
}

export const setModality = (modalityId: number): Action => {
    console.log(`setModality(${modalityId})`)
    return { type: Type.SET_MODALITY, payload: { modalityId } }
}

export const setInsurance = (insuranceId: number): Action => {
    return { type: Type.SET_INSURANCE, payload: { insuranceId } }
}

export const setInsuranceAuthNumber = (insuranceAuthNumber: string): Action => {
    return { type: Type.SET_INSURANCE_AUTH_NUMBER, payload: { insuranceAuthNumber } }
}

export const setFacility = (facilityId: number): Action => {
    return { type: Type.SET_FACILITY, payload: { facilityId } }
}

export const setReferringPhysician = (referringPhysicianId: number): Action => {
    return { type: Type.SET_REFERRING_PHYSICIAN, payload: { referringPhysicianId } }
}

export const setAssignee = (assigneeId: number): Action => {
    return { type: Type.SET_ASSIGNEE, payload: { assigneeId } }
}

export const setNote = (note: string): Action => {
    return { type: Type.SET_NOTE, payload: { note } }
}

export const setStatus = (statusId: number): Action => {
    return { type: Type.SET_STATUS, payload: { statusId } }
}

export const setPatientMrn = (mrn: string): Action => {
    return { type: Type.SET_PATIENT_MRN, payload: { mrn } }
}

export const setPatientDob = (dob: string): Action => {
    return { type: Type.SET_PATIENT_DOB, payload: { dob } }
}

export const setPatientFirstName = (firstName: string): Action => {
    return { type: Type.SET_PATIENT_FIRST_NAME, payload: { firstName } }
}

export const setPatientLastName = (lastName: string): Action => {
    return { type: Type.SET_PATIENT_LAST_NAME, payload: { lastName } }
}

export const setPatientMiddleInitial = (middleInitial: string): Action => {
    return { type: Type.SET_PATIENT_MIDDLE_INITIAL, payload: { middleInitial } }
}

export const setPatientGender = (gender: string): Action => {
    return { type: Type.SET_PATIENT_GENDER, payload: { gender } }
}

export const newPhase = (): Action => {
    return { type: Type.NEW_PHASE, payload: {} }
}

export const deletePhase = (index: number): Action => {
    return { type: Type.DEL_PHASE, payload: { index } }
}

export const setPhaseName = (name: string, index: number): Action => {
    return { type: Type.SET_PHASE_NAME, payload: { name, index } }
}

export const setPhaseFractions = (fractions: number, index: number): Action => {
    return { type: Type.SET_PHASE_FRACTIONS, payload: { fractions, index } }
}

export const setPhaseIgrt = (igrtId: number, index: number): Action => {
    return { type: Type.SET_PHASE_IGRT, payload: { igrtId, index } }
}

export const newAdmission = (): Action => {
    return { type: Type.NEW_ADMISSION, payload: {} }
}

export const deleteAdmission = (index: number): Action => {
    return { type: Type.DEL_ADMISSION, payload: { index } }
}

export const setAdmissionAdmit = (admit: string, index: number): Action => {
    return { type: Type.SET_ADMISSION_ADMIT, payload: { admit, index } }
}

export const setAdmissionDischarge = (discharge: string, index: number): Action => {
    return { type: Type.SET_ADMISSION_DISCHARGE, payload: { discharge, index } }
}

export const newDiagnosis = (): Action => {
    return { type: Type.NEW_DIAGNOSIS, payload: {} }
}

export const deleteDiagnosis = (index: number): Action => {
    return { type: Type.DEL_DIAGNOSIS, payload: { index } }
}

export const setDiagnosis = (icd10Id: number, index: number): Action => {
    return { type: Type.SET_DIAGNOSIS, payload: { icd10Id, index } }
}

export const defaultTreatment: any = {
    id: null
    , attributes: {
        note: ""
        , insurance_auth_number: null
    }
    , relationships: {
        patient: {
            data: { id: null, lid: 1, type: "Patient" }
        }
        , modality: {
            data: { id: null, type: "Modality" }
        }
        , facility: {
            data: { id: null, type: "Facility" }
        }
        , insurance: {
            data: { id: null, type: "Insurance" }
        }
        , referringphysician: {
            data: { id: null, type: "ReferringPhysician" }
        }
        , assignee: {
            data: { id: null, type: "User" }
        }
        , status: {
            data: { id: 1, type: "TreatmentStatus" }
        }
        , diagnosis: {
            data: [
                { id: null, lid: 1, icd10_id: null }
            ]
        }
        , admission: {
            data: [
                { id: null, lid: 1, type: "Admission" }
            ]
        }
        , phase: {
            data: [
                { id: null, lid: 1, type: "Phase" }
            ]
        }
    }
}

export const defaultPatient: any = {
    id: null
    , lid: 1
    , type: "Patient"
    , attributes: {
        name: ""
        , firstName: ""
        , lastName: ""
        , middleInitial: ""
        , mrn: ""
        , gender: "M"
        , dob: null
    }
}

export const defaultPhases: any[] = [
    {
        id: null
        , lid: 1
        , type: "Phase"
        , attributes: {
            name: "Initial"
            , fractions: 25
        }
        , relationships: {
            igrt: {
                data: {
                    id: null
                    , type: "CPT"
                }
            }
        }
    }
]
export const defaultAdmissions: any[] = [
    {
        id: null
        , lid: 1
        , type: "Admission"
        , attributes: {
            admit: null
            , discharge: null
        }
    }
]
const defaultDiagnosis: any[] = [
    {
        id: null
        , lid: 1
        , type: "Diagnosis"
        , attributes: {}
        , relationships: {
            icd10: {
                data: {
                    id: null
                    , type: "ICD10"
                }
            }
        }
    }
]

const defaultValidation: any = {
    patient: {
        mrn: null
        , dob: null
        , lastName: null
        , firstName: null
    }
    , modality: null
    , insurance: null
    , facility: null
}

const defaultDidUpdate: any = {
    treatment: false
    , patient: false
    , admission: false
    , diagnosis: false
}

interface ModalProps {
    waiting: boolean

    treatment: any
    patient: any

    phases: any[]
    admissions: any[]
    diagnosis: any[]

    operations: any[]

    validation: any
    didUpdate: any
}

const nextLid = (identifiableObjects: any[]) => {
    const max = identifiableObjects.reduce((curMax: number, curObj: any) => {
        if (typeof curObj.id !== undefined) {
            if (curMax < curObj.id) {
                curMax = curObj.id
            }
        }

        if (typeof curObj.lid !== undefined) {
            if (curMax < curObj.lid) {
                curMax = curObj.lid
            }
        }

        return curMax
    }, 1)

    return max + 1
}

const reducer = (prevState: ModalState, action: Action & ModalAction): ModalState => {
    if (prevState.modalIdentifier !== "TreatmentModal") {
        return prevState
    }

    const state = _.cloneDeep(prevState)

    switch (action.type) {
        case ModalType.OPENED:
            {
                state.modalProps.validation = _.cloneDeep(defaultValidation)
                state.modalProps.didUpdate = _.cloneDeep(defaultDidUpdate)
                state.modalProps.operations = ([] as any[])
                state.modalProps.waiting = false

                const { treatment, included } = state.modalProps

                if (treatment) {
                    const patient = included.find(byType("Patient"))
                    const phases = included.filter(byType("Phase"))
                    const admissions = included.filter(byType("Admission"))
                    const diagnosis = included.filter(byType("Diagnosis"))

                    state.modalProps.treatment = _.cloneDeep(treatment)
                    state.modalProps.patient = _.cloneDeep(patient)
                    state.modalProps.phases = _.cloneDeep(phases)
                    state.modalProps.admissions = _.cloneDeep(admissions)
                    state.modalProps.diagnosis = _.cloneDeep(diagnosis)
                } else {
                    state.modalProps.treatment = _.cloneDeep(defaultTreatment)
                    state.modalProps.patient = _.cloneDeep(defaultPatient)
                    state.modalProps.phases = _.cloneDeep(defaultPhases)
                    state.modalProps.admissions = _.cloneDeep(defaultAdmissions)
                    state.modalProps.diagnosis = _.cloneDeep(defaultDiagnosis)

                    state.modalProps.didUpdate.treatment = true
                    state.modalProps.didUpdate.patient = true
                    state.modalProps.didUpdate.phase = true
                    state.modalProps.didUpdate.admission = true
                    state.modalProps.didUpdate.diagnosis = true
                }
            }
            break
        case Type.SAVE_WAITING:
            state.modalProps.waiting = true
            break
        case Type.SAVE_COMPLETED:
            state.modalProps.waiting = false
            const { response, operations } = action.payload
            console.log(response)
            console.log(operations)
            break
        case Type.SAVE_ERROR:
            state.modalProps.waiting = false
            break

        case Type.SET_VALIDATION:
            state.modalProps.validation = { ...action.payload }
            break

        case Type.SET_MODALITY:
            state.modalProps.treatment.relationships.modality.data.id = action.payload.modalityId
            state.modalProps.validation.modality = null
            state.modalProps.didUpdate.treatment = true
            break
        case Type.SET_INSURANCE:
            state.modalProps.treatment.relationships.insurance.data.id = action.payload.insuranceId
            state.modalProps.validation.insurance = null
            state.modalProps.didUpdate.treatment = true
            break
        case Type.SET_INSURANCE_AUTH_NUMBER:
            state.modalProps.treatment.attributes.insurance_auth_number = action.payload.insuranceAuthNumber
            state.modalProps.didUpdate.treatment = true
            break
        case Type.SET_FACILITY:
            state.modalProps.treatment.relationships.facility.data.id = action.payload.facilityId
            state.modalProps.validation.facility = null
            state.modalProps.didUpdate.treatment = true
            break
        case Type.SET_REFERRING_PHYSICIAN:
            state.modalProps.treatment.relationships.referringphysician.data.id = action.payload.referringPhysicianId
            state.modalProps.didUpdate.treatment = true
            break
        case Type.SET_ASSIGNEE:
            state.modalProps.treatment.relationships.assignee.data.id = action.payload.assigneeId
            state.modalProps.didUpdate.treatment = true
            break
        case Type.SET_NOTE:
            state.modalProps.treatment.attributes.note = action.payload.note
            state.modalProps.didUpdate.treatment = true
            break
        case Type.SET_STATUS:
            state.modalProps.treatment.relationships.status.data.id = action.payload.statusId
            state.modalProps.didUpdate.treatment = true
            break


        case Type.SET_PATIENT_MRN:
            state.modalProps.patient.attributes.mrn = action.payload.mrn
            state.modalProps.validation.patient.mrn = null
            state.modalProps.didUpdate.patient = true
            break
        case Type.SET_PATIENT_DOB:
            state.modalProps.patient.attributes.dob = action.payload.dob
            state.modalProps.validation.patient.dob = null
            state.modalProps.didUpdate.patient = true
            break
        case Type.SET_PATIENT_LAST_NAME:
            state.modalProps.patient.attributes.lastName = action.payload.lastName
            state.modalProps.validation.patient.lastName = null
            state.modalProps.didUpdate.patient = true
            break
        case Type.SET_PATIENT_FIRST_NAME:
            state.modalProps.patient.attributes.firstName = action.payload.firstName
            state.modalProps.validation.patient.firstName = null
            state.modalProps.didUpdate.patient = true
            break
        case Type.SET_PATIENT_MIDDLE_INITIAL:
            state.modalProps.patient.attributes.middleInitial = action.payload.middleInitial
            state.modalProps.didUpdate.patient = true
            break
        case Type.SET_PATIENT_GENDER:
            state.modalProps.patient.attributes.gender = action.payload.gender
            state.modalProps.didUpdate.patient = true
            break

        case Type.NEW_PHASE:
            {
                const lid = nextLid(state.modalProps.phases)

                state.modalProps.phases.push({
                    id: null
                    , lid: lid
                    , type: "Phase"
                    , attributes: {
                        name: "Boost"
                        , fractions: 19
                    }
                    , relationships: {
                        igrt: {
                            data: {
                                id: null as null
                                , type: "CPT"
                            }
                        }
                    }
                })

                state.modalProps.treatment.relationships.phase.data.push({
                    id: null
                    , lid: lid
                    , type: "Phase"
                })

                state.modalProps.didUpdate.phase = true
            }
            break
        case Type.SET_PHASE_NAME:
            state.modalProps.phases[action.payload.index].attributes.name = action.payload.name
            state.modalProps.didUpdate.phase = true
            break
        case Type.SET_PHASE_FRACTIONS:
            state.modalProps.phases[action.payload.index].attributes.fractions = action.payload.fractions * 1
            state.modalProps.didUpdate.phase = true
            break
        case Type.SET_PHASE_IGRT:
            if (state.modalProps.phases[action.payload.index].relationships.igrt === null) {
                state.modalProps.phases[action.payload.index].relationships.igrt = {
                    data: {
                        id: action.payload.igrtId
                        , type: "CPT"
                    }
                }
                state.modalProps.didUpdate.phase = true
            } else {
                state.modalProps.phases[action.payload.index].relationships.igrt.data.id = action.payload.igrtId
                state.modalProps.didUpdate.phase = true
            }
            break


        case Type.NEW_ADMISSION:
            {
                const lid = nextLid(state.modalProps.admissions)

                state.modalProps.admissions.push({
                    id: null
                    , lid: lid
                    , type: "Admission"
                    , attributes: {
                        admit: null
                        , discharge: null
                    }
                })

                state.modalProps.treatment.relationships.admission.data.push({
                    id: null
                    , lid: lid
                    , type: "Admission"
                })

                state.modalProps.didUpdate.admission = true
            }
            break
        case Type.DEL_ADMISSION:
            if (state.modalProps.admissions[action.payload.index].id) {
                state.modalProps.operations.push({
                    op: "delete"
                    , ref: {
                        type: "Admission"
                        , id: state.modalProps.admissions[action.payload.index].id
                    }
                })
            }

            state.modalProps.admissions.splice(action.payload.index, 1)
            state.modalProps.treatment.relationships.admission.data.splice(action.payload.index, 1)
            state.modalProps.didUpdate.admission = true
            break
        case Type.SET_ADMISSION_ADMIT:
            state.modalProps.admissions[action.payload.index].attributes.admit = action.payload.admit
            state.modalProps.didUpdate.admission = true
            break
        case Type.SET_ADMISSION_DISCHARGE:
            state.modalProps.admissions[action.payload.index].attributes.discharge = action.payload.discharge
            state.modalProps.didUpdate.admission = true
            break


        case Type.NEW_DIAGNOSIS:
            const id: number = 0
            const lid = nextLid(state.modalProps.diagnosis)

            state.modalProps.diagnosis.push({
                id
                , lid
                , type: "Diagnosis"
                , relationships: {
                    icd10: {
                        data: {
                            id: null
                            , type: "ICD10"
                        }
                    }
                }
            })

            state.modalProps.treatment.relationships.diagnosis.data.push({
                id
                , lid
                , type: "Diagnosis"
                , icd10_id: null
            })

            state.modalProps.didUpdate.diagnosis = true
            break
        case Type.DEL_DIAGNOSIS:
            if (state.modalProps.diagnosis[action.payload.index].id) {
                state.modalProps.operations.push({
                    op: "delete"
                    , ref: {
                        type: "Diagnosis"
                        , id: state.modalProps.diagnosis[action.payload.index].id
                    }
                })
            }

            state.modalProps.diagnosis.splice(action.payload.index, 1)
            state.modalProps.treatment.relationships.diagnosis.data.splice(action.payload.index, 1)
            state.modalProps.didUpdate.diagnosis = true
            break
        case Type.SET_DIAGNOSIS:
            state.modalProps.diagnosis[action.payload.index].relationships.icd10.data.id = action.payload.icd10Id
            state.modalProps.treatment.relationships.diagnosis.data[action.payload.index].icd10_id = action.payload.icd10Id
            state.modalProps.didUpdate.diagnosis = true
            break
    }

    return state
}

export default reducer
