import React from "react"
import { connect } from "react-redux"
import { Props } from "react-select"
import Select from "./Select"
import { AppState } from "../../reducers"

interface InsuranceSelectProps {
	onChangeInsurance?: (insurance: any) => void
	withLabel?: boolean
}

interface InsuranceSelectStateProps {
	insurances: any[]
}

const InsuranceSelect = (props: InsuranceSelectProps & InsuranceSelectStateProps & Props) => {
	const { insurances, onChangeInsurance, withLabel, ...restProps } = props

	const handleChange = (insurance: any) => {
		if (onChangeInsurance) {
			onChangeInsurance(insurance)
		}
	}

	return <Select items={insurances.filter((insurance: any) => insurance.attributes.active)} label={withLabel ? "Insurance" : ""} onChange={handleChange} {...restProps} />
}

const mapStateToProps = (state: AppState, ownProps: InsuranceSelectProps): InsuranceSelectStateProps => {
	return {
		insurances: state.insurance.data
	}
}

export default connect(mapStateToProps)(InsuranceSelect)