export const getTenantId = () => {
	return Number(getCookie("tenant_id"));
}

// utility function called by getCookie( )
function getCookieVal(offset: number): string {
	var endstr = document.cookie.indexOf(";", offset);
	if (endstr === -1) {
		endstr = document.cookie.length;
	}
	return document.cookie.substring(offset, endstr)
}

// primary function to retrieve cookie by name
export function getCookie(name: string): string {
	var arg = name + "=";
	var alen = arg.length;
	var clen = document.cookie.length;
	var i = 0;
	while (i < clen) {
		var j = i + alen;
		if (document.cookie.substring(i, j) === arg) {
			return getCookieVal(j);
		}
		i = document.cookie.indexOf(" ", i) + 1;
		if (i === 0) break;
	}
	return "";
}