import { Cpt, CptModifier } from "./cpt"
import { ChargeStatus } from "./chargeStatus"
import { Provider } from "./provider"
import { Facility } from "./facility"
import moment from "moment"
import { Treatment } from "./treatment"
import { Payor } from "./payor"

export interface Charge {
    id: number
    dos: string
    ordinal: number
    units: number
    cpt: Cpt
    mod1: CptModifier
    mod2: CptModifier
    mod3: CptModifier
    mod4: CptModifier
    dx: string[]
    note: string
    status: ChargeStatus
    provider: Provider
    supervisingProvider: Provider
    payor: Payor
    facility: Facility
}

export interface DOS {
    date: string
    ordinal: number
}

export const defaultCharge: Charge = {
    id: 0
    , cpt: {
        id: 0
        , code: ""
        , description: ""
        , category: {
            id: 0
            , name: ""
        }
        , mod1: {
            id: 0
            , code: ""
        }
        , mod2: {
            id: 0
            , code: ""
        }
        , mod3: {
            id: 0
            , code: ""
        }
        , mod4: {
            id: 0
            , code: ""
        }
    }
    , dos: ""
    , ordinal: 0
    , units: 0
    , mod1: {
        id: 0
        , code: ""
    }
    , mod2: {
        id: 0
        , code: ""
    }
    , mod3: {
        id: 0
        , code: ""
    }
    , mod4: {
        id: 0
        , code: ""
    }
    , dx: []
    , note: ""
    , status: {
        id: 0
        , name: ""
    }
    , provider: {
        id: 0
        , name: ""
        , code: ""
        , enabled: true
    }
    , supervisingProvider: {
        id: 0
        , name: ""
        , code: ""
        , enabled: true
    }
    , facility: {
        id: 0
        , name: ""
        , code: ""
        , practice: {
            id: 0
            , name: ""
            , code: ""
        }
    }
    , payor: {
        id: 0
        , name: ""
        , code: ""
    }
}

export interface IChargeRepo {
    createCharge: (tenantId: number, phaseId: number, charge: Charge) => Promise<Charge>
}

export interface ReportCharge {
    id: number
    dos: string
    note: string
    cpt: Cpt
    status: ChargeStatus
    provider: Provider
    facility: Facility
    treatment: Treatment
}

export interface ReportChargeFilter {
    facilityIds: string
    statusIds: string
    rangeStart: string
    rangeEnd: string
    search: string

    limit: number
    offset: number
}

export const defaultReportChargeFilter = (): ReportChargeFilter => {
    return {
        facilityIds: ""
        , statusIds: "1,2"
        , rangeStart: moment().subtract(1, "month").format("MM/DD/YYYY")
        , rangeEnd: moment().format("MM/DD/YYYY")
        , search: ""
        , limit: 50
        , offset: 0
    }
}

export interface IReportChargeRepo {
    listReportCharges: (tenantId: number, filter: ReportChargeFilter) => Promise<ReportCharge[]>
}