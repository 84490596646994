import React from "react"
import { connect } from "react-redux"
import { Props as SelectProps } from "react-select"
import Select from "./Select"
import { AppState } from "../../reducers"

interface Props {
	onChangeTreatmentStatus?: (treatmentStatus: any) => void
	withLabel?: boolean
}

interface StateProps {
	statuses: any[]
}

const TreatmentStatusSelect = (props: Props & StateProps & SelectProps) => {
	const { statuses, onChangeTreatmentStatus, withLabel, ...restProps } = props

	const handleChange = (status: any) => {
		if (onChangeTreatmentStatus) {
			onChangeTreatmentStatus(status)
		}
	}

	return <Select items={statuses} defaultValue={statuses[0]} label={withLabel ? "Status" : ""} onChange={handleChange} {...restProps} />
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => {
	return {
		statuses: state.treatmentStatus.data
	}
}

export default connect(mapStateToProps)(TreatmentStatusSelect)