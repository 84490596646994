import React from "react"
import { connect } from "react-redux"
import { Props as SelectProps } from "react-select"
import Select from "./Select"
import { AppState } from "../../reducers"

interface Props {
	onChangeChargeStatus: (chargeStatus: any) => void
	withLabel?: boolean
}

interface StateProps {
	statuses: any[]
}

const ChargeStatusSelect = (props: Props & StateProps & SelectProps) => {
	const { statuses, onChangeChargeStatus, withLabel, ...restProps } = props

	const handleChange = (insurance: any) => {
		onChangeChargeStatus(insurance)
	}

	return <Select items={statuses} label={withLabel ? "Status" : ""} onChange={handleChange} {...restProps} />
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => {
	return {
		statuses: state.chargeStatus.data
	}
}

export default connect(mapStateToProps)(ChargeStatusSelect)