export enum ChargeSetStatusModalAction {
    OPENED = "ChargeSetStatusModalAction.OPENED"
    , CLOSED = "ChargeSetStatusModalAction.CLOSED"
}

export interface ChargeSetStatusModalPayload {
    type: ChargeSetStatusModalAction;
    chargeId?: number;
}

export class ChargeSetStatusModalActions  {
    open(chargeId: number) {
        return {type: ChargeSetStatusModalAction.OPENED, chargeId: chargeId};
    }

    close() {
        return {type: ChargeSetStatusModalAction.CLOSED};
    }
}