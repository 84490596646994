import React, { useState, FormEvent } from "react"
import { connect, useDispatch } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { cloneDeep } from "lodash"

import { close } from "../../actions/ModalActions"
import { updateRaw } from "../../actions/ChargeActions"
import { updated } from "../../actions/EventTimeActions"
import { unselectAll } from "../../actions/ChargeMultiEditActions"
import { useTokens } from "../../useAuthorizationCodeFlow"
import { getTenantId } from "../../common/jwt"

import { AppState } from "../../reducers"
import PhysicianSelect from "../formcontrol/PhysicianSelect"
import InsuranceSelect from "../formcontrol/InsuranceSelect"
import FacilitySelect from "../formcontrol/FacilitySelect"
import ChargeStatusSelect from "../formcontrol/ChargeStatusSelect"

const defaultCharge = {
	relationships: {
		status: { data: { id: 0 } }
		, physician: { data: { id: 0 } }
		, insurance: { data: { id: 0 } }
		, facility: { data: { id: 0 } }
	}
}

interface ChargeModalStateProps {
	show: boolean
	chargeIds: number[]
}

const ChargeMultiEditModal = (props: ChargeModalStateProps) => {
	const dispatch = useDispatch()
	const { getAccessToken } = useTokens()

	const [charge, setCharge] = useState((defaultCharge as any))
	const [waiting, setWaiting] = useState(false)

	const { show, chargeIds } = props

	const cancel = () => {
		dispatch(close())
	}

	const save = async (e: FormEvent<HTMLFormElement>) => {
		setWaiting(true)
		e.preventDefault()

		await updateRaw(getTenantId(), charge, chargeIds)

		dispatch(updated("charge"))
		dispatch(close())
		dispatch(unselectAll())
		setCharge(defaultCharge)
		setWaiting(false)
	}

	const setPhysician = (physician: any) => {
		const next = cloneDeep(charge)
		next.relationships.physician.data.id = physician.id
		setCharge(next)
	}

	const setInsurance = (insurance: any) => {
		const next = cloneDeep(charge)
		next.relationships.insurance.data.id = insurance.id
		setCharge(next)
	}

	const setFacility = (facility: any) => {
		const next = cloneDeep(charge)
		next.relationships.facility.data.id = facility.id
		setCharge(next)
	}

	const setStatus = (status: any) => {
		const next = cloneDeep(charge)
		next.relationships.status.data.id = status.id
		setCharge(next)
	}

	return <Modal show={show} onHide={cancel}>
		<Form onSubmit={save}>
			<Modal.Header>
				<Modal.Title>Edit Multiple Charges</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<PhysicianSelect onChangePhysician={setPhysician} withLabel />
				<InsuranceSelect onChangeInsurance={setInsurance} withLabel />
				<FacilitySelect onChangeFacility={setFacility} withLabel />
				<ChargeStatusSelect onChangeChargeStatus={setStatus} withLabel />

				{/* <FormGroup>
								<FormLabel>Physician</FormLabel>
								{
									!physician
										? null
										: <Select name="form-field-name"
											tabIndex={'4'}

											value={physician.find((option: any) => option.id === charge.relationships.physician.data.id)}
											defaultValue={{ id: null, attributes: { name: "No Change" } }}

											onChange={(option: any) => {
												dispatch(actions().chargeMultiEditModal.setPhysician(option.id))
											}}

											options={[{ id: null, attributes: { name: "No Change" } }].concat(physician)}
											getOptionLabel={(option: any) => option.attributes.name}
											getOptionValue={(option: any) => option.id}
											/>
										}
									</FormGroup> */}

				{/* <FormGroup>
								<FormLabel>Insurance</FormLabel>
								{
									!insurance
										? null
										: <Select name="form-field-name"
											value={insurance.find((option: any) => option.id === charge.relationships.insurance.data.id)}
											defaultValue={{ id: null, attributes: { name: "No Change" } }}

											onChange={(option: any) => {
												dispatch(actions().chargeMultiEditModal.setInsurance(option.id))
											}}

											options={[{ id: null, attributes: { name: "No Change" } }].concat(insurance)}
											getOptionLabel={(option: any) => option.attributes.name}
											getOptionValue={(option: any) => option.id}
										/>
								}
							</FormGroup> */}



				{/* <FormGroup>
								<FormLabel>Facility</FormLabel>
								{
									!facility
										? null
										: <Select name="form-field-name"
											value={facility.find((option: any) => option.id === charge.relationships.facility.data.id)}
											defaultValue={{ id: null, attributes: { name: "No Change" } }}

											onChange={(option: any) => {
												dispatch(actions().chargeMultiEditModal.setFacility(option.id))
											}}

											options={[{ id: null, attributes: { name: "No Change" } }].concat(facility)}
											getOptionLabel={(option: any) => option.attributes.name}
											getOptionValue={(option: any) => option.id}
										/>
								}
							</FormGroup> */}



			</Modal.Body>
			<Modal.Footer>
				<Button onClick={cancel}>Cancel</Button>
				<Button type="submit" disabled={waiting}>{waiting ? "Saving" : "Save"}</Button>
			</Modal.Footer>
		</Form>
	</Modal>
}

const mapStateToProps = (state: AppState, ownProps: {}): ChargeModalStateProps => {
	return {
		show: state.modal.modalIdentifier === "MultiEditCharges" && state.modal.isOpen
		, chargeIds: state.modal.modalProps.chargeIds
	}
}

export default connect(mapStateToProps)(ChargeMultiEditModal)