import React, { SelectHTMLAttributes } from "react"

interface DropdownProps {
    data?: { name: string, value: string | number }[]
    emptyOption?: string
}

const sortFn = (a: any, b: any) => {
    if (a.name < b.name) {
        return -1
    }

    if (a.name > b.name) {
        return 1
    }

    return 0
}

function Dropdown(props: DropdownProps & SelectHTMLAttributes<HTMLSelectElement>) {
    const { data, emptyOption, ...restProps } = props

    const options = data
        ? emptyOption
            ? [{ name: emptyOption, value: 0 }, ...data.sort(sortFn)]
            : [...data.sort(sortFn)]
        : []

    return <select className="form-control" {...restProps}>
        {
            options.map((option, index) => <option key={index} value={option.value}>{option.name}</option>)
        }
    </select>
}

export default Dropdown