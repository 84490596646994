import { useState, useEffect } from "react"
import { Provider } from "./provider"

export interface ProviderImportMap {
    uuid: string
    pattern: string
    provider: Provider
}

export const useProviderImportMapList = () => {
    const [providerImportMaps, setProviderImportMaps] = useState([] as ProviderImportMap[])
    const [err, setErr] = useState<string | undefined>()

    useEffect(() => {
        const doFetch = async () => {
            try {
                const resp = await fetch("/v2/provider-import-maps")
                const json = await resp.json()

                if ("error" in json) {
                    setErr(json.error)
                    return
                }

                setProviderImportMaps(json)
            } catch (e) {
                setErr("something went wrong")
            }
        }

        doFetch()
    }, [])

    const createProviderImportMap = async () => {
        try {
            const resp = await fetch("/v2/provider-import-maps/create", { method: "POST" })
            const json = await resp.json()

            if ("error" in json) {
                setErr(json.error)
                return
            }

            setProviderImportMaps([...providerImportMaps, json])
        } catch (e) {
            setErr("something went wrong")
        }
    }

    const updateProviderImportMap = async (m: ProviderImportMap) => {
        try {
            const resp = await fetch("/v2/provider-import-maps/update", { method: "POST", body: JSON.stringify(m) })
            const json = await resp.json()

            if ("error" in json) {
                setErr(json.error)
                return
            }

            const nextProviderImportMaps = []

            for (const mm of providerImportMaps) {
                if (mm.uuid == json.uuid) {
                    nextProviderImportMaps.push(json)
                } else {
                    nextProviderImportMaps.push(mm)
                }
            }

            setProviderImportMaps(nextProviderImportMaps)
        } catch (e) {
            setErr("something went wrong")
        }
    }

    const deleteProviderImportMap = async (m: ProviderImportMap) => {
        try {
            await fetch("/v2/provider-import-maps/delete?uuid=" + m.uuid, { method: "DELETE" })

            const nextProviderImportMaps = []

            for (const mm of providerImportMaps) {
                if (mm.uuid != m.uuid) {
                    nextProviderImportMaps.push(mm)
                }
            }

            setProviderImportMaps(nextProviderImportMaps)
        } catch (e) {
            setErr("something went wrong")
        }
    }

    return {
        providerImportMaps
        , err
        , createProviderImportMap
        , updateProviderImportMap
        , deleteProviderImportMap
    }
}