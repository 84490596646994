import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Col, Row, FormGroup, FormLabel } from "react-bootstrap"
import Select from "react-select"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import { AppState } from "./reducers"
import { State as DocumentState } from "./reducers/DocumentReducer"
import { ListState } from "./reducers/ListState"
import { useTokens } from "./useAuthorizationCodeFlow"
import { getTenantId } from "./common/jwt"

import Navbar from "./components/Navbar"
import DocumentMaster from "./components/masterdetail/DocumentMaster"
import DocumentDetail from "./components/masterdetail/DocumentDetail"

import actions from "./actions"
import * as documentStatusActions from "./actions/DocumentStatusActions"
import * as documentTypeActions from "./actions/DocumentTypeActions"

function sortByOrdinal(a: any, b: any) {
    return a.attributes.ordinal - b.attributes.ordinal
}

interface DocumentListStateProps {
    documentList: DocumentState
    facilityList: ListState
    documentStatusList: documentStatusActions.DocumentStatusState
    documentTypeList: documentTypeActions.DocumentTypeState
}

const DocumentList = (props: DocumentListStateProps) => {
    const dispatch = useDispatch()
    const { getAccessToken } = useTokens()

    const { facilityList, documentList, documentStatusList, documentTypeList } = props

    useEffect(() => {
        dispatch(actions().document.fetch(getTenantId(), {}, 10, 0))
    }, [dispatch, getAccessToken])

    // if (!facilityList.data.length || !documentStatusList.data.length || !documentTypeList.data.length) {
    //     return null
    // }

    const formStyle = {
        backgroundColor: "#f9f9f9"
        , padding: "15px"
        , borderBottom: "solid 1px #999"
        , marginBottom: 0
    }

    const leftColStyle = {
        padding: 0
        , backgroundColor: "#ddd"
        , height: "calc(100% - 157px)"
        , overflow: "scroll" as "scroll"
    }

    const rightColStyle = {
        padding: 0
        , height: "calc(100% - 157px)"
    }

    return <>
        <Navbar />
        <div className="body">
            <form style={formStyle}>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <FormLabel>Type</FormLabel>
                            <Select
                                value={documentTypeList.data.find((option: any) => option.id === documentList.filter.type_id)}
                                defaultValue={{ id: 0, attributes: { description: "All" } }}

                                options={[{ id: 0, attributes: { description: "All" } }].concat(documentTypeList.data)}
                                getOptionLabel={(option: any) => option.attributes.description}
                                getOptionValue={(option: any) => option.id}

                                onChange={(option: any) => {
                                    const nextFilter = Object.assign(documentList.filter, { type_id: option.id })
                                    dispatch(actions().document.fetch(nextFilter, documentList.pagination.limit, 0))
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3} >
                        <FormGroup>
                            <FormLabel>Facility</FormLabel>
                            <Select
                                value={facilityList.data.find((option: any) => option.id === documentList.filter.facility_id)}
                                defaultValue={{ id: 0, attributes: { name: "All" } }}

                                options={[{ id: 0, attributes: { name: "All" } }].concat(facilityList.data)}
                                getOptionLabel={(option: any) => option.attributes.name}
                                getOptionValue={(option: any) => option.id}

                                onChange={(option: any) => {
                                    const nextFilter = Object.assign(documentList.filter, { facility_id: option.id })
                                    dispatch(actions().document.fetch(nextFilter, documentList.pagination.limit, 0))
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <FormLabel>Status</FormLabel>
                            <Select
                                value={documentStatusList.data.find((option: any) => option.id === documentList.filter.status_id)}
                                defaultValue={{ id: 0, attributes: { name: "All", color: null } }}

                                // optionRenderer={(option: any) => <div>
                                //     <span>{option.label}</span>
                                //     {!option.color ? null : <FontAwesomeIcon icon={faCircle} style={{ color: option.color, float: "right" }} />}
                                // </div>}

                                formatOptionLabel={(option: any, { context }) => {
                                    if (context === 'menu') {
                                        return <div>
                                            <span>{option.attributes.name}</span>
                                            {!option.attributes.color ? null : <FontAwesomeIcon icon={faCircle} style={{ color: option.attributes.color, float: "right" }} />}
                                        </div>
                                    } else {
                                        return option.attributes.name
                                    }
                                }}

                                options={[{ id: 0, attributes: { name: "All", color: null } }].concat(documentStatusList.data.sort(sortByOrdinal))}
                                getOptionLabel={(option: any) => option.attributes.name}
                                getOptionValue={(option: any) => option.id}

                                onChange={(option: any) => {
                                    const nextFilter = Object.assign(documentList.filter, { status_id: option.id })
                                    dispatch(actions().document.fetch(nextFilter, documentList.pagination.limit, 0))
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <div style={{ clear: "both" }}></div>
                </Row>
            </form>

            <Row>
                <Col md={2} style={leftColStyle}>
                    <DocumentMaster />
                </Col>
                <Col style={rightColStyle}>
                    <DocumentDetail />
                </Col>
            </Row>

        </div>
    </>
}


// export class DocumentMasterDetail extends Component<DocumentMasterDetailStateProps & React, null> {
//     componentDidMount() {

//     }

//     render() {
//         console.log("facilityList", facilityList.data.length)
//         console.log("documentStatusList", documentStatusList.data.length)
//         console.log("documentTypeList", documentTypeList.data.length)


//     }
// }

const mapStateToProps = ({ document, facility, documentStatusList, documentTypeList }: AppState, ownProps: {}): DocumentListStateProps => {
    return {
        documentList: document
        , facilityList: facility
        , documentStatusList
        , documentTypeList
    }
}

export default connect(mapStateToProps)(DocumentList)
