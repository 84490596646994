import { useState, useEffect } from "react"

export interface Import {
    id: number
    filename: string
    rows: number
    charges: number
    date: string
    status: {
        id: number
        name: string
    }
    status_message: string
}

export const useImportList = () => {
    const [imports, setImports] = useState([] as Import[])
    const [err, setErr] = useState<string | undefined>()

    useEffect(() => {
        const doFetch = async () => {
            try {
                const resp = await fetch("/v2/imports")
                const json = await resp.json()

                if ("error" in json) {
                    console.log("setting err", json.error)
                    setErr(json.error)
                    return
                }

                setImports(json)
            } catch (e) {
                setErr("something went wrong")
                console.error(e)
            }
        }

        doFetch()
    }, [])

    return {
        imports
        , err
    }
}