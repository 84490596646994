import Axios from "../common/axios"
import { AppState } from '../reducers'

enum Type {
    WAITING = "ICD10Action.FETCH_ICD10_WAITING"
    , COMPLETED = "ICD10Action.FETCH_ICD10_COMPLETED"
    , ERROR = "ICD10Action.FETCH_ICD10_ERROR"

    , FILTER = "ICD10Action.FILTER"
}

interface Action {
    type: Type
    payload: any
}

export const fetch = () => async (dispatch: (payload: Action) => Action, getState: () => AppState) => {
    const state = getState().icd10

    if (state.loading || state.fetched) {
        return
    }

    const uri = "/api/icd10"
    const cache = sessionStorage.getItem(uri)

    if (cache) {
        return dispatch({ type: Type.COMPLETED, payload: { data: JSON.parse(cache) } })
    }

    dispatch({ type: Type.WAITING, payload: {} })

    try {
        const { data, request } = await Axios.get(uri)

        if (request.responseURL.endsWith("/login")) {
            throw new Error("redirected to /login")
        }

        sessionStorage.setItem(uri, JSON.stringify(data))
        dispatch({ type: Type.COMPLETED, payload: { data } })
    } catch (error) {
        dispatch({ type: Type.ERROR, payload: { error } })
    }
}

export const filter = (query: string): Action => {
    return { type: Type.FILTER, payload: { query } }
}

export interface ICD10State {
    loading: boolean
    fetched: boolean

    query: string

    data: any[]
    included: any[]
}

const reducer = (prevState: ICD10State, action: Action): ICD10State => {
    const state = { ...prevState }

    switch (action.type) {
        case Type.WAITING:
            state.loading = true
            break
        case Type.COMPLETED:
            state.loading = false
            state.fetched = true

            const { data, included } = action.payload.data

            state.data = [...data]

            if (included) {
                state.included = [...included]
            }
            break
        case Type.FILTER:
            state.query = action.payload.query
            break
    }

    return state
}

export default reducer