import React, { useState } from "react"
import { connect, useDispatch } from 'react-redux'
import * as B from "react-bootstrap"
import Select from 'react-select'
import actions from "../../actions"
import { useTokens } from "../../useAuthorizationCodeFlow"
import { getTenantId } from "../../common/jwt"

export interface DocumentSetStatusModalStateProps {
	show: boolean

	documentId: number
	statuses: any[]
}

const DocumentSetStatusModal = (props: DocumentSetStatusModalStateProps) => {
	const { show, documentId, statuses } = props
	const { getAccessToken } = useTokens()

	const dispatch = useDispatch()
	const [status, setStatus] = useState({ id: 0, reason: "" })

	const save = (documentId: number, statusId: number, reason: string) => {
		dispatch(actions().document.setStatus(getTenantId(), documentId, statusId, reason))
		dispatch(actions().documentSetStatusModal.close())
	}

	const cancel = () => {
		dispatch(actions().documentSetStatusModal.close())
	}

	return <div>
		<B.Modal show={show} onHide={() => cancel()} className="treatment-modal">
			<form onSubmit={(e: any) => {
				e.preventDefault()
				save(documentId, status.id, status.reason)
				return false
			}}>
				<B.Modal.Header>
					<B.Modal.Title>Set Status</B.Modal.Title>
				</B.Modal.Header>
				<B.Modal.Body>
					<B.FormGroup>
						<B.FormLabel>Status</B.FormLabel>
						{
							!statuses
								? null
								: <Select name="form-field-name"
									onChange={(option: any) => {
										setStatus({ id: option.value, reason: "" })
									}}

									options={statuses.map((s: any) => {
										return {
											value: s.id
											, label: s.attributes.name
										}
									})}
								/>
						}
					</B.FormGroup>
				</B.Modal.Body>
				<B.Modal.Footer>
					<B.Row>
						<B.Col md={6}>

						</B.Col>
						<B.Col md={6}>
							<B.Button onClick={(e: any) => { cancel() }}>Cancel</B.Button>
							<B.Button type="submit">Save</B.Button>
						</B.Col>
					</B.Row>
				</B.Modal.Footer>
			</form>
		</B.Modal>
	</div>
}

const mapStateToProps = (state: any, ownProps: {}): DocumentSetStatusModalStateProps => {
	return {
		show: state.documentSetStatusModal.show ? true : false
		, documentId: state.documentSetStatusModal.documentId
		, statuses: state.documentStatusList.data
	}
}

export default connect(mapStateToProps)(DocumentSetStatusModal)