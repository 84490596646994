export enum DocumentEditModalAction {
    DOCUMENT_EDIT_MODAL_OPENED = "DOCUMENT_EDIT_MODAL_OPENED"
    , DOCUMENT_EDIT_MODAL_CLOSED = "DOCUMENT_EDIT_MODAL_CLOSED"
}

export interface DocumentEditModalPayload {
    type: DocumentEditModalAction;
    documentId: number;
}

export class DocumentEditModalActions {
    opened(documentId: number): DocumentEditModalPayload {
        return { type: DocumentEditModalAction.DOCUMENT_EDIT_MODAL_OPENED, documentId: documentId };
    }

    closed(): DocumentEditModalPayload {
        return { type: DocumentEditModalAction.DOCUMENT_EDIT_MODAL_CLOSED, documentId: 0 };
    }

    open(documentId: number) {
        return (dispatch: (payload: any) => any) => {
            dispatch(this.opened(documentId));
        }
    }

    close() {
        return (dispatch: (payload: any) => any) => {
            dispatch(this.closed());
        }
    }
}