import React from "react"
import Navbar from "../../components/Navbar"
import NewFacilityModal from "./NewFacilityModal"
import { dispatchEvent } from "../../hooks/useEvent"
import { Facility, useFacilityList } from "../../core/entities/facility"

const FacilityList = () => {
    const { facilities } = useFacilityList()

    const facilitiesByPractice = new Map<string, Facility[]>()

    for (const f of facilities) {
        if (!facilitiesByPractice.has(f.practice.name)) {
            facilitiesByPractice.set(f.practice.name, [f])
        } else {
            facilitiesByPractice.set(f.practice.name, [...facilitiesByPractice.get(f.practice.name)!, f])
        }
    }

    const practices: string[] = []

    facilitiesByPractice.forEach((value: Facility[], name: string) => {
        practices.push(name)
    })

    practices.sort((a: string, b: string) => {
        if (a < b) {
            return -1
        }

        return 1
    })

    const openNewFacilityModal = () => {
        dispatchEvent("openNewFacilityModal", {})
    }

    return <>
        <Navbar />
        <NewFacilityModal />
        <div className="body">
            <div style={{ padding: "1em" }}>
                <button className="btn btn-primary" onClick={openNewFacilityModal}>New Facility</button>
            </div>
            <table className="table">
                <thead>
                    <th>Name</th>
                    <th>Code</th>
                </thead>
                <tbody>
                    {
                        practices.map((practice: string) => {
                            return <>
                                <tr>
                                    <td colSpan={2} style={{ backgroundColor: "#eee" }}><b>{practice}</b></td>
                                </tr>
                                {
                                    facilitiesByPractice.get(practice)!.sort((a: Facility, b: Facility) => {
                                        if (a.name < b.name) {
                                            return -1
                                        }

                                        return 1
                                    }).map((f: Facility) => {
                                        return <tr>
                                            <td>{f.name}</td>
                                            <td>{f.code}</td>
                                        </tr>
                                    })
                                }
                            </>
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
}

export default FacilityList