import React, { useEffect, useState, FormEvent, useContext } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col, Button } from "react-bootstrap"
import Collapse from 'react-bootstrap/Collapse'

import { close } from "../../actions/ModalActions"
import { updateRaw, validateRaw } from "../../actions/ChargeActions"
import { AppState } from "../../reducers";
import { updated } from "../../actions/EventTimeActions";

import TreatmentContext from "../../contexts/TreatmentContext";
import ChargeContext from "../../contexts/ChargeContext"

import SelectDos from "./ChargeModal/SelectDos"
import SelectCpt from "./ChargeModal/SelectCpt"
import SelectModifiers from "./ChargeModal/SelectModifiers"
import SelectDiagnosis from "./ChargeModal/SelectDiagnosis"
import SelectFacility from "./ChargeModal/SelectFacility"
import SelectPhysician from "./ChargeModal/SelectPhysician"
import SelectSupervisingPhysician from "./ChargeModal/SelectSupervisingPhysician"
import SelectInsurance from "./ChargeModal/SelectInsurance"
import SelectPhase from "./ChargeModal/SelectPhase"
import SelectStatus from "./ChargeModal/SelectStatus"
import SelectMips from "./ChargeModal/SelectMips"
import Note from "./ChargeModal/Note"

import "./ChargeModal.css"

const ChargeModal = () => {
	const show = useSelector((state: AppState) => state.modal.isOpen && state.modal.modalIdentifier === "EditCharge")
	const { charge: propsCharge, admissions, phases } = useSelector((state: AppState) => state.modal.modalProps)
	const [treatment] = useContext(TreatmentContext)

	const dispatch = useDispatch()

	const [charge, setCharge] = useState(propsCharge)
	const [waiting, setWaiting] = useState(false)
	const [showMips, setShowMips] = useState(false)

	useEffect(() => {
		if (propsCharge) {
			setCharge(propsCharge)
			setShowMips(false)
			setWaiting(false)
		}
	}, [show, propsCharge])

	if (!show || !charge) {
		return null
	}

	const isValid = (charge: any) => {
		if (!charge.attributes.diagnosis.length) {
			return false
		}

		if (!charge.relationships.cpt.data.id) {
			return false
		}

		const ids = [
			charge.relationships.physician.data.id
			, charge.relationships.insurance.data.id
			, charge.relationships.facility.data.id
		]

		for (let id of ids) {
			if (!id || id === 1) {
				return false
			}
		}

		if (!charge.attributes.dos) {
			return false
		}

		return true
	}

	const save = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (!isValid(charge)) {
			return;
		}

		setWaiting(true)

		const validationResult = await validateRaw(treatment.id, charge)

		if (validationResult.data.status !== 'OK') {
			alert(validationResult.data.error)
			setWaiting(false)

			return
		}

		const resp = await fetch(`/v2/legacy/charge?phaseId=${charge.relationships.phase.data.id}`, { method: "PUT", body: JSON.stringify(charge) })

		if (resp.status - 200 < 100) {
			dispatch(close())
			dispatch(updated("charge"))
		}

		setWaiting(false)
	}

	const cancel = () => {
		dispatch(close())
	}

	return <ChargeContext.Provider value={[charge, setCharge]}>
		<Modal show={show} onHide={cancel} className="charge-modal" autoFocus={false}>
			<form onSubmit={save}>
				<Modal.Header>
					<Modal.Title>Edit Charge</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md={6}>
							<SelectDos multiple={false} />
							<SelectCpt admissions={admissions} />
							<SelectModifiers />
							<SelectDiagnosis />
						</Col>

						<Col md={6}>
							<Row>
								<Col md={6}>
									<SelectFacility />
									<SelectPhysician />
									<SelectSupervisingPhysician />
								</Col>

								<Col>
									<SelectInsurance />
									<SelectPhase phases={phases} />
									<SelectStatus />
								</Col>
							</Row>
						</Col>
					</Row>

					<Note />

					{/* <p onClick={() => setShowMips(!showMips)} style={{ textAlign: "center", cursor: "pointer", margin: 0 }} className="text-primary">{showMips ? "Hide MIPS" : "Show MIPS"}</p>

					<Collapse in={showMips}>
						<SelectMips />
					</Collapse> */}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={cancel}>Cancel</Button>
					<Button type="submit" disabled={waiting || !isValid(charge)}>{waiting ? "Saving" : "Save"}</Button>
				</Modal.Footer>
			</form>
		</Modal>
	</ChargeContext.Provider>
}

export default ChargeModal