import React from "react"
import moment from "moment"
import { style } from "typestyle"
import { connect, useDispatch } from "react-redux"
import { Table, Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import { AppState } from "../../reducers"
import { getTenantId } from "../../common/jwt"
import actions from "../../actions"
import * as treatmentActions from "../../actions/TreatmentActions"
import {useTokens} from "../../useAuthorizationCodeFlow"

interface DocumentMasterStateProps {
    documentList: any
    documentTypeList: any
    documentStatusList: any
}


const DocumentMaster = (props: DocumentMasterStateProps) => {
    const dispatch = useDispatch()
    const { getAccessToken } = useTokens()

    const selectDocument = (document: any, patient: any) => {
        dispatch(actions().document.select(document.id))
        dispatch(treatmentActions.fetch(getTenantId(), { patient_id: patient.id }))
        dispatch(actions().charge.fetch(getTenantId(), { patient_id: patient.id }))
    }

    let tfoot = null

    const numRemaining = props.documentList.pagination.count - (props.documentList.pagination.offset + props.documentList.pagination.limit)

    if (numRemaining > 0) {
        tfoot = <tfoot>
            <tr>
                <td colSpan={2}>
                    {
                        props.documentList.loading
                            ? <Button disabled>Loading...</Button>
                            : <Button onClick={(e: any) => { dispatch(actions().document.fetchMore(getTenantId())) }}>Load More</Button>
                    }
                </td>
            </tr>
        </tfoot>
    }

    if (!props.documentList.data) {
        return null
    }

    return <Table>
        <tbody>
            {
                props.documentList.data.map((document: any) => {
                    const find = (relationship: any, included: any[]) => {
                        return included.find((item: any) => {
                            return item.id === relationship.data.id && item.type === relationship.data.type
                        })
                    }

                    const patient: any = find(document.relationships.patient, props.documentList.included)
                    const status: any = find(document.relationships.status, props.documentStatusList.data)
                    const type: any = find(document.relationships.documenttype, props.documentTypeList.data)

                    let documentName = "Unknown"

                    if (type) {
                        documentName = `${type.attributes.code}/${moment(document.meta.created).format("YYYY-MM-DD")}`
                    }

                    const trStyle = {
                        $nest: {
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }
                        , backgroundColor: "none"
                    }

                    if (props.documentList.selectedId && props.documentList.selectedId === document.id) {
                        trStyle.backgroundColor = "#ccc"
                    }

                    let icon = <FontAwesomeIcon icon={faCircle} style={{ color: status.attributes.color }} />

                    return <tr key={document.id} className={style(trStyle)} onClick={(e: any) => { selectDocument(document, patient) }}>
                        <td>
                            <h4>{patient.attributes.name}</h4>
                            <p>{documentName}</p>
                        </td>
                        <td style={{ padding: "33px 5px" }}>
                            {icon}
                        </td>
                    </tr>
                })
            }
        </tbody>
        {tfoot}
    </Table>
}

const mapStateToProps = ({ document, documentTypeList, documentStatusList }: AppState, ownProps: {}): DocumentMasterStateProps => {
    return {
        documentList: document
        , documentTypeList
        , documentStatusList
    }
}


export default connect(mapStateToProps)(DocumentMaster)
