import { DocumentEditModalPayload, DocumentEditModalAction } from "../actions/DocumentEditModalActions"

export interface DocumentEditModalState {
    show: boolean
    documentId: number
}

const reducer = (state: DocumentEditModalState, payload: DocumentEditModalPayload): DocumentEditModalState => {
    const nextState = { ...state }

    switch (payload.type) {
        case DocumentEditModalAction.DOCUMENT_EDIT_MODAL_OPENED:
            nextState.show = true
            nextState.documentId = payload.documentId
            break
        case DocumentEditModalAction.DOCUMENT_EDIT_MODAL_CLOSED:
            nextState.show = false
            break
    }

    return nextState
}

export default reducer
