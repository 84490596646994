export interface ListState {
    loading: boolean
    fetched: boolean

    data: any[]
    included: any[]
}

export const defaultListState: ListState = {
    loading: false
    , fetched: false

    , data: []
    , included: []
}