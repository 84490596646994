import Axios from "../common/axios"
import { ChargeMultiEditActions } from "./ChargeMultiEditActions"

export enum ChargeAction {
    FETCH_CHARGES_WAITING = "ChargeAction.FETCH_CHARGES_WAITING"
    , FETCH_CHARGES_COMPLETED = "ChargeAction.FETCH_CHARGES_COMPLETED"
    , FETCH_CHARGES_ERROR = "ChargeAction.FETCH_CHARGES_ERROR"

    , FETCH_MORE_WAITING = "ChargeAction.FETCH_MORE_WAITING"
    , FETCH_MORE_COMPLETED = "ChargeAction.FETCH_MORE_COMPLETED"
    , FETCH_MORE_ERROR = "ChargeAction.FETCH_MORE_ERROR"

    , DOWNLOAD_CHARGES_WAITING = "ChargeAction.DOWNLOAD_CHARGES_WAITING"
    , DOWNLOAD_CHARGES_COMPLETED = "ChargeAction.DOWNLOAD_CHARGES_COMPLETED"
    , DOWNLOAD_CHARGES_ERROR = "ChargeAction.DOWNLOAD_CHARGES_ERROR"

    , ADD_CHARGE_WAITING = "ChargeAction.ADD_CHARGE_WAITING"
    , ADD_CHARGE_COMPLETED = "ChargeAction.ADD_CHARGE_COMPLETED"
    , ADD_CHARGE_ERROR = "ChargeAction.ADD_CHARGE_ERROR"

    , UPDATE_CHARGE_WAITING = "ChargeAction.UPDATE_CHARGE_WAITING"
    , UPDATE_CHARGE_COMPLETED = "ChargeAction.UPDATE_CHARGE_COMPLETED"
    , UPDATE_CHARGE_ERROR = "ChargeAction.UPDATE_CHARGE_ERROR"

    , TRANSITION_CHARGE_WAITING = "ChargeAction.TRANSITION_CHARGE_WAITING"
    , TRANSITION_CHARGE_COMPLETED = "ChargeAction.TRANSITION_CHARGE_COMPLETED"
    , TRANSITION_CHARGE_ERROR = "ChargeAction.TRANSITION_CHARGE_ERROR"

    , SHOW_ADD_MODAL_WINDOW = "ChargeAction.SHOW_ADD_MODAL_WINDOW"
    , HIDE_ADD_MODAL_WINDOW = "ChargeAction.HIDE_ADD_MODAL_WINDOW"

    , SHOW_EDIT_MODAL_WINDOW = "ChargeAction.SHOW_EDIT_MODAL_WINDOW"
    , HIDE_EDIT_MODAL_WINDOW = "ChargeAction.HIDE_EDIT_MODAL_WINDOW"

    , SET_STATUS_WAITING = "ChargeAction.SET_STATUS_WAITING"
    , SET_STATUS_COMPLETED = "ChargeAction.SET_STATUS_COMPLETED"
    , SET_STATUS_ERROR = "ChargeAction.SET_STATUS_ERROR"
}

export const updateRaw = (tenantId: number, charge: any, ids: number[] = []) => {
    return Axios.post(`/api/${tenantId}/charge/${ids.length ? ids.join(",") : charge.id}`, { data: charge })
}

export const createRaw = (tenantId: number, charge: any) => {
    return Axios.post(`/v2/legacy/charge`, charge)
}

export const validateRaw = (treatmentId: number, charge: any) => {
    return Axios.post(`/api/validate/${treatmentId}`, { data: charge })
}

export const fetchRaw = (tenantId: number, filter: any, limit: number = 0, offset: number = 0) => {
    const params = {
        include: 'mipsreport,diagnosis'
        , filter: filter
        , limit: limit
        , offset: offset
    }

    return Axios.get(`/api/${tenantId}/charge`, { params })
}

export const fetch = (tenantId: number, filter: any, limit: number = 0, offset: number = 0) => async (dispatch: (payload: any) => any) => {
    dispatch({ type: ChargeAction.FETCH_CHARGES_WAITING, filter: filter })

    try {
        const params = {
            include: 'mipsreport,diagnosis'
            , filter: filter
            , limit: limit
            , offset: offset
        }

        const { status, data } = await Axios.get(`/api/${tenantId}/charge`, { params })

        if (status !== 200) {
            throw new Error(`Status ${status}`)
        }

        dispatch({ type: ChargeAction.FETCH_CHARGES_COMPLETED, response: data })

    } catch (error) {
        dispatch({ type: ChargeAction.FETCH_CHARGES_ERROR, response: error })
    }
}

export interface ChargePayload {
    type: ChargeAction
    , filter?: any
    , response?: any
    , data?: any
}

export class ChargeActions {
    waiting(filter: any): ChargePayload {
        return { type: ChargeAction.FETCH_CHARGES_WAITING, filter: filter }
    }

    completed(response: any): ChargePayload {
        return { type: ChargeAction.FETCH_CHARGES_COMPLETED, response: response }
    }

    error(response: any): ChargePayload {
        return { type: ChargeAction.FETCH_CHARGES_ERROR, response: response }
    }

    downloadCsv(tenantId: number, filter: any) {
        Axios.get(`/api/${tenantId}/charge-csv`, {
            params: {
                filter: filter
            }
        }).then((r: any) => {
            const url = window.URL.createObjectURL(new Blob([r.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'charges.csv')
            document.body.appendChild(link)
            link.click()
        })
    }

    fetch(tenantId: number, filter: any, limit: number = 0, offset: number = 0) {
        return (dispatch: (payload: any) => any) => {
            dispatch(this.waiting(filter))

            return Axios.get(`/api/${tenantId}/charge`, {
                params: {
                    include: 'mipsreport,diagnosis'
                    , filter: filter
                    , limit: limit
                    , offset: offset
                }
            })
                .then((r: any) => {
                    dispatch(this.completed(r))
                })
                .catch((exception: any) => {
                    dispatch({ type: ChargeAction.FETCH_CHARGES_ERROR, data: { error: exception } })
                })
        }
    }

    fetchMore(tenantId: number, ) {
        return (dispatch: (payload: any) => any, getState: () => any) => {
            const state = getState().charge

            if ((state.pagination.limit * 1) * ((state.pagination.offset * 1) + 1) >= (state.pagination.count * 1)) {
                return
            }

            dispatch({ type: ChargeAction.FETCH_MORE_WAITING, data: null })

            return Axios.get(`/api/${tenantId}/charge`, {
                params: {
                    include: 'mipsreport,diagnosis'
                    , filter: state.filter
                    , limit: state.pagination.limit
                    , offset: state.pagination.offset + state.pagination.limit
                }
            })
                .then((response: any) => {
                    dispatch({ type: ChargeAction.FETCH_MORE_COMPLETED, data: { response: response.data } })
                })
                .catch((exception: any) => {
                    dispatch({ type: ChargeAction.FETCH_MORE_ERROR, data: { error: exception } })
                })
        }
    }

    add(tenantId: number, charge: any, dispatchOnComplete: any[] = [], dispatchOnError: any[] = []) {
        return (dispatch: (payload: any) => any) => {
            dispatch({ type: ChargeAction.ADD_CHARGE_WAITING })

            return Axios({
                method: 'post'
                , url: `/api/${tenantId}/charge`
                , data: JSON.stringify({ data: charge })
            }).then((r: any) => {
                dispatch({ type: ChargeAction.ADD_CHARGE_COMPLETED, response: r })

                for (let action of dispatchOnComplete) {
                    dispatch(action)
                }
            })
                .catch((e: any) => {
                    console.error(e)

                    for (let action of dispatchOnError) {
                        dispatch(action)
                    }
                })
        }
    }

    update(tenantId: number, charge: any, dispatchOnComplete: any[] = [], dispatchOnError: any[] = []) {
        return (dispatch: (payload: any) => any) => {
            dispatch({ type: ChargeAction.UPDATE_CHARGE_WAITING })

            console.log("ChargeActions.update")
            console.log(charge)

            return Axios({
                method: 'post'
                , url: `/api/${tenantId}/charge/${charge.id}`
                , data: JSON.stringify({ data: charge })
            }).then((r: any) => {
                dispatch({ type: ChargeAction.UPDATE_CHARGE_COMPLETED, response: r })

                for (let action of dispatchOnComplete) {
                    dispatch(action)
                }
            })
                .catch((e: any) => {
                    console.error(e)

                    for (let action of dispatchOnError) {
                        dispatch(action)
                    }
                })
        }
    }

    updateMulti(tenantId: number, chargeIds: number[], charge: any, dispatchOnComplete: any[] = [], dispatchOnError: any[] = []) {
        return (dispatch: (payload: any) => any) => {
            dispatch({ type: ChargeAction.UPDATE_CHARGE_WAITING })

            return Axios({
                method: 'post'
                , url: `/api/${tenantId}/charge/${chargeIds.join(",")}`
                , data: JSON.stringify({ data: charge })
            }).then((r: any) => {
                dispatch({ type: ChargeAction.UPDATE_CHARGE_COMPLETED, response: r })

                for (let action of dispatchOnComplete) {
                    dispatch(action)
                }
            })
                .catch((e: any) => {
                    console.error(e)

                    for (let action of dispatchOnError) {
                        dispatch(action)
                    }
                })
        }
    }

    setStatus(tenantId: number, chargeId: number, statusId: number, reason: string, dispatchOnComplete: any[] = [], dispatchOnError: any[] = []) {
        return this.setStatusMulti(tenantId, [chargeId], statusId, reason, dispatchOnComplete, dispatchOnError)
    }

    setStatusMulti(tenantId: number, chargeIds: number[], statusId: number, reason: string, dispatchOnComplete: any[] = [], dispatchOnError: any[] = []) {
        const data: any = {
            type: "ChargeStatus"
            , id: statusId
        }

        return (dispatch: (payload: any) => ChargePayload) => {
            dispatch({ type: ChargeAction.SET_STATUS_WAITING })

            return Axios({
                method: 'post'
                , url: `/api/${tenantId}/charge/${chargeIds.join(",")}/relationships/status`
                , data: JSON.stringify({ data: data })
            })
                .then((res: any) => {
                    // console.log(res.data.errors.length)

                    if (res.data.errors.length) {
                        dispatch({ type: ChargeAction.SET_STATUS_ERROR, data: { errors: res.data.errors } })

                        for (let action of dispatchOnError) {
                            dispatch(action)
                        }
                    } else {
                        dispatch({ type: ChargeAction.SET_STATUS_COMPLETED, response: { chargeIds: chargeIds, statusId: statusId } })
                        dispatch((new ChargeMultiEditActions()).unselectAll())

                        for (let action of dispatchOnComplete) {
                            dispatch(action)
                        }
                    }
                })
                .catch((e: any) => {
                    dispatch({ type: ChargeAction.SET_STATUS_ERROR, data: { errors: [e] } })

                    console.error(e)

                    for (let action of dispatchOnError) {
                        dispatch(action)
                    }
                })
        }
    }

    transition(charge: any, transitionId: number) {
        return (dispatch: (payload: any) => any) => {
            dispatch({ type: ChargeAction.TRANSITION_CHARGE_WAITING })

            return Axios.get('/api/workflow-process/' + charge.relationships.process.id + '/next/' + transitionId)
                .then((r: any) => {
                    dispatch({ type: ChargeAction.TRANSITION_CHARGE_COMPLETED, response: r })
                })
                .catch((e: any) => {
                    console.error(e)
                })
        }
    }

    transitionMulti(processIds: number[], transitionId: number) {
        return (dispatch: (payload: any) => any) => {
            dispatch({ type: ChargeAction.TRANSITION_CHARGE_WAITING })

            return Axios.get('/api/workflow-process/' + processIds.join(",") + '/next/' + transitionId)
                .then((r: any) => {
                    dispatch({ type: ChargeAction.TRANSITION_CHARGE_COMPLETED, response: r })
                    dispatch((new ChargeMultiEditActions()).unselectAll())
                })
                .catch((e: any) => {
                    console.error(e)
                })
        }
    }
}

