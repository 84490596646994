import React, { useState, ChangeEvent } from "react"
import { Modal } from "react-bootstrap"
import Navbar from "./components/Navbar"
import { useEvent, dispatchEvent } from "./hooks/useEvent"
import { Import, useImportList } from "./core/entities/import"
import { FacilityImportMap, useFacilityImportMapList } from "./core/entities/facilityImportMap"
import { ProviderImportMap, useProviderImportMapList } from "./core/entities/providerImportMap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck, faCircleXmark, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import FacilityDropdown from "./components/FacilityDropdown"
import ProviderDropdown from "./components/ProviderDropdown"

const Uploader = () => {
    const [file, setFile] = useState<File | undefined>()
    const [err, setErr] = useState<string | undefined>()
    const [success, setSuccess] = useState<string | undefined>()

    const handleSelectedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFile(e.currentTarget.files![0])
    }

    const handleUpload = (endpoint: string) => async () => {
        const formData = new FormData();

        formData.append(
            "myFile",
            file!,
            file!.name
        );

        try {
            const resp = await fetch(endpoint, {
                method: "POST",
                body: formData,
            })

            if (resp.status != 200) {
                const json = await resp.json()

                if ("error" in json) {
                    setErr(json.error)
                }
            } else {
                setSuccess("Successfully uploaded file. Refresh the page to view status.")
            }
        } catch (e) {
            setErr("something went wrong")
        }
    }

    return <>
        {
            err
                ? <div className="alert alert-danger">{err}</div>
                : null
        }
        {
            success
                ? <div className="alert alert-success">{success}</div>
                : null
        }
        <input type="file" onChange={handleSelectedFile} />
        <button style={{ marginRight: ".5em" }} className="btn btn-primary" disabled={!file} onClick={handleUpload("/v2/imports/upload-charges")}>Upload Charges</button>
        <button style={{ marginRight: ".5em" }} className="btn btn-primary" disabled={!file} onClick={handleUpload("/v2/imports/upload-schedule")}>Upload Schedule</button>
    </>
}

const SettingsModal = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { facilityImportMaps, createFacilityImportMap, updateFacilityImportMap, deleteFacilityImportMap, err: facilityErr } = useFacilityImportMapList()
    const { providerImportMaps, createProviderImportMap, updateProviderImportMap, deleteProviderImportMap, err: providerErr } = useProviderImportMapList()

    useEvent("openImportListSettingsModal", () => {
        console.log("open modal")
        setIsOpen(true)
    })

    const handleChangeFacilityMapPattern = (m: FacilityImportMap) => (e: ChangeEvent<HTMLInputElement>) => {
        m.pattern = e.currentTarget.value
        updateFacilityImportMap(m)
    }

    const handleChangeFacilityMapFacility = (m: FacilityImportMap) => (e: ChangeEvent<HTMLSelectElement>) => {
        m.facility.id = Number(e.currentTarget.value)
        updateFacilityImportMap(m)
    }

    const handleDeleteFacilityMap = (m: FacilityImportMap) => () => {
        deleteFacilityImportMap(m)
    }

    const handleChangeProviderMapPattern = (m: ProviderImportMap) => (e: ChangeEvent<HTMLInputElement>) => {
        m.pattern = e.currentTarget.value
        updateProviderImportMap(m)
    }

    const handleChangeProviderMapProvider = (m: ProviderImportMap) => (e: ChangeEvent<HTMLSelectElement>) => {
        m.provider.id = Number(e.currentTarget.value)
        updateProviderImportMap(m)
    }

    const handleDeleteProviderMap = (m: ProviderImportMap) => () => {
        deleteProviderImportMap(m)
    }

    return <Modal show={isOpen} className="settings-modal">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Settings</h5>
            </div>
            <div className="modal-body">
                {
                    !facilityErr
                        ? null
                        : <div className="alert alert-danger">{facilityErr}</div>
                }
                {
                    !providerErr
                        ? null
                        : <div className="alert alert-danger">{providerErr}</div>
                }
                <p>All imports must be CSVs with fields in the order shown below. Fields can be empty (unless required), but they must exist.</p>
                <div className="row">

                    <div className="col-3">
                        <h5>Schedule Import Fields</h5>
                        <ul>
                            <li>Last Name (required)</li>
                            <li>First Name (required)</li>
                            <li>MRN (required)</li>
                            <li>DOS (required)</li>
                            <li>CPT (required)</li>
                            <li>Units (required)</li>
                            <li>Facility</li>
                            <li>Provider</li>
                            <li>Diagnosis</li>
                        </ul>

                    </div>
                    <div className="col-3">
                        <h5>Charge Import Fields</h5>
                        <ul>
                            <li>Last Name (required)</li>
                            <li>First Name (required)</li>
                            <li>MRN (required)</li>
                            <li>DOB (required)</li>
                            <li>DOS (required)</li>
                            <li>CPT (required)</li>
                            <li>Units (required)</li>
                            <li>Modifier</li>
                            <li>Facility</li>
                            <li>Provider</li>
                            <li>Diagnosis</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <table className="table">
                            <thead>
                                <th>Pattern</th>
                                <th>Maps to Facility</th>
                                <th>Actions</th>
                            </thead>
                            <tbody>
                                {
                                    facilityImportMaps.map((m: FacilityImportMap) => {
                                        return <tr key={m.uuid}>
                                            <td><input className="form-control" type="text" defaultValue={m.pattern} onChange={handleChangeFacilityMapPattern(m)} /></td>
                                            <td><FacilityDropdown value={m.facility.id} onChange={handleChangeFacilityMapFacility(m)} /></td>
                                            <td><button className="btn btn-danger" onClick={handleDeleteFacilityMap(m)}>Delete</button></td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <td colSpan={3}><button className="btn btn-primary" onClick={() => { createFacilityImportMap() }}>Add</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6">
                        <table className="table">
                            <thead>
                                <th>Pattern</th>
                                <th>Maps to Provider</th>
                                <th>Actions</th>
                            </thead>
                            <tbody>
                                {
                                    providerImportMaps.map((m: ProviderImportMap) => {
                                        return <tr>
                                            <td><input className="form-control" type="text" defaultValue={m.pattern} onChange={handleChangeProviderMapPattern(m)} /></td>
                                            <td><ProviderDropdown value={m.provider.id} onChange={handleChangeProviderMapProvider(m)} /></td>
                                            <td><button className="btn btn-danger" onClick={handleDeleteProviderMap(m)}>Delete</button></td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <td colSpan={3}><button className="btn btn-primary" onClick={() => { createProviderImportMap() }}>Add</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div className="modal-footer">
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                <button type="button" className="btn btn-secondary" onClick={() => { setIsOpen(false) }}>Close</button>
            </div>
        </div>
    </Modal>
}

const ImportList = () => {
    const { imports, err } = useImportList()

    return <>
        <Navbar />
        <SettingsModal />
        <div style={{ marginTop: "50px" }}>
            {
                !err
                    ? <>
                        <div style={{ padding: "1em" }}>
                            <Uploader />
                            <button className="btn btn-primary" onClick={() => { dispatchEvent("openImportListSettingsModal", {}) }}>Settings</button>
                        </div>
                        <table className="table">
                            <thead>
                                <th></th>
                                <th>Filename</th>
                                <th>File Rows</th>
                                <th>Imported Charges</th>
                                <th>Date Uploaded</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {
                                    imports.map((imp: Import) => {
                                        return <ImportRow import={imp} />
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                    : <div style={{ padding: "1em" }}>
                        <div className="alert alert-danger">{err}</div>
                    </div>
            }
        </div>
    </>
}

interface ImportRowProps {
    import: Import
}

const ImportRow = (props: ImportRowProps) => {
    const [showMessage, setShowMessage] = useState(false)

    return <>
        <tr key={props.import.id}>
            <td>
                <a onClick={() => setShowMessage(!showMessage)} style={{ opacity: props.import.status_message ? "0.75" : "0.2" }} role="button">
                    <img src="/right-arrow.png" width={16} className={"caret" + (showMessage ? " rotate-90" : "")} />
                </a>
            </td>
            <td>{props.import.filename}</td>
            <td>{props.import.rows}</td>
            <td>{props.import.charges}</td>
            <td>{props.import.date}</td>
            <td>
                {statusIcon(props.import.status.id)}
            </td>
        </tr>
        {
            showMessage && props.import.status_message
                ? <tr>
                    <td></td>
                    <td colSpan={5}>
                        <div className="alert alert-danger">{props.import.status_message}</div>
                    </td>
                </tr>
                : null
        }
    </>

}

const statusIcon = (statusId: number) => {
    switch (statusId) {
        case 2:
            return <FontAwesomeIcon color="#198754" icon={faCircleCheck} />
        case 3:
            return <FontAwesomeIcon color="#dc3545" icon={faCircleXmark} />
    }

    return <FontAwesomeIcon color="#0d6efd" icon={faCircleNotch} />
}


export default ImportList