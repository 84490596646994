import React, { useState, useEffect } from "react"
import Select, { Props } from "react-select"

interface AsyncSelectProps {
    url: string
    optionValue?: string | number
    mapFn?: (item: any) => any
}

const AsyncSelect = (props: AsyncSelectProps & Props) => {
    let { url, optionValue, mapFn, ...restProps } = props
    const [data, setData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const resp = await fetch(url)
            const json = await resp.json()

            if (mapFn) {
                setData(json.map(mapFn))
            } else {
                setData(json)
            }
        }

        fetchData()
    }, [])

    let value: { value: string, label: string } | null = null

    if (optionValue) {
        const v = data.find((item: { value: string }) => {
            return String(item.value) === String(optionValue)
        })

        if (v) {
            value = v
        }
    }

    console.log("value:", value)

    return <Select
        options={data}
        value={value}
        {...restProps}
    />
}

export default AsyncSelect
