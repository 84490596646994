import React, { useState, useEffect, FormEvent } from "react"
import { useDispatch, connect } from "react-redux"
import { Modal, Form, Button, Row, Col } from "react-bootstrap"
import { AppState } from "../../reducers"
import * as modalActions from "../../actions/ModalActions"
import { updated } from "../../actions/EventTimeActions"
import { updateRaw, createRaw } from "../../actions/PhaseActions"
import * as _ from "lodash"
import { getRelationshipId } from '../../common/jsonapi-filters'
import { useTokens } from "../../useAuthorizationCodeFlow"
import { getTenantId } from "../../common/jwt"

const defaultPhase = {
	id: 0
	, type: "Phase"
	, attributes: {
		name: "Boost"
		, fractions: 19
	}
	, relationships: {
		igrt: {
			data: {
				id: 0
				, type: "CPT"
			}
		}
	}
}

interface EditPhaseStateProps {
	show: boolean
	phase: any
	treatment: any
	igrts: any[]
}

const EditPhase = (props: EditPhaseStateProps) => {
	const { show, treatment, igrts } = props
	const { getAccessToken } = useTokens()

	const dispatch = useDispatch()
	const [phase, setPhase] = useState(defaultPhase)
	const [waiting, setWaiting] = useState(false)

	useEffect(() => {
		if (show === true && props.phase) {
			setPhase(_.cloneDeep(props.phase))
		} else {
			setPhase(defaultPhase)
		}
	}, [show, props.phase, setPhase])

	const close = () => {
		dispatch(modalActions.close())
	}

	const save = async () => {
		setWaiting(true)

		if (phase.id) {
			const { status } = await updateRaw(getTenantId(), phase)

			if (status === 200) {
				dispatch(updated("treatment"))
			}
		} else {
			const { status } = await createRaw(getTenantId(), phase, treatment.id)

			if (status === 200) {
				dispatch(updated("treatment"))
			}
		}

		setWaiting(false)
		dispatch(modalActions.close())
	}

	if (!phase) {
		return null
	}

	const setName = (e: FormEvent<HTMLInputElement>) => {
		const next = _.cloneDeep(phase)
		next.attributes.name = e.currentTarget.value
		setPhase(next)
	}

	const setFractions = (e: FormEvent<HTMLInputElement>) => {
		const next = _.cloneDeep(phase)
		next.attributes.fractions = Number(e.currentTarget.value)
		setPhase(next)
	}

	const setIgrt = (e: FormEvent<HTMLInputElement>) => {
		const next = _.cloneDeep(phase)

		if (!next.relationships.igrt) {

		} else { }

		next.relationships.igrt = {
			data: {
				id: Number(e.currentTarget.value)
				, type: "CPT"
			}
		}

		setPhase(next)
	}

	return <Modal show={show} onHide={close}>
		<Form onSubmit={(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			save()
		}}>
			<Modal.Header>
				<Modal.Title>{phase.id ? "Edit" : "Create"} Phase</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col md={8}>
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control value={phase.attributes.name} onChange={setName} />
						</Form.Group>
					</Col>

					<Col md={4}>
						<Form.Group>
							<Form.Label>Fractions</Form.Label>
							<Form.Control value={String(phase.attributes.fractions)} onChange={setFractions} />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Form.Group>
							<Form.Label>IGRT</Form.Label>
							<Form.Control value={getRelationshipId("CPT", 0)(phase)} as="select" onChange={setIgrt}>
								{
									[{ id: 0, attributes: { code: "None" } }]
										.concat(igrts)
										.map((igrt: any) => {
											let label = igrt.attributes.code

											if (igrt.attributes.mod1) {
												label += ("-" + igrt.attributes.mod1)
											}

											return <option key={igrt.id} value={igrt.id}>{label}</option>
										})
								}
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={close}>Cancel</Button>
				<Button type="submit" disabled={waiting}>{waiting ? "Saving" : "Save"}</Button>
			</Modal.Footer>
		</Form>
	</Modal>
}

const mapStateToProps = (state: AppState, ownProps: {}): EditPhaseStateProps => {
	const byCategoryId = (categoryId: number) => (item: any) => item.relationships.category.data.id === categoryId

	return {
		show: state.modal.isOpen && state.modal.modalIdentifier === "EditPhase"
		, phase: state.modal.modalProps.phase
		, treatment: state.modal.modalProps.treatment
		, igrts: state.cpt.data.filter(byCategoryId(5))
	}
}

export default connect(mapStateToProps)(EditPhase)
