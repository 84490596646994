export enum DocumentSetStatusModalAction {
    OPENED = "DocumentSetStatusModalAction.OPENED"
    , CLOSED = "DocumentSetStatusModalAction.CLOSED"
}

export interface DocumentSetStatusModalPayload {
    type: DocumentSetStatusModalAction;
    documentId?: number;
}

export class DocumentSetStatusModalActions  {
    open(documentId: number) {
        return (dispatch: (payload: any) => any) => {
            dispatch({type: DocumentSetStatusModalAction.OPENED, documentId: documentId});
        }
    }

    close() {
        return (dispatch: (payload: any) => any) => {
            dispatch({type: DocumentSetStatusModalAction.CLOSED});
        }
    }
}