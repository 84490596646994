import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { Row, Col, FormGroup, FormLabel, FormControl, Button, Table } from "react-bootstrap"
import Select from 'react-select'
import moment from "moment"

import Axios from "./common/axios"
import { getTenantId } from "./common/jwt"

import Navbar from "./components/Navbar"
import DateInput from "./components/DateInput"
import { defaultReportChargeFilter, IReportChargeRepo, ReportCharge } from "./core/entities/charge"
import { ReportChargeRepo } from "./core/repos/reportChargeRepo"
import { useTokens } from "./useAuthorizationCodeFlow"

const mdY = (str: string | undefined): string => {
    return moment(str).format("MM/DD/YYYY")
}

const useReportCharges = (repo: IReportChargeRepo) => {
    const [charges, setCharges] = useState([] as ReportCharge[])
    const [isFetching, setIsFetching] = useState(false)
    const [isFetchingMore, setIsFetchingMore] = useState(false)
    const [isDownloading, setIsDownloding] = useState(false)

    const [filter, setFilter] = useState(defaultReportChargeFilter())
    const [page, setPage] = useState(1)
    const { getAccessToken } = useTokens()

    const fetchMore = async () => {
        setIsFetchingMore(true)

        try {
            const moreCharges = await repo.listReportCharges(0, { ...filter, offset: filter.limit * page })
            setCharges([...charges, ...moreCharges])
            setPage(page + 1)
        } catch (err) {

        }

        setIsFetchingMore(false)
    }

    const downloadCsv = () => {
        setIsDownloding(true)

        Axios.get(`/v2/${getTenantId()}/report-charges/csv`, { params: filter }).then((r: any) => {
            setIsDownloding(false)

            const url = window.URL.createObjectURL(new Blob([r.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'charges.csv')
            document.body.appendChild(link)
            link.click()
        })
    }

    useEffect(() => {
        const fetch = async () => {
            setIsFetching(true)

            try {
                const charges = await repo.listReportCharges(getTenantId(), filter)
                setCharges(charges)
                setPage(1)
            } catch (err) {

            }

            setIsFetching(false)
        }

        fetch()
    }, [filter, repo, getAccessToken])

    return {
        charges
        , filter
        , setFilter
        , fetchMore
        , downloadCsv
        , isFetching
        , isFetchingMore
        , isDownloading
    }
}

const repo = new ReportChargeRepo()

interface ChargeListStateProps {
    facility: any[]
    , practice: any[]
    , chargeStatus: any[]
}

const ChargeList = (props: ChargeListStateProps) => {
    const { charges, filter, setFilter, isFetching, isFetchingMore, isDownloading, fetchMore, downloadCsv } = useReportCharges(repo)

    const defaultOption = { id: 0, attributes: { name: "All" } }
    const practiceOptions: any[] = []

    if (props.practice) {
        for (const practice of props.practice) {
            practiceOptions.push({
                id: practice.meta ? practice.meta.facilities.map((f: any) => f.id).join(",") : 0
                , attributes: {
                    name: practice.attributes.name
                }
            })

            for (const facility of practice.meta.facilities) {
                practiceOptions.push({
                    id: facility.id
                    , attributes: {
                        name: "- " + facility.name
                    }
                })
            }
        }
    }

    return <>
        <Navbar />
        <div className="filter-bar">
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <FormLabel>Search</FormLabel>
                        <FormControl type="text"
                            id="search"
                            placeholder="CPT or Note..."
                            onChange={(e: any) => {
                                (window as any).searchQuery = e.target.value;

                                clearTimeout((window as any).searchTimeout);

                                (window as any).searchTimeout = setTimeout(() => {
                                    setFilter({ ...filter, search: (window as any).searchQuery })
                                }, 500);
                            }} />
                    </FormGroup>
                </Col>

                <Col md={3} >
                    <FormGroup>
                        <FormLabel>Practice / Facility</FormLabel>
                        <Select name="form-field-name"
                            id="facility-dropdown"
                            defaultValue={defaultOption}

                            onChange={(option: any) => {
                                setFilter({ ...filter, facilityIds: option.id === 0 ? "" : option.id })
                            }}

                            options={[defaultOption].concat(practiceOptions)}

                            getOptionLabel={(option: any) => option.attributes.name}
                            getOptionValue={(option: any) => option.id}
                        />
                    </FormGroup>
                </Col>

                <Col md={2} >
                    <FormGroup>
                        <FormLabel>Status</FormLabel>
                        <Select name="form-field-name"
                            id="status-dropdown"
                            defaultValue={defaultOption}

                            onChange={(option: any) => {
                                setFilter({ ...filter, statusIds: option.id === 0 ? "1,2" : option.id })
                            }}

                            options={[defaultOption].concat(props.chargeStatus)}

                            getOptionLabel={(option: any) => option.attributes.name}
                            getOptionValue={(option: any) => option.id}
                        />
                    </FormGroup>
                </Col>

                <Col md={1} >
                    <FormGroup>
                        <FormLabel>Range Start</FormLabel>
                        <DateInput id="range-start-input" startDate={filter.rangeStart} onChangeDate={(rangeStart: string) => { setFilter({ ...filter, rangeStart }) }} />
                    </FormGroup>
                </Col>

                <Col md={1} >
                    <FormGroup>
                        <FormLabel>Range End</FormLabel>
                        <DateInput id="range-end-input" startDate={filter.rangeEnd} onChangeDate={(rangeEnd: string) => { setFilter({ ...filter, rangeEnd }) }} />
                    </FormGroup>
                </Col>

                <Col md={1}>
                    <Button id="download-csv" style={{ marginTop: "2em" }} disabled={isDownloading} onClick={downloadCsv}>Download CSV</Button>
                </Col>
            </Row>

        </div>

        <Table style={{ marginTop: "calc(11em - 2px)" }}>
            <thead>
                <tr>
                    <th>Patient</th>
                    <th>DOS</th>
                    <th>CPT</th>
                    <th>Note</th>
                    <th>Facility</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    isFetching
                        ? <tr><td colSpan={6}>Loading...</td></tr>
                        : !charges || !charges.length
                            ? <tr><td colSpan={6}>No results</td></tr>
                            : charges.map((charge: ReportCharge) => {
                                return <tr key={charge.id}>
                                    <td><a href={`/tx/${charge.treatment.id}`}>{charge.treatment.patient.lastName}, {charge.treatment.patient.firstName}</a></td>
                                    <td>{mdY(charge.dos.substr(0, 10))}</td>
                                    <td>{charge.cpt.code}</td>
                                    <td>{charge.note}</td>
                                    <td>{charge.facility.name}</td>
                                    <td>{charge.status.name}</td>
                                </tr>
                            }
                            )
                }
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={6} className="text-center">
                        <Button onClick={fetchMore} disabled={isFetchingMore}>Fetch More</Button>
                    </td>
                </tr>
            </tfoot>
        </Table>
    </>
}


const mapStateToProps = (state: any, ownProps: {}): ChargeListStateProps => {
    return {
        facility: state.facility.data
        , practice: state.practice.data
        , chargeStatus: state.chargeStatus.data
    }
}

export default connect(mapStateToProps)(ChargeList)