import React, { FormEvent } from "react"
import { FormGroup, FormLabel, FormControl } from "react-bootstrap"

export interface Patient {
    accountNumber: string
    dob: string
    firstName: string
    lastName: string
    gender: string
}

export interface SelectPatientProps {
    patient: Patient
    onChange: (patient: Patient) => void
}

const SelectPatient = (props: SelectPatientProps) => {
    const { patient, onChange } = props

    const handleUpdateFirstName = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...patient, firstName: e.currentTarget.value })
    }

    const handleUpdateLastName = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...patient, lastName: e.currentTarget.value })
    }

    const handleUpdateAccountNumber = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...patient, accountNumber: e.currentTarget.value })
    }

    const handleUpdateDOB = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...patient, dob: e.currentTarget.value })
    }

    const handleUpdateGender = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...patient, gender: e.currentTarget.value })
    }

    return <div>
        <FormGroup>
            <FormLabel>First Name</FormLabel>
            <FormControl type="input" value={patient.firstName} onChange={handleUpdateFirstName} />
        </FormGroup>
        <FormGroup>
            <FormLabel>Last Name</FormLabel>
            <FormControl type="input" value={patient.lastName} onChange={handleUpdateLastName} />
        </FormGroup>
        <FormGroup>
            <FormLabel>Account Number</FormLabel>
            <FormControl type="input" value={patient.accountNumber} onChange={handleUpdateAccountNumber} />
        </FormGroup>
        <FormGroup>
            <FormLabel>DOB</FormLabel>
            <FormControl type="input" value={patient.dob} onChange={handleUpdateDOB} />
        </FormGroup>
        <FormGroup>
            <FormLabel>Gender</FormLabel>
            <FormControl type="input" value={patient.gender} onChange={handleUpdateGender} />
        </FormGroup>
    </div>
}

export default SelectPatient