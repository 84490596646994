import React, { useEffect, useState } from "react"
import { Table, Row, Col, FormGroup, FormControl, FormLabel, Button } from "react-bootstrap"

import Navbar from "./components/Navbar"
import NewReferringPhysicianModal from "./components/modal/NewReferringPhysicianModal"

import { ReferringProvider, IReferringProviderRepo } from "./core/entities/referringProvider"
import { ReferringProviderRepo } from "./core/repos/referringProviderRepo"
import { dispatchEvent } from "./hooks/useEvent"

export const useReferringProvidersList = (repo: IReferringProviderRepo) => {
	const [referringProviders, setReferringProviders] = useState([] as ReferringProvider[])
	const [search, setSearch] = useState("")
	const [isFetching, setIsFetching] = useState(false)

	useEffect(() => {
		const fetchReferringProviders = async () => {
			setIsFetching(true)

			const resp = await fetch(`/v2/referring-providers?search=${search}`)
			const json = await resp.json()

			setIsFetching(false)

			setReferringProviders(json)
		}

		fetchReferringProviders()
	}, [fetch, search])

	const setSearchHandler = (e: React.FormEvent<HTMLInputElement>) => {
		setSearch(e.currentTarget.value)
	}

	return {
		referringProviders
		, search
		, setSearch
		, setSearchHandler
		, isFetching
	}
}

const repo = new ReferringProviderRepo()

const ReferringPhysicianList = () => {
	const { referringProviders, setSearchHandler, isFetching } = useReferringProvidersList(repo)

	const newReferringPhysician = () => {
		dispatchEvent("openNewReferringPhysicianModal", {})
	}

	return <>
		<Navbar />
		<div className="body">
			<NewReferringPhysicianModal />
			<Row style={{ background: "white", padding: "10px 20px" }}>
				<Col md={3}>
					<FormGroup>
						<FormLabel>Search</FormLabel>
						<FormControl type="text" onChange={setSearchHandler} />
					</FormGroup>
				</Col>
				<Col>
					<Button size="sm" style={{ marginTop: "35px" }} onClick={() => newReferringPhysician()}>New Referring Physician</Button>
				</Col>
			</Row>
			<Table bordered>
				<thead>
					<tr>
						<th>Name</th>
						<th>Code</th>
						<th>External Id</th>
					</tr>
				</thead>
				<tbody>
					{
						isFetching
							? <tbody><tr><td colSpan={2}>Loading...</td></tr></tbody>
							: referringProviders.map((rp: any) => {
								return <tr key={rp.id}>
									<td>{rp.name}</td>
									<td>{rp.code}</td>
									<td>{rp.external_id}</td>
								</tr>
							})
					}
				</tbody>
			</Table>
		</div>
	</>
}

export default ReferringPhysicianList