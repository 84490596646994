import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { Row, Col, Form } from "react-bootstrap"
import Select from "react-select"
import { cloneDeep } from "lodash"
import { AppState } from "../../../reducers";
import ChargeContext from "../../../contexts/ChargeContext"

const Modifiers = () => {
	const cptModifiers = useSelector((state: AppState) => state.cptModifier.data)
	const [charge, setCharge] = useContext(ChargeContext)

	const setModifier = (which: string, id: number) => {
		const next = cloneDeep(charge)
		next.relationships.modifiers[which] = { data: { id } }
		setCharge(next)
	}

	return <Form.Group>
		<Form.Label>Modifiers</Form.Label>
		<Row>
			<Col md={3} style={{ paddingRight: 0 }}>
				<Select
					value={cptModifiers.find((modifier: any) => modifier.id === charge.relationships.modifiers.mod1.data?.id) || { id: 0, attributes: { name: "Default" } }}

					defaultValue={{ id: 0, attributes: { name: "Default" } }}

					onChange={(option: any) => setModifier("mod1", option.id)}

					options={[{ id: 0, attributes: { name: "Default" } }].concat(cptModifiers)}

					getOptionLabel={(option: any) => option.attributes.name}
					getOptionValue={(option: any) => option.id}
				/>
			</Col>
			<Col md={3} style={{ paddingLeft: "10px", paddingRight: "5px" }}>
				<Select
					value={cptModifiers.find((modifier: any) => modifier.id === charge.relationships.modifiers.mod2.data?.id) || { id: 0, attributes: { name: "Default" } }}

					defaultValue={{ id: 0, attributes: { name: "Default" } }}

					onChange={(option: any) => setModifier("mod2", option.id)}

					options={[{ id: 0, attributes: { name: "Default" } }].concat(cptModifiers)}

					getOptionLabel={(option: any) => option.attributes.name}
					getOptionValue={(option: any) => option.id}
				/>
			</Col>
			<Col md={3} style={{ paddingLeft: "5px", paddingRight: "10px" }}>
				<Select
					value={cptModifiers.find((modifier: any) => modifier.id === charge.relationships.modifiers.mod3.data?.id) || { id: 0, attributes: { name: "Default" } }}

					defaultValue={{ id: 0, attributes: { name: "Default" } }}

					onChange={(option: any) => setModifier("mod3", option.id)}

					options={[{ id: 0, attributes: { name: "Default" } }].concat(cptModifiers)}

					getOptionLabel={(option: any) => option.attributes.name}
					getOptionValue={(option: any) => option.id}
				/>
			</Col>
			<Col md={3} style={{ paddingLeft: 0 }}>
				<Select
					value={cptModifiers.find((modifier: any) => modifier.id === charge.relationships.modifiers.mod4.data?.id) || { id: 0, attributes: { name: "Default" } }}

					defaultValue={{ id: 0, attributes: { name: "Default" } }}

					onChange={(option: any) => setModifier("mod4", option.id)}

					options={[{ id: 0, attributes: { name: "Default" } }].concat(cptModifiers)}

					getOptionLabel={(option: any) => option.attributes.name}
					getOptionValue={(option: any) => option.id}
				/>
			</Col>
		</Row>
	</Form.Group>
}

export default Modifiers