import { ChargeSetStatusModalPayload, ChargeSetStatusModalAction } from "../../actions/modal/ChargeSetStatusModalActions"

export interface ChargeSetStatusModalState {
    show: boolean
    chargeId: number
}

const documentSetStatusModalReducer = (state: ChargeSetStatusModalState, payload: ChargeSetStatusModalPayload): ChargeSetStatusModalState => {
    const nextState = { ...state };

    switch (payload.type) {
        case ChargeSetStatusModalAction.OPENED:
            nextState.show = true;
            nextState.chargeId = payload.chargeId ? payload.chargeId : 0;
            break;
        case ChargeSetStatusModalAction.CLOSED:
            nextState.show = false;
            break;
    }

    return nextState;
};

export default documentSetStatusModalReducer;