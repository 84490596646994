import { useEffect, useState } from "react"

export const useData = (url: string, mapFn?: (item: any) => any) => {
    const [data, setData] = useState([])

    useEffect(() => {
        async function myFetch() {
            const result = await fetch(url, {
                // headers: {
                //     "Authorization": "Bearer " + localStorage.getItem("chargebook_access_token")
                // }
            })

            const json = await result.json()

            if ("data" in json) {
                setData(mapFn ? json.data.map(mapFn) : json.data)
            } else {
                setData(mapFn ? json.map(mapFn) : json)
            }

        }

        myFetch()
    }, [url, mapFn])

    return {
        data
    }
}

export const useCustomData = (url: Promise<string>, mapFn?: (item: any) => any) => {
    const [data, setData] = useState([])

    useEffect(() => {
        async function myFetch() {
            const result = await fetch(await url, {
                // headers: {
                //     "Authorization": "Bearer " + localStorage.getItem("chargebook_access_token")
                // }
            })

            const json = await result.json()

            setData(mapFn ? json.data.map(mapFn) : json.data)
        }

        myFetch()
    }, [url, mapFn])

    return {
        data
    }
}