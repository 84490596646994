import React, { useEffect, ReactNode } from "react"
import { connect, useDispatch } from "react-redux"
import { AppState } from "./reducers"
import { useTokens } from "./useAuthorizationCodeFlow"
import { getTenantId } from "./common/jwt"

import * as referringPhysicianActions from "./actions/ReferringPhysicianActions"
import * as physicianActions from "./actions/PhysicianActions"
import * as practiceActions from "./actions/PracticeActions"
import * as cptActions from "./actions/CPTActions"
import * as facilityActions from "./actions/FacilityActions"
import * as chargeStatusActions from "./actions/ChargeStatusActions"
import * as insuranceActions from "./actions/InsuranceActions"
import * as mipsMeasureActions from "./actions/MIPSMeasureActions"
import * as icd10Actions from "./actions/ICD10Actions"
import * as modalityActions from "./actions/ModalityActions"
import * as treatmentStatusActions from "./actions/TreatmentStatusActions"
import * as userActions from "./actions/UserActions"
import * as cptModiferActions from "./actions/CPTModifierActions"
import * as documentStatusActions from "./actions/DocumentStatusActions"

interface StaticLoadProps {
	children: ReactNode
}

interface StaticLoaderStateProps {
	modality: modalityActions.ModalityState
	facility: facilityActions.FacilityState
	insurance: insuranceActions.InsuranceState
	referringPhysician: referringPhysicianActions.ReferringPhysicianState
	physician: physicianActions.PhysicianState
	mipsMeasure: mipsMeasureActions.MIPSMeasureState
	practice: practiceActions.PracticeState
	user: userActions.UserState
	cpt: cptActions.CPTState
	cptModifier: cptModiferActions.CPTModifierState
	icd10: icd10Actions.ICD10State
	chargeStatus: chargeStatusActions.ChargeStatusState
	documentStatusList: documentStatusActions.DocumentStatusState
	treatmentStatus: treatmentStatusActions.TreatmentStatusState
}

const StaticLoader = (props: StaticLoadProps & StaticLoaderStateProps) => {
	const dispatch = useDispatch()
	const { getAccessToken } = useTokens()

	useEffect(() => {
		if (true) {
			dispatch(facilityActions.fetch(getTenantId()))
			dispatch(physicianActions.fetch(getTenantId()))
			dispatch(practiceActions.fetch(getTenantId()))
			dispatch(cptActions.fetch())
			dispatch(referringPhysicianActions.fetch(getTenantId()))
			dispatch(cptModiferActions.fetch())
			dispatch(chargeStatusActions.fetch())
			dispatch(documentStatusActions.fetch())
			dispatch(insuranceActions.fetch(getTenantId()))
			dispatch(mipsMeasureActions.fetch())
			dispatch(icd10Actions.fetch())
			dispatch(modalityActions.fetch())
			dispatch(treatmentStatusActions.fetch())
			dispatch(userActions.fetch(getTenantId()))
		}
	}, [dispatch, getAccessToken])

	const {
		modality
		, facility
		, insurance
		, referringPhysician
		, physician
		, mipsMeasure
		, practice
		, user
		, cpt
		, cptModifier
		, icd10
		, chargeStatus
		, documentStatusList
		, treatmentStatus
	}: any = props

	const staticProps = [
		modality
		, facility
		, insurance
		, referringPhysician
		, physician
		, mipsMeasure
		, practice
		, user
		, cpt
		, cptModifier
		, icd10
		, chargeStatus
		, documentStatusList
		, treatmentStatus
	]

	const staticPropsNames = [
		"Modality"
		, "Facility"
		, "Insurance"
		, "Referring Physician"
		, "Physician"
		, "MIPS Measure"
		, "Practice"
		, "User"
		, "Cpt"
		, "Cpt Modifier"
		, "Icd10"
		, "Charge Status"
		, "Document Status"
		, "Treatment Status"
	]

	let staticPropsBeingLoaded = []

	for (let index in staticProps) {
		if (staticProps[index].loading) {
			staticPropsBeingLoaded.push(staticPropsNames[index])
		}
	}

	if (staticPropsBeingLoaded.length) {
		return <ul style={{ marginTop: "35px" }}>
			{
				staticPropsBeingLoaded.map((staticProp: string, index: number) => {
					return <li key={index} style={{ listStyle: "none" }}>Loading {staticProp}...</li>
				})
			}
		</ul>
	}

	return <>{props.children}</>
}

const mapStateToProps = (state: AppState, ownProps: StaticLoadProps): StaticLoaderStateProps => {
	const {
		modality
		, facility
		, insurance
		, referringPhysician
		, physician
		, mipsMeasure
		, practice
		, user
		, cpt
		, cptModifier
		, icd10
		, chargeStatus
		, documentStatusList
		, treatmentStatus
	} = state

	return {
		modality
		, facility
		, insurance
		, referringPhysician
		, physician
		, mipsMeasure
		, practice
		, user
		, cpt
		, cptModifier
		, icd10
		, chargeStatus
		, documentStatusList
		, treatmentStatus
		, ...ownProps
	}
}

export default connect(mapStateToProps)(StaticLoader)