export interface Patient {
    id: number
    mrn: string
    acct: string
    dob: string
    firstName: string
    lastName: string
    middleInitial: string
    gender: string
}

export const defaultPatient: Patient = {
    id: 0
    , mrn: ''
    , acct: ''
    , dob: ''
    , firstName: ''
    , lastName: ''
    , middleInitial: ''
    , gender: 'M'
}