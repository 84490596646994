import { ChargePayload, ChargeAction } from "../actions/ChargeActions"

export interface NotificationListState {
    data: any[]
}

const reducer = (prevState: NotificationListState, action: ChargePayload): NotificationListState => {
    const state = { ...prevState }

    switch (action.type) {
        case ChargeAction.ADD_CHARGE_COMPLETED:
        case ChargeAction.TRANSITION_CHARGE_COMPLETED:
            for (let notification of action.response.data.notifications) {
                state.data.push(notification)
            }
            break

        case ChargeAction.SET_STATUS_ERROR:
            for (let error of action.data.errors) {
                state.data.push({ title: "Error", message: error, style: "danger" })
            }
            break

    }

    return state
}

export default reducer
