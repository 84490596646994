import React, { useEffect, useState } from "react"
import { connect, useDispatch } from 'react-redux'
import * as B from "react-bootstrap"
import actions from "../../actions"
import * as documentStatusActions from "../../actions/DocumentStatusActions"
import { useTokens } from "../../useAuthorizationCodeFlow"
import { getTenantId } from "../../common/jwt"

import Select from 'react-select'

export interface DocumentEditModalStateProps {
    show: boolean

    document?: any

    statuses: any[]
    assignees: any[]
}

const DocumentEditModal = (props: DocumentEditModalStateProps) => {
    const { getAccessToken } = useTokens()
    const { show, statuses, assignees } = props

    const dispatch = useDispatch()
    const [doc, setDoc] = useState(props.document!)
    useEffect(() => {
        dispatch(documentStatusActions.fetch())
    })

    const save = (document: any) => {
        dispatch(actions().document.update(getTenantId(), document))
        close()
    }

    const close = () => {
        dispatch(actions().documentEditModal.close())
    }

    const handleSelectStatus = (statusId: number) => {
        const nextDoc = Object.assign({}, doc)
        doc.relationships.status.id = statusId

        setDoc(nextDoc)
    }

    const handleSelectAssignee = (assigneeId: number) => {
        const nextDoc = Object.assign({}, doc)
        doc.relationships.assignee.id = assigneeId

        setDoc(nextDoc)
    }

    if (!doc) {
        return null
    }

    return <div>
        <B.Modal show={show} className="treatment-modal">
            <form onSubmit={(e: any) => {
                e.preventDefault()
                save(doc)
                return false
            }}>
                <B.Modal.Header>
                    <B.Modal.Title>Edit Document</B.Modal.Title>
                </B.Modal.Header>
                <B.Modal.Body>
                    <B.FormGroup>
                        <B.FormLabel>Status</B.FormLabel>
                        {
                            !statuses
                                ? null
                                : <Select name="form-field-name"
                                    value={doc.relationships.status.id}

                                    onChange={(option: any) => {
                                        handleSelectStatus(option.value)
                                    }}

                                    options={statuses.map((status: any) => {
                                        return {
                                            value: status.id
                                            , label: status.attributes.name
                                        }
                                    })}
                                />
                        }
                    </B.FormGroup>
                    <B.FormGroup>
                        <B.FormLabel>Assignee</B.FormLabel>
                        {
                            !assignees
                                ? null
                                : <Select name="form-field-name"
                                    value={doc.relationships.assignee.id}

                                    onChange={(option: any) => {
                                        handleSelectAssignee(option.value)
                                    }}

                                    options={assignees.map((assignee: any) => {
                                        return {
                                            value: assignee.id
                                            , label: assignee.attributes.name
                                        }
                                    })}
                                />
                        }
                    </B.FormGroup>
                </B.Modal.Body>
                <B.Modal.Footer>
                    <B.Row>
                        <B.Col md={6}>

                        </B.Col>
                        <B.Col md={6}>
                            <B.Button onClick={(e: any) => { close() }}>Cancel</B.Button>
                            <B.Button type="submit">Save</B.Button>
                        </B.Col>
                    </B.Row>
                </B.Modal.Footer>
            </form>
        </B.Modal>
    </div>
}

const mapStateToProps = (nextState: any, ownProps: {}): DocumentEditModalStateProps => {
    return {
        show: nextState.documentEditModal ? nextState.documentEditModal.show : false

        , document: !nextState.documentEditModal || !nextState.documentEditModal.documentId || nextState.documentEditModal.show === false ? null : nextState.document.data.find((document: any) => {
            return document.id === nextState.documentEditModal.documentId
        })

        , statuses: !nextState.documentStatusList.data.length ? null : nextState.documentStatusList.data
        , assignees: nextState.user.data
    }
}

export default connect(mapStateToProps)(DocumentEditModal)
