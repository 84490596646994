
import React, { FormEvent } from "react"
import { FormGroup, FormLabel, FormControl, FormText } from "react-bootstrap"

export interface Physician {
	name: string
	address: string
}

export interface SelectPhysicianProps {
	physician: Physician
	onChange: (physician: Physician) => void
}

const SelectPhysician = (props: SelectPhysicianProps) => {
	const { physician, onChange } = props

	const handleChangeName = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...physician, name: e.currentTarget.value })
	}

	const handleChangeAddress = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...physician, address: e.currentTarget.value })
	}

	return <>
		<FormGroup>
			<FormLabel>Physician Name</FormLabel>
			<FormControl type="input" value={physician.name} onChange={handleChangeName} />
		</FormGroup>
		<FormGroup>
			<FormLabel>Physician Address</FormLabel>
			<FormControl type="input" value={physician.address} onChange={handleChangeAddress} />
			<FormText>Optional</FormText>
		</FormGroup>
	</>
}

export default SelectPhysician