import React, { useState } from "react"
import { render } from "react-dom"

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from "redux-devtools-extension"
import thunkMiddleware from 'redux-thunk'

import rootReducer from "./reducers"

import NotificationList from "./components/NotificationList"

import StaticLoader from "./StaticLoader"
import TreatmentList from "./TreatmentList"
import Treatment from "./Treatment"
import ReferringPhysicianList from "./ReferringPhysicianList"
import PreauthList from "./PreauthList"
import ChargeList from "./ChargeList"
import ImportList from "./ImportList"
import DocumentList from "./DocumentList"
import DXList from "./DXList"
import PracticeList from "./pages/PracticeList"
import FacilityList from "./pages/FacilityList"

import AuthContext from "./contexts/AuthContext"

import { Provider } from 'react-redux'
import { Router, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { useAuth } from "./useAuth"
import { useEvent } from "./hooks/useEvent"

import "./themes/bootstrap.min.css"
import "./index.css"

document.title = "ChargeBook"

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)))
var history = createBrowserHistory()

const ProtectedApp = () => {
	const { initialized, loggedIn, logout, getAccessToken } = useAuth()

	if (!initialized) {
		return null
	}

	if (!loggedIn) {
		return <div>You are not logged in.</div>
	}

	return <AuthContext.Provider value={[getAccessToken, logout]}>
		<Provider store={store}>
			<StaticLoader>
				<NotificationList />
				<Route path="/" component={TreatmentList} exact={true} />
				<Route path="/tx/:id" component={(match: any) => { return <Treatment treatmentId={match.match.params.id} /> }} />
				<Route path="/Charges" component={ChargeList} />
				<Route path="/Imports" component={ImportList} />
				<Route path="/Documents" component={DocumentList} />
				<Route path="/Preauths" component={PreauthList} />
				<Route path="/Admin/ReferringPhysician" component={ReferringPhysicianList} />
				<Route path="/Admin/DX" component={DXList} />
				<Route path="/Admin/Practice" component={PracticeList} />
				<Route path="/Admin/Facility" component={FacilityList} />
			</StaticLoader>
		</Provider>
		<Overlay />
	</AuthContext.Provider>
}

const Overlay = () => {
	const [display, setDisplay] = useState("none")

	useEvent("displayTimeoutOverlay", () => {
		setDisplay("block")
	})

	useEvent("clearTimeoutOverlay", () => {
		setDisplay("none")
	})

	const style: React.CSSProperties = {
		position: "fixed"
		, display: display
		, width: "100%"
		, height: "100%"
		, top: 0
		, left: 0
		, right: 0
		, bottom: 0
		, backgroundColor: "rgba(0, 0, 0, 0.7)"
		, zIndex: 2000
		, cursor: "pointer"
	}

	return <div style={style}>
		<div className="card" style={{ width: "30em", left: 0, right: 0, marginLeft: "auto", marginRight: "auto", marginTop: "20em" }} >
			<h5 className="card-header">Automatic Logout</h5>
			<div className="card-body">
				<p className="card-text">You are about to be logged out due to inactivity.</p>
			</div>
		</div>
	</div >
}

const root = <React.StrictMode>
	<Router history={history}>
		<ProtectedApp />
	</Router>
</React.StrictMode >

// utility function called by getCookie( )
function getCookieVal(offset: number) {
	var endstr = document.cookie.indexOf(";", offset);
	if (endstr === -1) {
		endstr = document.cookie.length;
	}
	return document.cookie.substring(offset, endstr)
}

// primary function to retrieve cookie by name
function getCookie(name: string) {
	var arg = name + "=";
	var alen = arg.length;
	var clen = document.cookie.length;
	var i = 0;
	while (i < clen) {
		var j = i + alen;
		if (document.cookie.substring(i, j) === arg) {
			return getCookieVal(j);
		}
		i = document.cookie.indexOf(" ", i) + 1;
		if (i === 0) break;
	}
	return "";
}

render(root, document.getElementById("root"));
