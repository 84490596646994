import React, { useState, useRef, FormEvent, useEffect } from "react"
import { connect } from 'react-redux'
import { Modal, Row, Col, Form, Button, ButtonGroup } from "react-bootstrap"
import Axios from "../../common/axios"
import moment from "moment"

import DateInput from "../DateInput"
import DateRange from "../DateRange"

import ModalitySelect from "../formcontrol/ModalitySelect"
import InsuranceSelect from "../formcontrol/InsuranceSelect"
import FacilitySelect from "../formcontrol/FacilitySelect"
import TreatmentStatusSelect from "../formcontrol/TreatmentStatusSelect"
import ReferringPhysicianSelect from "../formcontrol/ReferringPhysicianSelect"
import UserSelect from "../formcontrol/UserSelect"
import DiagnosisSelect from "../formcontrol/DiagnosisSelect"

import { byId } from "../../common/jsonapi-filters"
import { AppState } from "../../reducers"
import { useTokens } from "../../useAuthorizationCodeFlow"
import { getTenantId } from "../../common/jwt"
import { useEvent } from "../../hooks/useEvent"

import { Treatment } from "../../core/entities/treatment"

import "./NewTreatmentModal.css"

interface TreatmentModalStateProps {
	insurances: any[]
	igrts: any[]
}

const TreatmentModal = (props: TreatmentModalStateProps) => {
	const { insurances, igrts } = props

	const [isOpen, setIsOpen] = useState(false)
	const [numPhases, setNumPhases] = useState(1)
	const [numAdmissions, setNumAdmissions] = useState(0)
	const [waiting, setWaiting] = useState(false)
	const [isValid, setIsValid] = useState(false)
	const [diagnosis, setDiagnosis] = useState<string[]>([])
	const [numChanges, setNumChanges] = useState(0)

	const [defaultTreatment, setDefaultTreatment] = useState<Treatment | undefined>()

	const [tenantPrefs, setTenantPrefs] = useState<any>()

	const formRef = useRef<HTMLFormElement>(null)

	const { getAccessToken } = useTokens()

	useEffect(() => {
		setIsValid(validate())
	}, [numChanges, diagnosis])

	useEvent("openNewTreatmentModal", ({ treatment }) => {
		if (treatment) {
			treatment.patient.dob = moment(treatment.patient.dob.substring(0, 10)).format("MM/DD/YYYY")
			setDefaultTreatment(treatment)
		}

		setIsOpen(true)

		const getPrefs = async () => {
			const resp = await fetch(`/v2/tenant-prefs`)
			const prefs = await resp.json()

			console.log("setTenantPrefs", prefs)
			
			setTenantPrefs(prefs)
		}

		getPrefs()
	})

	const save = async () => {
		if (!isValid) {
			return
		}

		setWaiting(true)

		const patient: any = {
			id: null
			, lid: 1
			, type: "Patient"
			, attributes: {
				name: ""
				, firstName: getFieldValue("patient.firstName")
				, lastName: getFieldValue("patient.lastName")
				, middleInitial: getFieldValue("patient.middleInitial")
				, mrn: getFieldValue("patient.mrn")
				, acct: getFieldValue("patient.acct")
				, gender: queryFieldValue(`select[name="patient.gender"]`)
				, dob: getFieldValue("patient.dob")
			}
		}

		const treatment: any = {
			id: null
			, attributes: {
				note: queryFieldValue(`textarea[name="treatment.note"]`)
				, insurance_auth_number: getFieldValue("treatment.insuranceAuthNumber")
				, diagnosis: [...diagnosis]
			}
			, relationships: {
				patient: {
					data: { id: null, lid: 1, type: "Patient" }
				}
				, modality: {
					data: { id: parseInt(getFieldValue("treatment.modalityId")!), type: "Modality" }
				}
				, facility: {
					data: { id: parseInt(getFieldValue("treatment.facilityId")!), type: "Facility" }
				}
				, insurance: {
					data: { id: parseInt(getFieldValue("treatment.insuranceId")!), type: "Insurance" }
				}
				, referringphysician: {
					data: { id: parseInt(getFieldValue("treatment.referringPhysicianId")!), type: "ReferringPhysician" }
				}
				, assignee: {
					data: { id: parseInt(getFieldValue("treatment.assigneeId")!), type: "User" }
				}
				, status: {
					data: { id: parseInt(getFieldValue("treatment.statusId")!), type: "TreatmentStatus" }
				}
				, admission: {
					data: [...Array(numAdmissions)].map((_, index: number) => {
						return { id: null, lid: index + 1, type: "Admission" }
					})
				}
				, phase: {
					data: [...Array(numAdmissions)].map((_, index: number) => {
						return { id: null, lid: index + 1, type: "Phase" }
					})
				}
			}
		}

		const phases = [...Array(numPhases)].map((_, index: number) => {
			return {
				id: null
				, lid: index + 1
				, type: "Phase"
				, attributes: {
					name: getFieldValue(`phase.${index}.name`)
					, fractions: parseInt(getFieldValue(`phase.${index}.fractions`)!)
				}
				, relationships: {
					igrt: {
						data: {
							id: getFieldValue(`phase.${index}.igrtId`) || null
							, type: "CPT"
						}
					}
				}
			}
		})

		const admissions = [...Array(numAdmissions)].map((_, index: number) => {
			return {
				id: null
				, lid: index + 1
				, type: "Admission"
				, attributes: {
					admit: queryFieldValue(`div#admission-${index} input[name="startDate"]`) || null
					, discharge: queryFieldValue(`div#admission-${index} input[name="endDate"]`) || null
				}
			}
		})

		const operations = []

		operations.push({
			op: "add"
			, ref: { lid: patient.lid, type: "Patient" }
			, data: patient
		})

		operations.push({
			op: "add"
			, ref: { lid: treatment.lid, type: "Treatment" }
			, data: treatment
		})

		for (const phase of phases) {
			operations.push({
				op: "add"
				, ref: { lid: phase.lid, type: "Phase" }
				, data: phase
			})
		}

		for (const admission of admissions) {
			operations.push({
				op: "add"
				, ref: { lid: admission.lid, type: "Admission" }
				, data: admission
			})
		}

		await Axios.post(`/api/${getTenantId()}/treatment/operations`, JSON.stringify({ operations }))

		setWaiting(false)
		close()
	}
	const close = () => {
		setDefaultTreatment(undefined)
		setIsOpen(false)
	}

	const validate = () => {
		const requiredFieldNames = [
			"patient.mrn"
			, "patient.firstName"
			, "patient.lastName"
			, "treatment.modalityId"
			, "treatment.insuranceId"
			, "treatment.facilityId"
			, "treatment.referringPhysicianId"
			, "treatment.assigneeId"
		]

		for (let fieldName of requiredFieldNames) {
			if (!getFieldValue(fieldName)) {
				return false
			}
		}

		if (!diagnosis.length) {
			return false
		}

		return true
	}

	const getFieldValue = (fieldName: string): string | undefined => {
		return queryFieldValue(`input[name="${fieldName}"]`)
	}

	const queryFieldValue = (selectors: string): string | undefined => {
		return formRef?.current?.querySelector<HTMLInputElement>(selectors)?.value
	}

	const handleAddPhase = () => {
		setNumPhases(numPhases + 1)
	}

	const handleRemovePhase = () => {
		if (numPhases > 1) {
			setNumPhases(numPhases - 1)
		}
	}

	const handleAddAdmission = () => {
		setNumAdmissions(numAdmissions + 1)
	}

	const handleRemoveAdmission = () => {
		if (numAdmissions > 0) {
			setNumAdmissions(numAdmissions - 1)
		}
	}

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		save()
		return false
	}

	const handleAnyChange = () => {
		setNumChanges(numChanges + 1)
	}

	const handleChangeDiagnosis = (dx: any[]) => {
		setDiagnosis(dx ? dx.map(option => option.value) : [])
	}

	// TODO: There must be a better way
	if (!tenantPrefs) {
		return null
	}

	return <Modal show={isOpen} onHide={close} className="treatment-modal">
		<form onSubmit={handleSubmit} ref={formRef}>
			<Modal.Header>
				<Modal.Title>New Treatment</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col md={4}>
						<Form.Group>
							<Form.Label>MRN</Form.Label>
							<input type="text" className="form-control" name="patient.mrn" defaultValue={defaultTreatment?.patient.mrn} onChange={handleAnyChange} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Acct</Form.Label>
							<input type="text" className="form-control" name="patient.acct" defaultValue={defaultTreatment?.patient.acct} onChange={handleAnyChange} />
						</Form.Group>

						<Form.Group>
							<Form.Label>DOB</Form.Label>
							<DateInput dateFormat="MM/DD/YYYY" name="patient.dob" startDate={defaultTreatment?.patient.dob} onChange={handleAnyChange} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Gender</Form.Label>
							<Form.Control as="select" name="patient.gender" defaultValue={defaultTreatment?.patient.gender} onChange={handleAnyChange}>
								<option value={"M"}>M</option>
								<option value={"F"}>F</option>
							</Form.Control>
						</Form.Group>

						<Form.Group>
							<Form.Label>Last Name</Form.Label>
							<Form.Control type="text" name="patient.lastName" defaultValue={defaultTreatment?.patient.lastName} onChange={handleAnyChange} />
						</Form.Group>

						<Row>
							<Col md={8}>
								<Form.Group>
									<Form.Label>First Name</Form.Label>
									<Form.Control type="text" name="patient.firstName" defaultValue={defaultTreatment?.patient.firstName} onChange={handleAnyChange} />
								</Form.Group>
							</Col>

							<Col>
								<Form.Group>
									<Form.Label>MI</Form.Label>
									<Form.Control type="text" name="patient.middleInitial" onChange={handleAnyChange} />
								</Form.Group>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col md={6}>
								<ModalitySelect name="treatment.modalityId" defaultId={defaultTreatment?.modality.id} withLabel onChange={handleAnyChange} />
								<InsuranceSelect name="treatment.insuranceId" defaultId={defaultTreatment?.payor.id} withLabel onChange={handleAnyChange} />
								<FacilitySelect name="treatment.facilityId" defaultId={defaultTreatment?.facility.id} withLabel onChange={handleAnyChange} />
								<TreatmentStatusSelect name="treatment.statusId" withLabel />
							</Col>
							<Col>
								<ReferringPhysicianSelect name="treatment.referringPhysicianId" defaultId={defaultTreatment?.referringProvider.id} withLabel onChange={handleAnyChange} />

								<UserSelect name="treatment.assigneeId" defaultId={tenantPrefs?.defaults?.treatment?.assignee?.id} withLabel onChange={handleAnyChange} />

								<Form.Group>
									<Form.Label>Insurance Auth Number</Form.Label>
									<Form.Control name="treatment.insuranceAuthNumber" />
								</Form.Group>
							</Col>
						</Row>
						<Form.Group>
							<Form.Label>Diagnosis</Form.Label>
							<DiagnosisSelect onChangeDiagnosis={handleChangeDiagnosis} />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Form.Group>
							<Form.Label>Phases</Form.Label><br />
							{
								[...Array(numPhases)].map((_, index: number) => {
									return <Row style={{ marginBottom: "10px" }} key={index}>
										<Col md={5}>
											<Form.Control placeholder="Name" name={`phase.${index}.name`} defaultValue="Initial" />
										</Col>
										<Col md={3}>
											<Form.Control placeholder="Fractions" name={`phase.${index}.fractions`} defaultValue={25} />
										</Col>

										<Col md={4}>
											{
												<Form.Control as="select" name={`phase.${index}.igrtId`}>
													{
														[{ id: 0, attributes: { code: "IGRT (None)" } }]
															.concat(igrts.filter((igrt: any) => {
																const insurance = insurances.find(byId(parseInt(getFieldValue("treatment.insuranceId")!)))

																if (!insurance || !insurance.relationships.igrt.data.length) {
																	return true
																}

																return insurance.relationships.igrt.data.map((data: any) => data.id).indexOf(igrt.id) !== -1
															}))
															.map((igrt: any, optionIndex: number) => {
																let label = igrt.attributes.code

																if (igrt.attributes.mod1) {
																	label += ("-" + igrt.attributes.mod1)
																}

																return <option key={optionIndex} value={igrt.id}>{label}</option>
															})
													}
												</Form.Control>
											}
										</Col>
									</Row>
								})
							}

							<ButtonGroup>
								<Button size="sm" onClick={handleAddPhase}>Add</Button>
								<Button size="sm" onClick={handleRemovePhase}>Remove</Button>
							</ButtonGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="formValidationNull">
							<Form.Label>Admissions</Form.Label><br />
							{
								[...Array(numAdmissions)].map((_, index: number) => {
									return <div id={`admission-${index}`}>
										<DateRange />
									</div>
								})
							}
							<ButtonGroup>
								<Button size="sm" onClick={handleAddAdmission}>Add</Button>
								<Button size="sm" onClick={handleRemoveAdmission}>Remove</Button>
							</ButtonGroup>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group>
					<Form.Label>Note</Form.Label>
					<textarea className="form-control" name="treatment.note" rows={3}></textarea>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={close}>Cancel</Button>
				{
					waiting
						? <Button type="submit" disabled>Save...</Button>
						: <Button type="submit" disabled={!isValid}>Save</Button>
				}

			</Modal.Footer>
		</form>
	</Modal >
}

const mapStateToProps = (state: AppState): TreatmentModalStateProps => {
	const byCategoryId = (categoryId: number) => (item: any) => item.relationships.category.data.id === categoryId

	return {
		insurances: state.insurance.data
		, igrts: state.cpt.data.filter(byCategoryId(5))
	}
}

export default connect(mapStateToProps)(TreatmentModal)