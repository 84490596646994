import { Cpt, defaultCpt } from "./cpt"

export interface Phase {
    id: number
    name: string
    fractions: number
    igrt: Cpt
}

export const defaultPhase: Phase = {
    id: 0
    , name: ''
    , fractions: 0
    , igrt: defaultCpt
}