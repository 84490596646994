import React, { useEffect, useState, useContext } from "react"
import { Nav, Navbar, NavDropdown, NavItem, NavLink, Container } from "react-bootstrap"
import { Link } from 'react-router-dom'
import AuthContext from "../contexts/AuthContext"
import { Tenant } from "../core/entities/tenant"
import { TenantRepo } from "../core/repos/tenantRepo"
import { getTenantId } from "../common/jwt"

const N = () => {
	const [getAccessToken, logout] = useContext(AuthContext)
	const tenantId = getTenantId()

	const resetCache = () => {
		sessionStorage.removeItem(`/api/icd10`)
		sessionStorage.removeItem(`/api/cpt`)
		sessionStorage.removeItem(`/api/cpt-modifier`)
		sessionStorage.removeItem(`/api/modality`)
		sessionStorage.removeItem(`/api/mips-measure`)
		sessionStorage.removeItem(`/api/document-status`)
		sessionStorage.removeItem(`/api/charge-status`)
		sessionStorage.removeItem(`/api/treatment-status`)

		sessionStorage.removeItem(`/api/${tenantId}/facility`)
		sessionStorage.removeItem(`/api/${tenantId}/practice`)
		sessionStorage.removeItem(`/api/${tenantId}/physician`)
		sessionStorage.removeItem(`/api/${tenantId}/referring-physician`)
		sessionStorage.removeItem(`/api/${tenantId}/insurance`)
		sessionStorage.removeItem(`/api/${tenantId}/user`)

		window.location.reload()
	}

	return <Container>
		<Navbar variant="dark" bg="dark" className="fixed-top" expand="md">
			<Navbar.Brand>
				<Link id="nav-home" to="/">ChargeBook</Link>
			</Navbar.Brand>
			<Nav >
				<NavDropdown title="Show All" id="nav-showall-list">
					<NavDropdown.Item><Link id="nav-treatments-item" to="/">Treatments</Link></NavDropdown.Item>
					<NavDropdown.Item><Link id="nav-charges-item" to="/Charges">Charges</Link></NavDropdown.Item>
					<NavDropdown.Item><Link id="nav-documents-item" to="/Documents">Documents</Link></NavDropdown.Item>
					<NavDropdown.Item><Link id="nav-imports-item" to="/Imports">Imports</Link></NavDropdown.Item>
					<NavDropdown.Item><Link id="nav-preauths-item" to="/Preauths">Preauths</Link></NavDropdown.Item>
				</NavDropdown>

				<NavDropdown title="Admin" id="nav-admin-list">
					<NavDropdown.Item><Link id="nav-referringprovider-item" to="/Admin/ReferringPhysician">Referring Physicians</Link></NavDropdown.Item>
					<NavDropdown.Item><Link id="nav-dx-item" to="/Admin/DX">Diagnosis</Link></NavDropdown.Item>
					<NavDropdown.Item><Link id="nav-dx-item" to="/Admin/Practice">Practice</Link></NavDropdown.Item>
					<NavDropdown.Item><Link id="nav-dx-item" to="/Admin/Facility">Facility</Link></NavDropdown.Item>
				</NavDropdown>

				<NavItem>
					<NavLink id="nav-resetcache" onClick={resetCache}>Reset Cache</NavLink>
				</NavItem>
			</Nav>

			<Nav className="ml-auto">
				<NavItem>
					<NavLink id="nav-logout" onClick={logout}>Logout, {getAccessToken().payload()?.preferred_username}</NavLink>
				</NavItem>
			</Nav>
		</Navbar>
	</Container>
}

export default N