export const byType = (type: string, positive: boolean = true) => (item: any) => {
	return positive ? item.type === type : item.type !== type
}

export const byId = (id: number, positive: boolean = true) => (item: any) => {
	return item.id === id && positive
}

export const byIds = (ids: number[], positive: boolean = true) => (item: any) => {
	return ids.find(byId(item.id)) !== undefined && positive
}

export const byRelationshipId = (relationship: string, id: number, positive: boolean = true) => (item: any) => {
	for (let [, value] of Object.entries(item.relationships)) {
		if (!value) {
			continue
		}

		const data = (value as any).data

		if (data && data.type === relationship && data.id === id) {
			return positive
		}
	}

	return !positive
}

export const getRelationshipId = (relationship: string, defaultId: number = 0) => (item: any) => {
	for (let [, value] of Object.entries(item.relationships)) {
		if (!value) {
			continue
		}

		const data = (value as any).data

		if (data && data.type === relationship) {
			return data.id
		}
	}

	return defaultId
}