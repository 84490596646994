import Axios from "../common/axios"
import * as _ from "lodash"

export enum Type {
    WAITING = "FETCH_REFERRING_PHYSICIAN_WAITING"
    , COMPLETED = "FETCH_REFERRING_PHYSICIAN_COMPLETED"
    , ERROR = "FETCH_REFERRING_PHYSICIAN_ERROR"

    , SEARCH = "ReferringPhysicianAction.SEARCH"
}

export interface Action {
    type: Type
    payload: any
}

export const fetch = (tenantId: number) => async (dispatch: (payload: Action) => Action, getState: () => any) => {
    const state = getState().referringPhysician

    if (state.loading || state.fetched) {
        return
    }

    const uri = `/api/${tenantId}/referring-physician`
    const cache = sessionStorage.getItem(uri)

    if (cache) {
        return dispatch({ type: Type.COMPLETED, payload: { data: JSON.parse(cache) } })
    }

    dispatch({ type: Type.WAITING, payload: {} })

    try {
        const { data, request } = await Axios.get(uri)

        if (request.responseURL.endsWith("/login")) {
            throw new Error("redirected to /login")
        }

        sessionStorage.setItem(uri, JSON.stringify(data))
        dispatch({ type: Type.COMPLETED, payload: { data } })
    } catch (error) {
        dispatch({ type: Type.ERROR, payload: { error } })
    }
}

export const search = (search: string) => {
    return { type: Type.SEARCH, payload: { search } }
}

export interface ReferringPhysicianState {
    search: string

    loading: boolean
    fetched: boolean

    data: any[]
    included: any[]
}

const reducer = (prevState: ReferringPhysicianState, action: Action): ReferringPhysicianState => {
    const state = _.cloneDeep(prevState)

    switch (action.type) {
        case Type.WAITING:
            state.loading = true
            break
        case Type.COMPLETED:
            {
                state.loading = false
                state.fetched = true

                const { data, included } = action.payload.data

                state.data = [...data]

                if (included) {
                    state.included = [...included]
                }
            }
            break
        // case Type.SEARCH:
        //     state.search = action.data.search
        //     break
        // case NewReferringPhysician.Action.SAVE_COMPLETED:
        //     state.data.push(action.data.referringPhysician)
        //     break
    }

    return state
}

export default reducer
