export interface Payor {
    id: number
    name: string
    code: string
}

export const defaultPayor: Payor = {
    id: 0
    , name: ''
    , code: ''
}