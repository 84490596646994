export interface CptCategory {
    id: number
    name: string
}

export interface CptModifier {
    id: number
    code: string
}

export interface Cpt {
    id: number
    code: string
    description: string
    category: CptCategory
    mod1: CptModifier | null
    mod2: CptModifier | null
    mod3: CptModifier | null
    mod4: CptModifier | null
}

export interface ICptCategoryRepo {
    listCptCategories: () => Promise<CptCategory[]>
}

export interface ICptCategoryRepo {
    listCptModifiers: () => Promise<CptModifier[]>
}

export interface ICptRepo {
    listCpts: () => Promise<Cpt[]>
}

export const defaultCpt: Cpt = {
    id: 0
    , code: ''
    , description: ''
    , category: {
        id: 0
        , name: ''
    }
    , mod1: null
    , mod2: null
    , mod3: null
    , mod4: null
}