import Axios from "../common/axios";
import { AppState } from "../reducers"

enum Type {
    WAITING = "DocumentTypeAction.FETCH_WAITING"
    , COMPLETED = "DocumentTypeAction.FETCH_COMPLETED"
    , ERROR = "DocumentTypeAction.FETCH_ERROR"
}

interface Action {
    type: Type
    payload: any
}

export const fetch = () => async (dispatch: (action: Action) => Action, getState: () => AppState) => {
    const state = getState().documentTypeList

    if (state.loading || state.fetched) {
        return
    }

    const uri = "/api/document-type"
    const cache = sessionStorage.getItem(uri)

    if (cache) {
        return dispatch({ type: Type.COMPLETED, payload: { data: JSON.parse(cache) } })
    }

    dispatch({ type: Type.WAITING, payload: {} })

    try {
        const { data, status } = await Axios.get(uri)

        if (status !== 200) {
            throw new Error(`${status} ${uri}`)
        }

        sessionStorage.setItem(uri, JSON.stringify(data))

        dispatch({ type: Type.COMPLETED, payload: { data } })
    } catch (error) {
        dispatch({ type: Type.ERROR, payload: { error } })
    }
}

export interface DocumentTypeState {
    loading: boolean
    fetched: boolean

    data: any[]
}

const reducer = (prevState: DocumentTypeState, action: Action): DocumentTypeState => {
    const state = { ...prevState }

    switch (action.type) {
        case Type.WAITING:
            state.loading = true
            break
        case Type.COMPLETED:
            state.loading = false
            state.fetched = true

            const { data } = action.payload.data

            state.data = [...data]
            break
    }

    return state
}

export default reducer