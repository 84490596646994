import React, { useContext, ChangeEvent } from "react"
import { useSelector } from "react-redux"
import { Row, Col, Form } from "react-bootstrap"
import Select, { createFilter } from "react-select"
import { cloneDeep } from "lodash"
import { byId } from "../../../common/jsonapi-filters"
import { AppState } from "../../../reducers";
import ChargeContext from "../../../contexts/ChargeContext"
import moment from "moment"

const SelectCpt = (props: any) => {
	const [charge, setCharge] = useContext(ChargeContext)
	const cpts = useSelector((state: AppState) => state.cpt.data)
	const insurances = useSelector((state: AppState) => state.insurance.data)

	const { admissions } = props

	const setCpt = (cpt: any) => {
		const next = cloneDeep(charge)
		next.relationships.cpt.data.id = cpt.id
		setCharge(next)
	}

	const setUnits = (e: ChangeEvent<HTMLInputElement>) => {
		setCharge({ ...charge, ...{ attributes: { ...charge.attributes, units: Math.abs(e.currentTarget.valueAsNumber) } } })
	}

	return <Row>
		<Col md={4}>
			<Form.Group>
				<Form.Label>CPT</Form.Label>
				<Select
					tabIndex={"2"}

					filterOption={createFilter({ matchFrom: 'start' })}

					value={cpts.find(byId(charge.relationships.cpt.data.id)) || null}

					onChange={setCpt}

					options={cpts.filter((cpt: any) => {
						if (cpt.relationships.category.data.id !== 5) {
							return true
						}

						const insurance = insurances.find((insurance: any) => insurance.id === charge.relationships.insurance.data.id)

						if (!insurance || !insurance.relationships.igrt.data.length) {
							return true
						}

						return insurance.relationships.igrt.data.map((igrt: any) => igrt.id).indexOf(cpt.id) !== -1
					}).filter((cpt: any) => {
						let isInpatient = false

						if (charge.id) {
							isInpatient = dateInAnyAdmission(charge.attributes.dos, admissions.map((admission: any) => admission.attributes))
						} else {
							const dates = charge.dos.map((dos: any) => dos.date)
							isInpatient = allDatesInAnyAdmission(dates, admissions.map((admission: any) => admission.attributes))
						}

						return filterUsingServicePlace(cpt, isInpatient)
					})}

					getOptionLabel={(option: any) => {
						let label = option.attributes.code

						if (option.attributes.mod1 && option.attributes.mod1.length) {
							label += ("-" + option.attributes.mod1)
						}

						label += ` (${option.attributes.abbr})`

						return label
					}}

					getOptionValue={(option: any) => option.id}
				/>
			</Form.Group>
		</Col>
		<Col md={2}>
			<Form.Group>
				<Form.Label>Units</Form.Label>
				<Form.Control value={charge.attributes.units} onChange={setUnits} tabIndex={3} type="number" />
			</Form.Group>
		</Col>
	</Row>
}

export default SelectCpt

export interface Admission {
	admit: number;
	discharge: number;
}

export const dateInAnyAdmission = (date: string, admissions: Admission[]) => {
	for (let admission of admissions) {
		if (admission.admit && moment(date, "MM/DD/YYYY") >= moment(admission.admit, "MM/DD/YYYY")) {
			if (admission.discharge) {
				if (moment(date, "MM/DD/YYYY") <= moment(admission.discharge, "MM/DD/YYYY")) {
					return true
				}
			} else {
				return true
			}
		}
	}

	return false
}

export const allDatesInAnyAdmission = (dates: number[], admissions: Admission[]): boolean => {
	for (let admission of admissions) {
		if (admission.admit) {
			let allDatesGreaterThanAdmit = true

			for (let date of dates) {
				if (!(moment(date, "MM/DD/YYYY") >= moment(admission.admit, "MM/DD/YYYY"))) {
					allDatesGreaterThanAdmit = false; break
				}
			}

			if (allDatesGreaterThanAdmit) {
				if (admission.discharge) {
					let allDatesLessThanDischarge = true

					for (let date of dates) {
						if (!(moment(date, "MM/DD/YYYY") <= moment(admission.discharge, "MM/DD/YYYY"))) {
							allDatesLessThanDischarge = false; break;
						}
					}

					if (allDatesLessThanDischarge) {
						return true
					}
				} else {
					return true
				}
			}
		}
	}

	return false
}

export const filterUsingServicePlace = (cpt: any, occurredDuringAdmission: boolean) => {
	if (!cpt.relationships.serviceplace.data.length) {
		return true
	} else {
		const servicePlaceIds = cpt.relationships.serviceplace.data.map((servicePlace: any) => servicePlace.id)

		if (occurredDuringAdmission) {
			return servicePlaceIds.indexOf(21) !== -1
		} else {
			return servicePlaceIds.indexOf(22) !== -1 || servicePlaceIds.indexOf(11) !== -1
		}
	}
}