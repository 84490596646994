import { useState } from "react"

export const useParams = (defaultParams: any) => {
    const [params, setStateParams] = useState({ ...defaultParams, ...fromHash(window.location.hash) })

    const setParamsAndHash = (next: any) => {
        setStateParams({ ...params, ...next })
        window.location.hash = toHash({ ...params, ...next })
    }

    const setParam = (key: string, value: string | number) => {
        const next: any = { ...params }
        next[key] = value

        setParamsAndHash(next)
    }

    return {
        params
        , setParam
        , setParams: setParamsAndHash
    }
}

const fromHash = (hash: string) => {
    let pairs: string[][] = []

    if (hash.length) {
        pairs = hash.substring(1).split("&").map(pair => pair.split("="))
    }

    const obj: any = {}

    for (let i = 0; i < pairs.length; i++) {
        const [key, value] = pairs[i]
        obj[key] = decodeURIComponent(value)
    }

    return obj
}

const toHash = (obj: any) => {
    const pairs = []

    for (const key in obj) {
        pairs.push(`${key}=${obj[key]}`)
    }

    return pairs.join("&")
}