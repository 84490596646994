import { ChargeActions } from "./ChargeActions"
import { PhaseActions } from "./PhaseActions"
import { ChargeModalActions } from "./modal/ChargeModalActions"
import { ChargeMultiEditModalActions } from "./ChargeMultiEditModalActions"
import { ChargeMultiEditActions } from "./ChargeMultiEditActions"
import { DocumentActions } from "./DocumentActions"
import { DocumentEditModalActions } from "./DocumentEditModalActions"
import { DocumentSetStatusModalActions } from "./modal/DocumentSetStatusModalActions"
import { ChargeSetStatusModalActions } from "./modal/ChargeSetStatusModalActions"
export interface AppActions {
    charge: ChargeActions
    chargeMultiEdit: ChargeMultiEditActions
    phase: PhaseActions
    chargeModal: ChargeModalActions
    chargeMultiEditModal: ChargeMultiEditModalActions
    document: DocumentActions
    documentEditModal: DocumentEditModalActions
    documentSetStatusModal: DocumentSetStatusModalActions
    chargeSetStatusModal: ChargeSetStatusModalActions
}

const actions = (): AppActions => {
    return {
        charge: new ChargeActions()
        , chargeMultiEdit: new ChargeMultiEditActions()
        , phase: new PhaseActions()
        , chargeModal: new ChargeModalActions()
        , chargeMultiEditModal: new ChargeMultiEditModalActions()
        , document: new DocumentActions()
        , documentEditModal: new DocumentEditModalActions()
        , documentSetStatusModal: new DocumentSetStatusModalActions()
        , chargeSetStatusModal: new ChargeSetStatusModalActions()
    }
}

export default actions