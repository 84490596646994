import { DocumentSetStatusModalPayload, DocumentSetStatusModalAction } from "../../actions/modal/DocumentSetStatusModalActions"

export interface DocumentSetStatusModalState {
    show: boolean
    documentId: number
}

const documentSetStatusModalReducer = (state: DocumentSetStatusModalState, payload: DocumentSetStatusModalPayload): DocumentSetStatusModalState => {
    const nextState = { ...state };

    switch (payload.type) {
        case DocumentSetStatusModalAction.OPENED:
            nextState.show = true;
            nextState.documentId = payload.documentId ? payload.documentId : 0;
            break;
        case DocumentSetStatusModalAction.CLOSED:
            nextState.show = false;
            break;
    }

    return nextState;
};

export default documentSetStatusModalReducer;