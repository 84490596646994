import Axios from "../../common/axios"
import { ChargeActions } from "../ChargeActions"

export enum ChargeModalAction {
    OPENED = "ChargeModalAction.OPENED"
    , CLOSED = "ChargeModalAction.CLOSED"

    , DUPLICATED = "ChargeModalAction.DUPLICATED"

    , SAVE_WAITING = "ChargeModalAction.SAVE_WAITING"
    , SAVE_COMPLETED = "ChargeModalAction.SAVE_COMPLETED"
    , SAVE_ERROR = "ChargeModalAction.SAVE_ERROR"

    , VALIDATION_WAITING = "ChargeModalAction.VALIDATION_WAITING"
    , VALIDATION_COMPLETE = "ChargeModalAction.VALIDATION_COMPLETE"
    , VALIDATION_ERROR = "ChargeModalAction.VALIDATION_ERROR"

    , VALIDATION_ERROR_MESSAGE = "ChargeModalAction.VALIDATION_ERROR_MESSAGE"

    , SET_IS_PLACEHOLDER = "ChargeModalAction.SET_IS_PLACEHOLDER"
    , SET_STATUS = "ChargeModalAction.SET_STATUS"
    , SET_PHASE = "SChargeModalAction.ET_PHASE"
    , SET_CPT = "ChargeModalAction.SET_CPT"
    , SET_MOD1 = "ChargeModalAction.SET_MOD1"
    , SET_MOD2 = "ChargeModalAction.SET_MOD2"
    , SET_MOD3 = "ChargeModalAction.SET_MOD3"
    , SET_MOD4 = "ChargeModalAction.SET_MOD4"
    , SET_INSURANCE = "ChargeModalAction.SET_INSURANCE"
    , SET_PHYSICIAN = "ChargeModalAction.SET_PHYSICIAN"
    , SET_FACILITY = "ChargeModalAction.SET_FACILITY"
    , SET_DOCUMENT = "ChargeModalAction.SET_DOCUMENT"
    , SET_PRACTICE = "ChargeModalAction.SET_PRACTICE"
    , SET_ORDINAL = "ChargeModalAction.SET_ORDINAL"
    , SET_MIPS_MEASURE = "ChargeModalAction.SET_MIPS_MEASURE"
    , SET_MIPS_NUMERATOR = "ChargeModalAction.SET_MIPS_NUMERATOR"

    , SET_UNITS = "ChargeModalAction.SET_UNITS"
    , SET_NOTE = "ChargeModalAction.SET_NOTE"
    , SET_DOS = "ChargeModalAction.SET_DOS"
    , SET_DIAGNOSIS = "ChargeModalAction.SET_DIAGNOSIS"


    , ADDED_DOS = "ChargeModalAction.ADDED_DOS"
    , ADDED_DIAGNOSIS = "ChargeModalAction.ADDED_DIAGNOSIS"
    , ADDED_MIPS_REPORT = "ChargeModalAction.ADDED_MIPS_REPORT"

    , REMOVED_DIAGNOSIS = "ChargeModalAction.REMOVED_DIAGNOSIS"
}

export interface ChargeModalPayload {
    type: ChargeModalAction
    data: any
}

export class ChargeModalActions {
    open(charge?: any, treatment?: any, document?: any): ChargeModalPayload {
        return { type: ChargeModalAction.OPENED, data: { charge, treatment, document } }
    }

    duplicate() {
        return { type: ChargeModalAction.DUPLICATED, data: {} }
    }

    save(charge: any) {
        return async (dispatch: (payload: any) => any, getState: () => any) => {
            dispatch({ type: ChargeModalAction.SAVE_WAITING, data: {} })

            let validationError = false

            const validation: any = {
                phase: null
                , dos: null
                , code: null
                , units: null
                , physician: null
                , insurance: null
                , facility: null
                , diagnosis: null
            }

            if (!charge.relationships.phase.data.id) {
                validationError = true
                validation.phase = "error"
            }

            if (!charge.relationships.cpt.data.id) {
                validationError = true
                validation.code = "error"
            }

            if (!charge.attributes.units) {
                validationError = true
                validation.units = "error"
            }

            let isPlaceholder = false

            console.log("state.cpt", getState().chargeModal.cpt)

            if (getState().chargeModal.cpt && getState().chargeModal.cpt.attributes.isPlaceholder) {
                isPlaceholder = true
            }

            if (!isPlaceholder && (!charge.relationships.physician.data.id || charge.relationships.physician.data.id * 1 === 1)) {
                validationError = true
                validation.physician = "error"
            }

            if (!isPlaceholder && (!charge.relationships.insurance.data.id || charge.relationships.insurance.data.id * 1 === 1)) {
                validationError = true
                validation.insurance = "error"
            }

            if (!isPlaceholder && (!charge.relationships.facility.data.id || charge.relationships.facility.data.id * 1 === 1)) {
                validationError = true
                validation.facility = "error"
            }

            if (!isPlaceholder && !charge.relationships.diagnosis.data.length) {
                validationError = true
                validation.diagnosis = "error"
            }

            if (validationError) {
                console.log("local validation error", ["chargemodal"])
                dispatch({ type: ChargeModalAction.VALIDATION_ERROR, data: { validation } })
            } else {
                const treatmentId = getState().treatment.data[0].id

                try {
                    if (!isPlaceholder) {
                        const validationResult = await Axios.post('/api/validate/' + treatmentId, JSON.stringify({ data: charge }))

                        if (validationResult.data.status !== 'OK') {
                            console.log("remote validation error: " + validationResult.data.error, ["chargemodal"])
                            dispatch({ type: ChargeModalAction.VALIDATION_ERROR_MESSAGE, data: { validationErrorMessage: validationResult.data.error } })


                            return
                        }
                    }

                    const chargeActions = new ChargeActions()

                    const dispatchOnComplete: any[] = [
                        { type: ChargeModalAction.SAVE_COMPLETED, data: {} }
                        , { type: ChargeModalAction.CLOSED, data: {} }
                    ]

                    const dispatchOnError: any[] = [
                        { type: ChargeModalAction.SAVE_ERROR, data: {} }
                    ]

                    if (!charge.id) {
                        console.log("created charge", ["chargemodal"])
                        dispatch(chargeActions.add(charge, dispatchOnComplete, dispatchOnError))
                    } else {
                        console.log("updated charge", ["chargemodal"])
                        dispatch(chargeActions.update(charge, dispatchOnComplete, dispatchOnError))
                    }
                }
                catch (error) {
                    dispatch({ type: ChargeModalAction.VALIDATION_ERROR_MESSAGE, data: { validationErrorMessage: error } })
                }
            }
        }
    }

    close(): ChargeModalPayload {
        return { type: ChargeModalAction.CLOSED, data: {} }
    }

    setIsPlaceholder(isPlaceholder: boolean): ChargeModalPayload {
        return { type: ChargeModalAction.SET_IS_PLACEHOLDER, data: { isPlaceholder } }
    }

    setStatus(statusId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_STATUS, data: { statusId } }
    }

    setPhase(phaseId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_PHASE, data: { phaseId } }
    }

    setCpt(cpt: any): ChargeModalPayload {
        return { type: ChargeModalAction.SET_CPT, data: { cpt } }
    }

    setMod1(modId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_MOD1, data: { modId } }
    }

    setMod2(modId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_MOD2, data: { modId } }
    }

    setMod3(modId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_MOD3, data: { modId } }
    }

    setMod4(modId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_MOD4, data: { modId } }
    }

    setInsurance(insuranceId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_INSURANCE, data: { insuranceId } }
    }

    setPhysician(physicianId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_PHYSICIAN, data: { physicianId } }
    }

    setFacility(facilityId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_FACILITY, data: { facilityId } }
    }

    setDocument(documentId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_DOCUMENT, data: { documentId } }
    }

    setPractice(practiceId: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_PRACTICE, data: { practiceId } }
    }

    setOrdinal(ordinal: number, index: number = 0): ChargeModalPayload {
        return { type: ChargeModalAction.SET_ORDINAL, data: { ordinal, index } }
    }

    setDiagnosis(icd10Id: string, index: number = 0): ChargeModalPayload {
        return { type: ChargeModalAction.SET_DIAGNOSIS, data: { icd10Id, index } }
    }

    setUnits(units: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_UNITS, data: { units } }
    }

    setNote(note: string): ChargeModalPayload {
        return { type: ChargeModalAction.SET_NOTE, data: { note } }
    }

    setDos(date: string, index: number = 0): ChargeModalPayload {
        return { type: ChargeModalAction.SET_DOS, data: { date, index } }
    }

    setMipsMeasure(measureId: number, index: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_MIPS_MEASURE, data: { measureId, index } }
    }

    setMipsNumerator(numeratorId: number, index: number): ChargeModalPayload {
        return { type: ChargeModalAction.SET_MIPS_NUMERATOR, data: { numeratorId, index } }
    }

    addDos(): ChargeModalPayload {
        return { type: ChargeModalAction.ADDED_DOS, data: {} }
    }

    addDiagnosis(): ChargeModalPayload {
        return { type: ChargeModalAction.ADDED_DIAGNOSIS, data: {} }
    }

    addMipsReport(): ChargeModalPayload {
        return { type: ChargeModalAction.ADDED_MIPS_REPORT, data: {} }
    }

    removeDiagnosis(index: number): ChargeModalPayload {
        return { type: ChargeModalAction.REMOVED_DIAGNOSIS, data: { index } }
    }
}

export function open(charge?: any, treatment?: any, document?: any): ChargeModalPayload {
    return { type: ChargeModalAction.OPENED, data: { charge, treatment, document } }
}

export function duplicate() {
    return { type: ChargeModalAction.DUPLICATED, data: {} }
}

export function save(charge: any) {
    return async (dispatch: (payload: any) => any, getState: () => any) => {
        dispatch({ type: ChargeModalAction.SAVE_WAITING, data: {} })

        let validationError = false

        const validation: any = {
            phase: null
            , dos: null
            , code: null
            , units: null
            , physician: null
            , insurance: null
            , facility: null
            , diagnosis: null
        }

        if (!charge.relationships.phase.data.id) {
            validationError = true
            validation.phase = "error"
        }

        if (!charge.relationships.cpt.data.id) {
            validationError = true
            validation.code = "error"
        }

        if (!charge.attributes.units) {
            validationError = true
            validation.units = "error"
        }

        let isPlaceholder = false

        console.log("state.cpt", getState().chargeModal.cpt)

        if (getState().chargeModal.cpt && getState().chargeModal.cpt.attributes.isPlaceholder) {
            isPlaceholder = true
        }

        if (!isPlaceholder && (!charge.relationships.physician.data.id || charge.relationships.physician.data.id * 1 === 1)) {
            validationError = true
            validation.physician = "error"
        }

        if (!isPlaceholder && (!charge.relationships.insurance.data.id || charge.relationships.insurance.data.id * 1 === 1)) {
            validationError = true
            validation.insurance = "error"
        }

        if (!isPlaceholder && (!charge.relationships.facility.data.id || charge.relationships.facility.data.id * 1 === 1)) {
            validationError = true
            validation.facility = "error"
        }

        if (!isPlaceholder && !charge.relationships.diagnosis.data.length) {
            validationError = true
            validation.diagnosis = "error"
        }

        if (validationError) {
            console.log("local validation error", ["chargemodal"])
            dispatch({ type: ChargeModalAction.VALIDATION_ERROR, data: { validation } })
        } else {
            const treatmentId = getState().treatment.data[0].id

            try {
                if (!isPlaceholder) {
                    const validationResult = await Axios.post('/api/validate/' + treatmentId, JSON.stringify({ data: charge }))

                    if (validationResult.data.status !== 'OK') {
                        console.log("remote validation error: " + validationResult.data.error, ["chargemodal"])
                        dispatch({ type: ChargeModalAction.VALIDATION_ERROR_MESSAGE, data: { validationErrorMessage: validationResult.data.error } })


                        return
                    }
                }

                const chargeActions = new ChargeActions()

                const dispatchOnComplete: any[] = [
                    { type: ChargeModalAction.SAVE_COMPLETED, data: {} }
                    , { type: ChargeModalAction.CLOSED, data: {} }
                ]

                const dispatchOnError: any[] = [
                    { type: ChargeModalAction.SAVE_ERROR, data: {} }
                ]

                if (!charge.id) {
                    console.log("created charge", ["chargemodal"])
                    dispatch(chargeActions.add(charge, dispatchOnComplete, dispatchOnError))
                } else {
                    console.log("updated charge", ["chargemodal"])
                    dispatch(chargeActions.update(charge, dispatchOnComplete, dispatchOnError))
                }
            }
            catch (error) {
                dispatch({ type: ChargeModalAction.VALIDATION_ERROR_MESSAGE, data: { validationErrorMessage: error } })
            }
        }
    }
}

export function close(): ChargeModalPayload {
    return { type: ChargeModalAction.CLOSED, data: {} }
}

export function setIsPlaceholder(isPlaceholder: boolean): ChargeModalPayload {
    return { type: ChargeModalAction.SET_IS_PLACEHOLDER, data: { isPlaceholder } }
}

export function setStatus(statusId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_STATUS, data: { statusId } }
}

export function setPhase(phaseId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_PHASE, data: { phaseId } }
}

export function setCpt(cpt: any): ChargeModalPayload {
    return { type: ChargeModalAction.SET_CPT, data: { cpt } }
}

export function setMod1(modId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_MOD1, data: { modId } }
}

export function setMod2(modId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_MOD2, data: { modId } }
}

export function setMod3(modId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_MOD3, data: { modId } }
}

export function setMod4(modId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_MOD4, data: { modId } }
}

export function setInsurance(insuranceId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_INSURANCE, data: { insuranceId } }
}

export function setPhysician(physicianId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_PHYSICIAN, data: { physicianId } }
}

export function setFacility(facilityId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_FACILITY, data: { facilityId } }
}

export function setDocument(documentId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_DOCUMENT, data: { documentId } }
}

export function setPractice(practiceId: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_PRACTICE, data: { practiceId } }
}

export function setOrdinal(ordinal: number, index: number = 0): ChargeModalPayload {
    return { type: ChargeModalAction.SET_ORDINAL, data: { ordinal, index } }
}

export function setDiagnosis(icd10Id: string, index: number = 0): ChargeModalPayload {
    return { type: ChargeModalAction.SET_DIAGNOSIS, data: { icd10Id, index } }
}

export function setUnits(units: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_UNITS, data: { units } }
}

export function setNote(note: string): ChargeModalPayload {
    return { type: ChargeModalAction.SET_NOTE, data: { note } }
}

export function setDos(date: string, index: number = 0): ChargeModalPayload {
    return { type: ChargeModalAction.SET_DOS, data: { date, index } }
}

export function setMipsMeasure(measureId: number, index: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_MIPS_MEASURE, data: { measureId, index } }
}

export function setMipsNumerator(numeratorId: number, index: number): ChargeModalPayload {
    return { type: ChargeModalAction.SET_MIPS_NUMERATOR, data: { numeratorId, index } }
}

export function addDos(): ChargeModalPayload {
    return { type: ChargeModalAction.ADDED_DOS, data: {} }
}

export function addDiagnosis(): ChargeModalPayload {
    return { type: ChargeModalAction.ADDED_DIAGNOSIS, data: {} }
}

export function addMipsReport(): ChargeModalPayload {
    return { type: ChargeModalAction.ADDED_MIPS_REPORT, data: {} }
}

export function removeDiagnosis(index: number): ChargeModalPayload {
    return { type: ChargeModalAction.REMOVED_DIAGNOSIS, data: { index } }
}