import { DocumentPayload, DocumentAction } from "../actions/DocumentActions"
import { ListState } from "./ListState"

export interface State extends ListState {
    filter: any
    selectedId: number

    pagination: any
}

const reducer = (state: State, payload: DocumentPayload): State => {
    const nextState = { ...state }

    switch (payload.type) {
        case DocumentAction.FETCH_WAITING:
            nextState.loading = true
            nextState.fetched = false
            nextState.filter = { ...payload.filter }
            nextState.selectedId = 0
            break
        case DocumentAction.FETCH_COMPLETED:
            nextState.loading = false
            nextState.fetched = true
            nextState.selectedId = 0
            nextState.data = [...payload.response.data.data]
            nextState.included = [...payload.response.data.included]
            nextState.pagination = { ...payload.response.data.pagination }
            break

        case DocumentAction.FETCH_MORE_COMPLETED:
            nextState.loading = false

            nextState.data = nextState.data.concat([...payload.data.response.data])
            nextState.included = nextState.included.concat([...payload.data.response.included])
            nextState.pagination = { ...payload.data.response.pagination }
            break

        case DocumentAction.SET_STATUS_COMPLETED:
            for (const document of nextState.data) {
                if (payload.data.documentIds.indexOf(document.id) !== -1) {
                    // console.log("Updating documentId:", payload.response.documentId, "statusId: ", payload.response.statusId)
                    document.relationships.status.data.id = payload.data.statusId
                }
            }
            break
        case DocumentAction.UPDATE_COMPLETED:
            const updatedData = [...payload.response.data.data]

            for (let d of updatedData) {
                const index = nextState.data.findIndex((dd: any) => dd.id === d.id && dd.type === d.type)
    
                if (index === -1) {
                    nextState.data.push(d)
                } else {
                    nextState.data.splice(index, 1, d)
                }
            }

            const updatedIncluded = [...payload.response.data.included]

            for (let i of updatedIncluded) {
                const index = nextState.included.findIndex((ii: any) => ii.id === i.id && ii.type === i.type)
    
                if (index === -1) {
                    nextState.included.push(i)
                } else {
                    nextState.included.splice(index, 1, i)
                }
            }
            break
        case DocumentAction.SELECT:
            nextState.selectedId = payload.response.selectedId
            break
    }

    return nextState
}

export default reducer