import React, { useState, MouseEvent } from "react"
import { Row, Col, FormGroup, FormLabel, FormControl, Table, Button } from "react-bootstrap"

import NewTreatmentModal from "./components/modal/NewTreatmentModal"
import Navbar from "./components/Navbar"

import { Treatment, TreatmentRepo, useTreatmentList } from "./core/entities/treatment"

import { dispatchEvent } from "./hooks/useEvent";

import FacilityDropdown from "./components/FacilityDropdown"
import UserDropdown from "./components/UserDropdown"
import TreatmentStatusDropdown from "./components/TreatmentStatusDropdown"

import moment from "moment"

const repo = new TreatmentRepo()

const TreatmentList = () => {
    const { treatments, filter, setFilter, fetchMore, isFetching, isFetchingMore } = useTreatmentList()

    const handleSearch = (e: any) => {
        (window as any).searchQuery = e.target.value;

        clearTimeout((window as any).searchTimeout);

        (window as any).searchTimeout = setTimeout(() => {
            setFilter({ ...filter, search: (window as any).searchQuery })
        }, 500);
    }

    return <>
        <Navbar />
        <div className="filter-bar">
            <Row>
                <Col md={9}>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>Search</FormLabel>
                                <FormControl
                                    id="search"
                                    defaultValue={filter.search}
                                    type="text"
                                    onChange={handleSearch} />
                            </FormGroup>
                        </Col>

                        <Col md={3} >
                            <FormGroup>
                                <FormLabel>Facility</FormLabel>
                                <FacilityDropdown id="facility-dropdown" value={filter.facilityId} onChange={(e) => { setFilter({ ...filter, facilityId: e.target.value }) }} emptyOption="All" />
                            </FormGroup>
                        </Col>

                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>Assignee</FormLabel>
                                <UserDropdown id="assignee-dropdown" value={filter.assigneeId} onChange={(e) => { setFilter({ ...filter, assigneeId: e.target.value }) }} emptyOption="All" />
                            </FormGroup>
                        </Col>

                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>Status</FormLabel>
                                <TreatmentStatusDropdown id="status-dropdown" value={filter.statusId} onChange={(e) => { setFilter({ ...filter, statusId: e.target.value }) }} emptyOption="All" />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>

                <Col md={3} style={{ marginTop: "17px" }}>
                    <Button id="add-treatment" style={{ float: "right", marginTop: "10px", marginRight: "10px" }} onClick={() => {
                        dispatchEvent("openNewTreatmentModal", {})
                    }}>Add</Button>
                </Col>

                <div style={{ clear: "both" }}></div>
            </Row>

            <NewTreatmentModal />
        </div>

        <Table style={{ marginTop: "calc(11em - 2px)" }}>
            <thead>
                <tr>
                    <th>Patient</th>
                    <th>MRN</th>
                    <th>DOS Range</th>
                    <th>Modality</th>
                    <th>Facility</th>
                    <th>Assignee</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    isFetching
                        ? <tr>
                            <td colSpan={7}>Loading...</td>
                        </tr>
                        : !treatments || !treatments.length
                            ? <tr>
                                <td colSpan={7}>No results</td>
                            </tr>
                            : treatments.map((treatment: Treatment) => {
                                return <TreatmentRow key={treatment.id} treatment={treatment} />
                            })
                }

            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={8} className="text-center">
                        <Button onClick={fetchMore} disabled={isFetchingMore}>Fetch More</Button>
                    </td>
                </tr>
            </tfoot>
        </Table>
    </>
}

interface TreatmentRowProps {
    treatment: Treatment
}

const mdY = (str: string | undefined): string => {
    if (str) {
        return moment(str).format("MM/DD/YYYY")
    }

    return ""
}

const TreatmentRow = (props: TreatmentRowProps) => {
    const { treatment } = props

    const handleClick = (treatment: Treatment) => (event: MouseEvent<HTMLSpanElement>) => {
        if (event.altKey) {
            dispatchEvent("openNewTreatmentModal", { treatment })
            return
        }

        window.location.href = `/tx/${treatment.id}`
    }

    return <>
        <tr>
            <td>
                <div>
                    <b>
                        <span className="a text-primary" style={{ cursor: "pointer" }} onClick={handleClick(treatment)}>{treatment.patient.lastName}, {treatment.patient.firstName}</span>
                    </b>
                </div>
                <div>{mdY(treatment.patient.dob.substr(0, 10))}</div>
            </td>
            <td>{treatment.patient.mrn}</td>
            <td>{mdY(treatment.startDate?.substr(0, 10))} - {mdY(treatment.endDate?.substr(0, 10))}</td>
            <td>{treatment.modality.name}</td>
            <td>
                <div><b>{treatment.facility.name}</b></div>
                <div>{treatment.facility.practice.name}</div>
            </td>
            <td>{treatment.assignee.name}</td>
            <td>{treatment.status.name}</td>
        </tr>
        {
            treatment.note
                ? <tr>
                    <td colSpan={7}>
                        {treatment.note}
                    </td>
                </tr>
                : null
        }
    </>
}

export default TreatmentList