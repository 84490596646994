import React, { useContext, ChangeEvent } from "react"
import { Form } from "react-bootstrap"
import ChargeContext from "../../../contexts/ChargeContext"

const Note = () => {
	const [charge, setCharge] = useContext(ChargeContext)

	const setNote = (e: ChangeEvent<HTMLInputElement>) => {
		setCharge({ ...charge, ...{ attributes: { ...charge.attributes, note: e.currentTarget.value } } })
	}

	return <Form.Group>
		<Form.Label>Note</Form.Label>
		<Form.Control as="textarea" tabIndex={7} value={charge.attributes.note} onChange={setNote} />
	</Form.Group>
}

export default Note