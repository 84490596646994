import Axios from "../../common/axios"
import { AppState } from "../../reducers"
import * as jsonapi from "../../common/jsonapi"

export enum Action {
    OPENED = "NewReferringPhysician.Action.OPENED"
    , CLOSED = "NewReferringPhysician.Action.CLOSED"

    , SET_NAME = "NewReferringPhysician.Action.SET_NAME"
    , SET_EXTERNAL_ID = "NewReferringPhysician.Action.SET_EXTERNAL_ID"

    , SAVE_WAITING = "NewReferringPhysician.Action.SAVE_WAITING"
    , SAVE_ERROR = "NewReferringPhysician.Action.SAVE_ERROR"
    , SAVE_COMPLETED = "NewReferringPhysician.Action.SAVE_COMPLETED"
}

export interface Payload {
    type: Action
    data?: any
}

export const open = (): Payload => {
    return { type: Action.OPENED }
}

export const close = (): Payload => {
    return { type: Action.CLOSED }
}

export const save = (tenantId: number) => {
    return async (dispatch: (action: any) => void, getState: () => AppState) => {
        dispatch({ type: Action.SAVE_WAITING })

        try {
            const attributes = getState().newReferringPhysicianModal.referringPhysician

            const operations: jsonapi.Op[] = [
                {
                    op: "add"
                    , ref: {
                        lid: 1
                        , type: "ReferringPhysician"
                    }
                    , data: {
                        type: "ReferringPhysician"
                        , lid: 1
                        , attributes
                        , relationships: {}
                    }
                }
            ]

            const data: jsonapi.OperationsRequest = { operations }

            const res = await Axios({ method: "post", url: `/api/${tenantId}/referring-physician/operations`, data })
            const referringPhysician = res.data.operations[0].data

            const uri = "/api/referring-physician"
            const cache = sessionStorage.getItem(uri)

            if (cache) {
                const parsed = JSON.parse(cache)
                parsed.data.push(referringPhysician)

                sessionStorage.setItem(uri, JSON.stringify(parsed))
            }

            dispatch({ type: Action.SAVE_COMPLETED, data: { referringPhysician } })
            dispatch(close())
        } catch (error) {
            console.log("could not save referring physician: ", error)
            dispatch({ type: Action.SAVE_ERROR, data: { error } })
        }
    }
}

export const setName = (name: string): Payload => {
    return { type: Action.SET_NAME, data: { name } }
}

export const setExternalId = (externalId: string): Payload => {
    return { type: Action.SET_EXTERNAL_ID, data: { externalId } }
}