import React, { FormEvent, useContext } from "react"
import { Row, Col, Form, ButtonGroup, Button, FormText } from "react-bootstrap"
import { cloneDeep } from "lodash"
import moment from "moment"
import DateInput from "../../DateInput"
import ChargeContext from "../../../contexts/ChargeContext"

interface DosProps {
	multiple: boolean
}

const Dos = (props: DosProps) => {
	const [charge, setCharge] = useContext(ChargeContext)

	const setDos = (date: string, index: number) => {
		const c = cloneDeep(charge)

		if (props.multiple) {
			c.dos[index].date = date
		} else {
			c.attributes.dos = date
		}

		setCharge(c)
	}

	const setOrdinal = (ordinal: number, index: number) => {
		const c = cloneDeep(charge)

		if (props.multiple) {
			c.dos[index].ordinal = ordinal
		} else {
			c.attributes.ordinal = ordinal
		}

		setCharge(c)
	}

	const toggleOTV = (index: number) => {
		const c = cloneDeep(charge)

		if (props.multiple) {
			c.dos[index].otv = !c.dos[index].otv
		} else {
			c.attributes.otv = !c.attributes.otv
		}

		setCharge(c)
	}

	const addDos = () => {
		const c = cloneDeep(charge)
		c.dos.push({ date: moment(c.dos[c.dos.length - 1].date, "L").add(1, 'd').format("L"), ordinal: 1 })
		setCharge(c)
	}

	const removeDos = () => {
		const c = cloneDeep(charge)
		c.dos.splice(c.dos.length - 1, 1)
		setCharge(c)
	}

	return <>
		<Row>
			<Col md={7}><Form.Label>Service Date</Form.Label></Col>
			<Col md={3}><Form.Label>Ordinal</Form.Label></Col>
			<Col><Form.Label>OTV</Form.Label></Col>
		</Row>
		{
			props.multiple
				? charge.dos
					? charge.dos.map((dos: any, index: number) => {
						return <Row key={index}>
							<Col md={7}>
								<Form.Group >
									<DateInput startDate={dos.date} onChangeDate={(date: string) => setDos(date, index)} tabIndex={1} />
									{
										dos.date && Math.abs(moment(dos.date).diff(moment.now(), 'days')) > 365
											? <FormText className="warning" style={{ "color": "red" }}>Are you sure about the date?</FormText>
											: null
									}
								</Form.Group>
							</Col>
							<Col md={3}>
								<Form.Group>
									<Form.Control as="select" value={dos.ordinal} onChange={(e: FormEvent<HTMLInputElement>) => setOrdinal(Number(e.currentTarget.value), index)}>
										{
											[1, 2, 3].map((ordinal: number) => <option key={ordinal} value={ordinal}>{ordinal}</option>)
										}
									</Form.Control>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Control style={{marginTop: ".75em"}} type="checkbox" checked={dos.otv} onChange={() => toggleOTV(index)} />
								</Form.Group>
							</Col>
						</Row>

					})
					: null
				: <Row>
					<Col md={7}>
						<Form.Group >
							<DateInput startDate={charge.attributes.dos} onChangeDate={(date: string) => setDos(date, 0)} tabIndex={1} />
						</Form.Group>
					</Col>
					<Col md={3}>
						<Form.Group>
							<Form.Control as="select" value={charge.attributes.ordinal} onChange={(e: FormEvent<HTMLInputElement>) => setOrdinal(Number(e.currentTarget.value), 0)}>
								{
									[1, 2, 3].map((ordinal: number) => <option key={ordinal} value={ordinal}>{ordinal}</option>)
								}
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Control style={{marginTop: ".75em"}} type="checkbox" checked={charge.attributes.otv} onChange={() => toggleOTV(0)} />
						</Form.Group>
					</Col>
				</Row>

		}

		{
			props.multiple
				? <ButtonGroup>
					<Button size="sm" onClick={addDos}>Add</Button>
					<Button size="sm" onClick={removeDos} disabled={!charge.dos || charge.dos.length === 1}>Remove</Button>
				</ButtonGroup>
				: null
		}

	</>
}

export default Dos

