import { Action, Payload } from "../../actions/modal/NewICD10"
import * as _ from "lodash"

export interface ICD10Attributes {
	code: string
	description: string
}

export interface State {
	show: boolean
	icd10: ICD10Attributes
}

export const defaultState: State = {
	show: false
	, icd10: {
		code: ""
		, description: ""
	}
}

export const reducer = (state: State = defaultState, payload: Payload): State => {
	let nextState = _.cloneDeep(state)

	switch (payload.type) {
		case Action.OPENED:
			nextState.show = true
			break
		case Action.CLOSED:
			nextState = _.cloneDeep(defaultState)
			break
		case Action.SET_CODE:
			nextState.icd10.code = payload.data.code
			break
		case Action.SET_DESCRIPTION:
			nextState.icd10.description = payload.data.description
			break
	}

	return nextState
}