import React, { useState, useEffect, useContext, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Modal,
	ModalBody,
	ModalFooter,
	Button,
	Row,
	Col,
	FormGroup,
	FormLabel,
	FormControl,
	Tabs,
	Tab,
	ModalTitle,
	FormText,
	Form,
} from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { close } from "../../actions/ModalActions";
import { AppState } from "../../reducers";
import DatePicker from "react-datepicker";
import Axios from "../../common/axios";

import moment from "moment";
import TreatmentContext from "../../contexts/TreatmentContext";

import SelectStatus from "./PreauthModal/SelectStatus";

import "react-datepicker/dist/react-datepicker.css";
import "./PreauthModal.css";
import { byId } from "../../common/jsonapi-filters";
import { updated } from "../../actions/EventTimeActions";
import { useTokens } from "../../useAuthorizationCodeFlow"

interface Patient {
	accountNumber: string;
	dob: string;
	firstName: string;
	lastName: string;
	gender: string;
}

interface PatientProps {
	patient: Patient;
	onChange: (patient: Patient) => void;
}

const Patient = (props: PatientProps) => {
	const { patient, onChange } = props;

	const handleUpdateFirstName = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...patient, firstName: e.currentTarget.value });
	};

	const handleUpdateLastName = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...patient, lastName: e.currentTarget.value });
	};

	const handleUpdateAccountNumber = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...patient, accountNumber: e.currentTarget.value });
	};

	const handleUpdateDOB = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...patient, dob: e.currentTarget.value });
	};

	const handleUpdateGender = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...patient, gender: e.currentTarget.value });
	};

	return (
		<div>
			<FormGroup>
				<FormLabel>First Name</FormLabel>
				<FormControl
					type="input"
					value={patient.firstName}
					onChange={handleUpdateFirstName}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>Last Name</FormLabel>
				<FormControl
					type="input"
					value={patient.lastName}
					onChange={handleUpdateLastName}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>Account Number</FormLabel>
				<FormControl
					type="input"
					value={patient.accountNumber}
					onChange={handleUpdateAccountNumber}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>DOB</FormLabel>
				<FormControl
					type="input"
					value={patient.dob}
					onChange={handleUpdateDOB}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>Gender</FormLabel>
				<FormControl
					type="input"
					value={patient.gender}
					onChange={handleUpdateGender}
				/>
			</FormGroup>
		</div>
	);
};

interface Facility {
	name: string;
	address: string;
}

interface FacilityProps {
	facility: Facility;
	onChange: (facility: Facility) => void;
}

const Facility = (props: FacilityProps) => {
	const { facility, onChange } = props;

	const handleUpdateName = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...facility, name: e.currentTarget.value });
	};

	const handleUpdateAddress = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...facility, address: e.currentTarget.value });
	};

	return (
		<>
			<FormGroup>
				<FormLabel>Facility Name</FormLabel>
				<FormControl
					type="input"
					value={facility.name}
					onChange={handleUpdateName}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>Facility Address</FormLabel>
				<FormControl
					type="input"
					value={facility.address}
					onChange={handleUpdateAddress}
				/>
				<FormText>Optional</FormText>
			</FormGroup>
		</>
	);
};

interface Insurance {
	carrier: string;
	address: string;
}

interface InsuranceProps {
	insurance: Insurance;
	onChange: (insurance: Insurance) => void;
}

const Insurance = (props: InsuranceProps) => {
	const { insurance, onChange } = props;

	const handleUpdateCarrier = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...insurance, carrier: e.currentTarget.value });
	};

	const handleUpdateAddress = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...insurance, address: e.currentTarget.value });
	};

	return (
		<>
			<FormGroup>
				<FormLabel>Insurance Carrier</FormLabel>
				<FormControl
					type="input"
					value={insurance.carrier}
					onChange={handleUpdateCarrier}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>Insurance Address</FormLabel>
				<FormControl
					type="input"
					value={insurance.address}
					onChange={handleUpdateAddress}
				/>
				<FormText>Optional</FormText>
			</FormGroup>
		</>
	);
};

interface Physician {
	name: string;
	address: string;
}

interface PhysicianProps {
	physician: Physician;
	onChange: (physician: Physician) => void;
}

const Physician = (props: PhysicianProps) => {
	const { physician, onChange } = props;

	const handleChangeName = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...physician, name: e.currentTarget.value });
	};

	const handleChangeAddress = (e: FormEvent<HTMLInputElement>) => {
		onChange({ ...physician, address: e.currentTarget.value });
	};

	return (
		<>
			<FormGroup>
				<FormLabel>Physician Name</FormLabel>
				<FormControl
					type="input"
					value={physician.name}
					onChange={handleChangeName}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>Physician Address</FormLabel>
				<FormControl
					type="input"
					value={physician.address}
					onChange={handleChangeAddress}
				/>
				<FormText>Optional</FormText>
			</FormGroup>
		</>
	);
};

const CustomOption = (props: any) => {
	const { innerRef, innerProps, getStyles, data } = props;

	return (
		<div ref={innerRef} {...innerProps} style={getStyles("option", props)}>
			<div>{data.label}</div>
			<div style={{ fontSize: "smaller", color: "gray" }}>
				{data.description}
			</div>
		</div>
	);
};

interface DiagnosisProps {
	diagnosis: string[];
	onChange: (diagnosis: string[]) => void;
}

const Diagnosis = (props: DiagnosisProps) => {
	const { diagnosis, onChange } = props;

	return (
		<AsyncSelect
			isMulti
			loadOptions={async (inputValue: string, callback: Function) => {
				const { status, data } = await Axios.get("/api/icd10?filter[__search]=" + inputValue)

				if (status === 200) {
					callback(
						data.data.map((icd10: any) => {
							return {
								value: icd10.attributes.code,
								label: icd10.attributes.code,
								description: icd10.attributes.desc,
							};
						})
					);
				}
			}}
			components={{ Option: CustomOption }}
			value={diagnosis.map((dx: string) => {
				return {
					value: dx,
					label: dx,
				};
			})}
			onChange={(option: any) => {
				if (!option) {
					onChange([]);
				} else {
					onChange(option.map((obj: any) => obj.value));
				}
			}}
		/>
	);
};

interface Preauth {
	statusUUID: string;
	patient: Patient;
	facility: Facility;
	physician: Physician;
	insurance: Insurance;
	diagnosis: string[];
	modalities: string[];
	fractions: number;
	simulationDate: string | null;
	treatmentStartDate: string | null;
	startDate: string | null;
	endDate: string | null;
	authorizationNumber: string;
	completedBenefitCheck: boolean;
	notes: string[];
	treatmentID: number;
}

const defaultPreauth: Preauth = {
	statusUUID: "5650649f-be30-42f6-a9fa-639806d58887",
	patient: {
		accountNumber: "",
		dob: "",
		firstName: "",
		lastName: "",
		gender: "",
	},
	facility: {
		name: "",
		address: "",
	},
	physician: {
		name: "",
		address: "",
	},
	insurance: {
		carrier: "",
		address: "",
	},
	diagnosis: [],
	modalities: [],
	fractions: 0,
	simulationDate: null,
	treatmentStartDate: null,
	startDate: null,
	endDate: null,
	authorizationNumber: "",
	notes: [],
	completedBenefitCheck: false,
	treatmentID: 0,
};

const PreauthModal = () => {
	const show = useSelector(
		(state: AppState) =>
			state.modal.isOpen && state.modal.modalIdentifier === "NewPreauth"
	);
	const dispatch = useDispatch();
	const { getAccessToken } = useTokens()
	const [isSaveWaiting, setIsSaveWaiting] = useState(false);
	const [treatment, included] = useContext(TreatmentContext);
	const [preauth, setPreauth] = useState(defaultPreauth);

	const facilityList = useSelector((state: AppState) => state.facility);
	const insuranceList = useSelector((state: AppState) => state.insurance);

	useEffect(() => {
		if (show) {
			const { firstName, lastName, mrn, dob, gender } = included.find(
				(item: any) => item.type === "Patient"
			).attributes;
			const patient = {
				firstName,
				lastName,
				dob,
				gender,
				accountNumber: mrn,
			};

			let nextPreauth = {
				...defaultPreauth,
				patient,
				diagnosis: treatment.attributes.diagnosis,
				facility: {
					name: facilityList.data.find(
						byId(treatment.relationships.facility.data.id)
					).attributes.name,
					address: "",
				},
				insurance: {
					carrier: insuranceList.data.find(
						byId(treatment.relationships.insurance.data.id)
					).attributes.name,
					address: "",
				},
			};

			setPreauth(nextPreauth);
		}
	}, [show, facilityList, insuranceList, treatment, included]);

	const setModalities = (option: any) => {
		setPreauth({
			...preauth,
			modalities: option.map((obj: any) => obj.value),
		});
	};

	const setFractions = (e: FormEvent<HTMLInputElement>) => {
		setPreauth({ ...preauth, fractions: Number(e.currentTarget.value) });
	};

	const setAuthorizationNumber = (e: FormEvent<HTMLInputElement>) => {
		setPreauth({ ...preauth, authorizationNumber: e.currentTarget.value });
	};

	const setSimulationDate = (date: Date) => {
		setPreauth({
			...preauth,
			simulationDate: moment(date).format("YYYY-MM-DD"),
		});
	};

	const setTreatmentStartDate = (date: Date) => {
		setPreauth({
			...preauth,
			treatmentStartDate: moment(date).format("YYYY-MM-DD"),
		});
	};

	const setStartDate = (date: Date) => {
		setPreauth({
			...preauth,
			startDate: moment(date).format("YYYY-MM-DD"),
		});
	};

	const setEndDate = (date: Date) => {
		setPreauth({ ...preauth, endDate: moment(date).format("YYYY-MM-DD") });
	};

	const setPhysician = (physician: Physician) => {
		setPreauth({ ...preauth, physician });
	};

	const setInsurance = (insurance: Insurance) => {
		setPreauth({ ...preauth, insurance });
	};

	const setFacility = (facility: Facility) => {
		setPreauth({ ...preauth, facility });
	};

	const setDiagnosis = (diagnosis: string[]) => {
		if (!diagnosis) {
			diagnosis = [];
		}

		setPreauth({ ...preauth, diagnosis });
	};

	const setPatient = (patient: Patient) => {
		setPreauth({ ...preauth, patient });
	};

	const setStatusUUID = (statusUUID: string) => {
		setPreauth({ ...preauth, statusUUID });
	};

	const toggleCompletedBenefitCheck = () => {
		setPreauth({
			...preauth,
			completedBenefitCheck: !preauth.completedBenefitCheck,
		});
	};

	const saveAndClose = () => {
		setIsSaveWaiting(true);

		const save = async () => {
			try {
				await Axios.post(
					`/remote-preauth/api/${localStorage.getItem("tenant)_id")!}/preauth/create`,
					{ ...preauth, treatmentID: treatment.id }
				);
				dispatch(updated("preauth"));
				dispatch(close());
			} catch (err) {
				alert(err);
			}
		};

		save();

		setIsSaveWaiting(false);
	};

	const authorizationNumberRequired = (preauth: any): boolean => {
		const statusUUIDs = [
			'3f40efb4-4fd0-4de9-81b1-f131a759610d' // Auto approved
			, 'a59e7bef-8062-446f-ba27-0b731f67d2ef' // Submitted
			, '53f81284-c844-4d6a-a87d-568c2558de80' // Not Required
			, '4c27d5d3-a9c2-4c7c-abc4-0e7c7f4cee46' // Approved
		]

		return (preauth.authorizationNumber === "" && statusUUIDs.includes(preauth.statusUUID))
	}

	const valid = (preauth: any): boolean => {
		return !authorizationNumberRequired(preauth)
	}

	console.log(moment(preauth.simulationDate).diff(moment.now(), 'days'))

	return (
		<Modal show={show} onHide={() => { }} className={"preauth-modal"}>
			<Modal.Header>
				<ModalTitle>New Preauth</ModalTitle>
			</Modal.Header>
			<ModalBody>
				<Tabs defaultActiveKey={1} id="preauth-tabs" variant="pills">
					<Tab eventKey={1} title="Preauth">
						<div style={{ marginTop: "15px" }}>
							<Row>
								<Col md={9}>
									<FormGroup>
										<FormLabel>Modalities</FormLabel>
										<Select
											isMulti
											options={[
												"2D",
												"3D",
												"3D Boost",
												"Electron",
												"IGRT",
												"DIBH",
												"IMRT",
												"SRS",
												"SBRT",
												"CK – 3D",
												"CK – IMRT",
												"GK",
												"LDR",
												"HDR",
												"IORT",
												"XOFIGO",
												"Accuboost",
												"Sirspheres",
												"Eye Plaque",
												"Space Oars",
												"Superficial",
												"Fid Markers",
												"MRI",
												"CT",
												"PET",
												"MRA",
												"Bone Scan",
												"VS",
												"IMRT Boost",
												"Y-90",
											].map((dx) => {
												return { label: dx, value: dx };
											})}
											value={preauth.modalities.map(
												(m: string) => {
													return {
														label: m,
														value: m,
													};
												}
											)}
											onChange={setModalities}
										/>
									</FormGroup>
								</Col>
								<Col md={3}>
									<FormGroup>
										<FormLabel>Fractions</FormLabel>
										<FormControl
											type="input"
											value={String(preauth.fractions)}
											onChange={setFractions}
										/>
									</FormGroup>
								</Col>
							</Row>

							<FormGroup>
								<FormLabel>Diagnosis</FormLabel>
								<Diagnosis
									diagnosis={preauth.diagnosis}
									onChange={setDiagnosis}
								/>
							</FormGroup>

							<FormGroup>
								<FormLabel>Authorization Number</FormLabel>
								<FormControl
									type="input"
									value={preauth.authorizationNumber}
									onChange={setAuthorizationNumber}
								/>
								{
									authorizationNumberRequired(preauth)
										? <FormText style={{ color: "red" }}>Required</FormText>
										: null
								}
							</FormGroup>

							<Form.Group controlId="formBasicCheckbox">
								<Form.Check
									type="checkbox"
									label="Completed Benefit Check"
									checked={preauth.completedBenefitCheck}
									onClick={toggleCompletedBenefitCheck}
								/>
							</Form.Group>

							<Row>
								<Col md={4}>
									<FormGroup>
										<FormLabel>Simulation Date</FormLabel>
										<DatePicker
											className="form-control"
											selected={
												!preauth.simulationDate
													? null
													: moment(
														preauth.simulationDate.substring(
															0,
															10
														)
													).toDate()
											}
											onChange={setSimulationDate}
										/>
										{
											preauth.simulationDate && Math.abs(moment(preauth.simulationDate).diff(moment.now(), 'days')) > 365
												? <FormText className="warning" style={{ "color": "red" }}>Are you sure about the date?</FormText>
												: null
										}
									</FormGroup>
								</Col>

								<Col md={4} style={{ marginLeft: "15px" }}>
									<FormGroup>
										<FormLabel>
											Treatment Start Date
										</FormLabel>
										<DatePicker
											className="form-control"
											selected={
												!preauth.treatmentStartDate
													? null
													: moment(
														preauth.treatmentStartDate.substring(
															0,
															10
														)
													).toDate()
											}
											onChange={setTreatmentStartDate}
										/>
										{
											preauth.treatmentStartDate && Math.abs(moment(preauth.treatmentStartDate).diff(moment.now(), 'days')) > 365
												? <FormText className="warning" style={{ "color": "red" }}>Are you sure about the date?</FormText>
												: null
										}
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col md={12}>
									<FormLabel>Valid Date Range</FormLabel>
								</Col>

								<Col md={4}>
									<DatePicker
										className="form-control"
										selected={
											!preauth.startDate
												? null
												: moment(
													preauth.startDate.substring(
														0,
														10
													)
												).toDate()
										}
										selectsStart
										startDate={
											!preauth.startDate
												? null
												: moment(
													preauth.startDate.substring(
														0,
														10
													)
												).toDate()
										}
										endDate={
											!preauth.startDate
												? null
												: moment(
													String(
														preauth.endDate
													).substring(0, 10)
												).toDate()
										}
										onChange={setStartDate}
									/>
								</Col>
								<Col md={4} style={{ marginLeft: "15px" }}>
									<DatePicker
										className="form-control"
										selected={
											!preauth.endDate
												? null
												: moment(
													preauth.endDate.substring(
														0,
														10
													)
												).toDate()
										}
										selectsEnd
										startDate={
											!preauth.endDate
												? null
												: moment(
													String(
														preauth.startDate
													).substring(0, 10)
												).toDate()
										}
										endDate={
											!preauth.endDate
												? null
												: moment(
													preauth.endDate.substring(
														0,
														10
													)
												).toDate()
										}
										onChange={setEndDate}
										minDate={
											!preauth.endDate
												? null
												: moment(
													String(
														preauth.startDate
													).substring(0, 10)
												).toDate()
										}
									/>
								</Col>
							</Row>

							<Row>
								<Col md={12}>
									<FormGroup>
										<FormLabel>Status</FormLabel>
										<SelectStatus
											statusUUID={preauth.statusUUID}
											onChange={setStatusUUID}
										/>
									</FormGroup>
								</Col>
							</Row>
						</div>
					</Tab>
					<Tab eventKey={2} title="Facility">
						<div style={{ marginTop: "15px" }}>
							<Facility
								facility={preauth.facility}
								onChange={setFacility}
							/>
						</div>
					</Tab>
					<Tab eventKey={3} title="Insurance">
						<div style={{ marginTop: "15px" }}>
							<Insurance
								insurance={preauth.insurance}
								onChange={setInsurance}
							/>
						</div>
					</Tab>
					<Tab eventKey={4} title="Physician">
						<div style={{ marginTop: "15px" }}>
							<Physician
								physician={preauth.physician}
								onChange={setPhysician}
							/>
						</div>
					</Tab>
					<Tab eventKey={5} title="Patient">
						<div style={{ marginTop: "15px" }}>
							<Patient
								patient={preauth.patient}
								onChange={setPatient}
							/>
						</div>
					</Tab>
				</Tabs>
			</ModalBody>
			<ModalFooter>
				<Button variant="secondary" onClick={() => dispatch(close())}>
					Cancel
				</Button>
				<Button
					variant="primary"
					disabled={isSaveWaiting || !valid(preauth)}
					onClick={saveAndClose}
				>
					{isSaveWaiting ? "Saving..." : "Save"}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default PreauthModal;
