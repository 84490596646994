import Axios from "../common/axios"

export enum Type {
    WAITING = "FETCH_MIPS_MEASURE_WAITING"
    , COMPLETED = "FETCH_MIPS_MEASURE_COMPLETED"
    , ERROR = "FETCH_MIPS_MEASURE_ERROR"
}

export interface Action {
    type: Type
    payload: any
}

export const fetch = () => async (dispatch: (action: Action) => Action, getState: () => any) => {
    const state = getState().mipsMeasure

    if (state.loading || state.fetched) {
        return
    }

    const uri = "/api/mips-measure"
    const cache = sessionStorage.getItem(uri)

    if (cache) {
        return dispatch({ type: Type.COMPLETED, payload: { data: JSON.parse(cache) } })
    }

    dispatch({ type: Type.WAITING, payload: {} })

    try {
        const { data, request } = await Axios.get(uri)

        if (request.responseURL.endsWith("/login")) {
            throw new Error("redirected to /login")
        }

        sessionStorage.setItem(uri, JSON.stringify(data))
        dispatch({ type: Type.COMPLETED, payload: { data } })
    } catch (error) {
        dispatch({ type: Type.ERROR, payload: { error } })
    }
}

export interface MIPSMeasureState {
    loading: boolean
    fetched: boolean

    data: any[]
    included: any[]
}

const reducer = (prevState: MIPSMeasureState, action: Action): MIPSMeasureState => {
    const state = { ...prevState }

    switch (action.type) {
        case Type.WAITING:
            state.loading = true
            break
        case Type.COMPLETED:
            state.loading = false
            state.fetched = true

            const { data, included } = action.payload.data

            state.data = [...data]

            if (included) {
                state.included = [...included]
            }
            break
    }

    return state
}

export default reducer