import moment from "moment"

enum Type {
	UPDATED = "EVENT_TIME_UPDATED"
}

interface Action {
	type: string
	payload: any
}

export const updated = (what: string) => {
	return { type: Type.UPDATED, payload: { what } }
}

const reducer = (prevState: any, action: Action) => {
	const state = { ...prevState }

	switch (action.type) {
		case Type.UPDATED:
			state[action.payload.what].push(moment().toJSON())
			break
	}



	return state
}

export default reducer