import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { Button, ButtonGroup, DropdownButton, NavItem, Col, Table } from "react-bootstrap"
import { AppState } from "../../reducers"
import Axios from "../../common/axios"
// @ts-ignore
import { RTFJS } from 'rtf.js';
import { useTokens } from "../../useAuthorizationCodeFlow"

// import ChargeCell from "../ChargeCell"
// import ChargeModal from "../../components/modal/ChargeModal"
// import TreatmentModal from "../modal/NewTreatmentModal"

import actions from "../../actions"
import { getTenantId } from "../../common/jwt"

interface DocumentDetailStateProps {
    currentTreatment: any
    currentDocument: any
    currentVersion: any

    currentCharges: any[]
    chargeIncluded: any[]

    facilityList: any
    documentStatusList: any
}

interface DocumentDetailDispatchProps {
    dispatch: (action: any) => void
}

const Toolbar = (props: DocumentDetailStateProps & DocumentDetailDispatchProps) => {
    const { getAccessToken } = useTokens()

    const barStyle = {
        padding: "0 15px 0 15px"
        , lineHeight: "50px"
        , backgroundColor: "#aaa"
    }

    const attrStyle = {
        marginRight: "10px"
    }

    const facility = props.facilityList.data.find((facility: any) => {
        return facility.id === props.currentDocument.relationships.facility.data.id
    })

    return <div style={barStyle}>
        <span style={attrStyle}><strong>Attending:</strong> {props.currentVersion.meta.attending_physician_code}</span>
        <span style={attrStyle}><strong>Signing:</strong> {props.currentVersion.meta.signing_physician_code}</span>
        <span style={attrStyle}><strong>Facility:</strong> {facility.attributes.code}</span>
        <span style={attrStyle}><strong>External Edit Date:</strong> {props.currentVersion.attributes.editdate}</span>
        <span style={attrStyle}><strong>External Status:</strong> {props.currentVersion.meta.status_code}</span>
        <span style={attrStyle}><strong>Status:</strong> {props.documentStatusList.data.find((status: any) => status.id === props.currentDocument.relationships.status.data.id).attributes.name}</span>

        <ButtonGroup className="pull-right" style={{ marginTop: "7px", marginRight: "5px" }}>
            <DropdownButton title="Set Status" id="bg-nested-dropdown">
                {
                    props.documentStatusList.data.map((status: any, index: number) => {
                        return <NavItem key={index}
                            onClick={(e: any) => {
                                props.dispatch(actions().document.setStatus(getTenantId(), props.currentDocument.id, status.id, ""))
                            }}>
                            {status.attributes.name}
                        </NavItem>
                    })
                }
            </DropdownButton>
        </ButtonGroup>

        <Button style={{ marginTop: "7px", marginRight: "5px" }} className="pull-right" onClick={(e: any) => {
            // props.dispatch(actions().treatmentModal.open(props.currentTreatment))
        }}>Edit Treatment</Button>
    </div>
}

const useDocument = (documentId: number) => {
    const { getAccessToken } = useTokens()
    const [doc, setDoc] = useState<HTMLElement | undefined>()

    useEffect(() => {
        const fetchRtf = async () => {
            const stringToArrayBuffer = (str: string) => {
                const buffer = new ArrayBuffer(str.length);
                const bufferView = new Uint8Array(buffer);
                for (let i = 0; i < str.length; i++) {
                    bufferView[i] = str.charCodeAt(i);
                }
                return buffer;
            }

            RTFJS.loggingEnabled(false)

            const resp = await Axios.get(`/api/${getTenantId()}/document/rtf/${documentId}`)
            const d = new RTFJS.Document(stringToArrayBuffer(resp.data), {});

            const div = document.createElement("div")
            div.append(...await d.render())

            setDoc(div)
        }

        if (documentId) {
            fetchRtf()
        }

    }, [documentId, getAccessToken])

    return {
        doc
    }
}

const DocumentDetail = (props: DocumentDetailStateProps & DocumentDetailDispatchProps) => {
    const { doc } = useDocument(props.currentDocument?.id)

    if (!props.currentDocument || !props.currentTreatment) {
        return null
    }

    const toolbar = Toolbar(props)

    let docHtml: string = ""
    if (doc?.innerHTML) {
        docHtml = doc?.innerHTML
    }


    return <div>
        {/* <TreatmentModal /> */}

        {/* {
            !props.currentTreatment
                ? null
                : <ChargeModal />
        } */}

        {toolbar}

        <Col md={9} style={{ padding: 0, margin: 0, minHeight: "500px", height: "calc(100% - 50px)" }}>
            <div dangerouslySetInnerHTML={{ __html: docHtml }}></div>
        </Col>
        <Col md={3} style={{ padding: 0, margin: 0, height: "calc(100% - 50px)", background: "rgb(245, 245, 245)" }}>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>DOS</th>
                        <th>CPT</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.currentCharges.map((charge: any) => {
                            return <tr key={charge.id}>
                                <td>{charge.attributes.dos}</td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </Col>
    </div>

}

const getCurrentDocument = (documentList: any) => {
    if (!documentList.selectedId) {
        return null
    }

    return documentList.data.find((document: any) => {
        return document.id === documentList.selectedId
    })
}

const getCurrentVersion = (documentList: any) => {
    if (!documentList.selectedId) {
        return null
    }

    const d = getCurrentDocument(documentList)

    return documentList.included.find((included: any) => {
        return included.type === "DocumentVersion" && included.id === d.relationships.documentversion.data
            .map((data: any) => data.id)
            .sort((a: number, b: number) => b - a)
        [0]
    })
}

const getCurrentTreatment = (treatmentList: any) => {
    if (!treatmentList.data || !treatmentList.data.length) {
        return null
    }

    return treatmentList.data[0]
}

const mapStateToProps = ({ charge, treatment, document, facility, documentStatusList }: AppState, ownProps: any): any => {
    const currentTreatment: any = getCurrentTreatment(treatment)
    const currentDocument: any = getCurrentDocument(document)
    const currentVersion: any = getCurrentVersion(document)

    const currentCharges: any[] = charge.data.filter((c: any) => {
        const assignedToDocument = c.relationships.document.data.id === document.selectedId
        const assignedToTreatment = c.meta.treatment_id === currentTreatment.id && (c.meta.category_id === 1 || c.attributes.cpt.code === "EOT")

        return assignedToDocument || assignedToTreatment
    })

    return {
        currentCharges
        , chargeIncluded: charge.included

        , currentTreatment

        , currentDocument
        , currentVersion

        , documentStatusList
        , facilityList: facility
    }
}

const mapDispatchToProps = (dispatch: (action: any) => void): any => {
    return {
        dispatch: (action: any) => dispatch(action)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetail)
