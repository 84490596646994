import React from "react"
import AsyncSelect from "react-select/async"
import Axios from "../../../common/axios"

const CustomOption = (props: any) => {
    const { innerRef, innerProps, getStyles, data } = props

    return <div ref={innerRef} {...innerProps} style={getStyles("option", props)}>
        <div>{data.label}</div>
        <div style={{ fontSize: "smaller", color: "gray" }}>{data.description}</div>
    </div>
}

export interface SelectDiagnosisProps {
    diagnosis: string[]
    onChange: (diagnosis: string[]) => void
}

const SelectDiagnosis = (props: SelectDiagnosisProps) => {
    const { diagnosis, onChange } = props

    return <AsyncSelect
        isMulti
        loadOptions={async (inputValue: string, callback: Function) => {
            const res = await Axios.get("/api/icd10?filter[__search]=" + inputValue)

            const { status, data } = res

            if (status === 200) {
                callback(data.data.map((icd10: any) => {
                    return {
                        value: icd10.attributes.code
                        , label: icd10.attributes.code
                        , description: icd10.attributes.desc
                    }
                }))
            }
        }}

        components={{ Option: CustomOption }}

        value={diagnosis.map((dx: string) => {
            return {
                value: dx
                , label: dx
            }
        })}

        onChange={(option: any) => onChange(option.map((obj: any) => obj.value))}
    />
}

export default SelectDiagnosis