import React, { useState, FormEvent } from "react"
import { useDispatch } from "react-redux"
import { updated } from "../../actions/EventTimeActions"
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { Patient } from "../../core/entities/patient"
import { useEvent } from "../../hooks/useEvent"

const EditPatientModal = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [patient, setPatient] = useState<Patient | undefined>()
	const [err, setErr] = useState<string | undefined>()
	const dispatch = useDispatch()

	useEvent("openEditPatientModal", ({ patient }) => {
		setPatient(patient)
		setErr(undefined)
		setIsOpen(true)
	})

	const handleClose = () => {
		setIsOpen(false)
	}

	const handleSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		const resp = await fetch(`/v2/patients`, {
			method: "PUT"
			, headers: {
				'Content-Type': 'application/json'
			}
			, body: JSON.stringify(patient)
		})

		const data = await resp.json()

		if ("error" in data) {
			setErr(data.error)
			return
		}

		dispatch(updated("treatment"))
		setIsOpen(false)
	}

	const handleChangeLastName = (e: FormEvent<HTMLInputElement>) => {
		setPatient({ ...patient!, lastName: e.currentTarget.value })
	}

	const handleChangeFirstName = (e: FormEvent<HTMLInputElement>) => {
		setPatient({ ...patient!, firstName: e.currentTarget.value })
	}

	const handleChangeMiddleInitial = (e: FormEvent<HTMLInputElement>) => {
		setPatient({ ...patient!, middleInitial: e.currentTarget.value })
	}

	const handleChangDOB = (e: FormEvent<HTMLInputElement>) => {
		setPatient({ ...patient!, dob: e.currentTarget.value })
	}

	const handleChangMRN = (e: FormEvent<HTMLInputElement>) => {
		setPatient({ ...patient!, mrn: e.currentTarget.value })
	}

	const handleChangAcct = (e: FormEvent<HTMLInputElement>) => {
		setPatient({ ...patient!, acct: e.currentTarget.value })
	}

	const handleChangeGender = (e: FormEvent<HTMLInputElement>) => {
		setPatient({ ...patient!, gender: e.currentTarget.value })
	}

	if (!patient) {
		return null
	}

	return <Modal show={isOpen} onHide={handleClose}>
		<Form onSubmit={handleSubmitForm}>
			<Modal.Header>
				<Modal.Title>Edit Patient</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					err
						? <div className="alert alert-danger">{err}</div>
						: null
				}
				<Form.Group>
					<Form.Label>Last Name</Form.Label>
					<Form.Control value={patient?.lastName} onChange={handleChangeLastName} />
				</Form.Group>

				<Row>
					<Col md={8}>
						<Form.Group>
							<Form.Label>First Name</Form.Label>
							<Form.Control value={patient?.firstName} onChange={handleChangeFirstName} />
						</Form.Group>

					</Col>
					<Col md={4}>
						<Form.Group>
							<Form.Label>MI</Form.Label>
							<Form.Control value={patient?.middleInitial} onChange={handleChangeMiddleInitial} />
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group>
							<Form.Label>DOB</Form.Label>
							<Form.Control value={patient?.dob} onChange={handleChangDOB} />
						</Form.Group>
					</Col>

					<Col md={6}>
						<Form.Group>
							<Form.Label>MRN</Form.Label>
							<Form.Control value={patient?.mrn} onChange={handleChangMRN} />
						</Form.Group>

					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group>
							<Form.Label>Gender</Form.Label>
							<Form.Control as="select" value={patient?.gender} onChange={handleChangeGender}>
								<option value="M">M</option>
								<option value="F">F</option>
							</Form.Control>
						</Form.Group>
					</Col>

					<Col>
						<Form.Group>
							<Form.Label>Acct</Form.Label>
							<Form.Control value={patient?.acct} onChange={handleChangAcct} />
						</Form.Group>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>Cancel</Button>
				<Button type="submit">Save</Button>
			</Modal.Footer>
		</Form>
	</Modal>
}

export default EditPatientModal