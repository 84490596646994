import { useState, useEffect } from "react"
import { Facility } from "./facility"

export interface FacilityImportMap {
    uuid: string
    pattern: string
    facility: Facility
}

export const useFacilityImportMapList = () => {
    const [facilityImportMaps, setFacilityImportMaps] = useState([] as FacilityImportMap[])
    const [err, setErr] = useState<string | undefined>()

    useEffect(() => {
        const doFetch = async () => {
            try {
                const resp = await fetch("/v2/facility-import-maps")
                const json = await resp.json()

                if ("error" in json) {
                    setErr(json.error)
                }

                setFacilityImportMaps(json)
            } catch (e) {
                setErr("something went wrong")
            }
        }

        doFetch()
    }, [])

    const createFacilityImportMap = async () => {
        try {
            const resp = await fetch("/v2/facility-import-maps/create", { method: "POST" })
            const json = await resp.json()

            if ("error" in json) {
                setErr(json.error)
                return
            }

            setFacilityImportMaps([...facilityImportMaps, json])
        } catch(e) {
            setErr("something went wrong")
        }
    }

    const updateFacilityImportMap = async (m: FacilityImportMap) => {
        try {
            const resp = await fetch("/v2/facility-import-maps/update", { method: "POST", body: JSON.stringify(m) })
            const json = await resp.json()

            if ("error" in json) {
                setErr(json.error)
            }

            const nextFactilityImportMaps = []

            for (const mm of facilityImportMaps) {
                if (mm.uuid == m.uuid) {
                    nextFactilityImportMaps.push(json)
                } else {
                    nextFactilityImportMaps.push(mm)
                }
            }

            setFacilityImportMaps(nextFactilityImportMaps)
        } catch (e) {
            setErr("something went wrong")
        }
    }

    const deleteFacilityImportMap = async (m: FacilityImportMap) => {
        try {
            await fetch("/v2/facility-import-maps/delete?uuid=" + m.uuid, { method: "DELETE" })

            const nextFactilityImportMaps = []

            for (const mm of facilityImportMaps) {
                if (mm.uuid != m.uuid) {
                    nextFactilityImportMaps.push(mm)
                }
            }

            setFacilityImportMaps(nextFactilityImportMaps)
        } catch (e) {
            setErr("something went wrong")
        }
    }

    return {
        facilityImportMaps
        , err
        , createFacilityImportMap
        , updateFacilityImportMap
        , deleteFacilityImportMap
    }
}