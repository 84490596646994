// import Axios from "../../common/axios"
// import { AppState } from "../../reducers"
// import * as jsonapi from "../../common/jsonapi"

export enum Action {
	OPENED = "NewICD10.Action.OPENED"
	, CLOSED = "NewICD10.Action.CLOSED"

	, SET_CODE = "NewICD10.Action.SET_CODE"
	, SET_DESCRIPTION = "NewICD10.Action.SET_DESCRIPTION"

	, SAVE_WAITING = "NewICD10.Action.SAVE_WAITING"
	, SAVE_ERROR = "NewICD10.Action.SAVE_ERROR"
	, SAVE_COMPLETED = "NewICD10.Action.SAVE_COMPLETED"
}

export interface Payload {
	type: Action
	data?: any
}

export const open = (): Payload => {
	return { type: Action.OPENED }
}

export const close = (): Payload => {
	return { type: Action.CLOSED }
}

export const save = () => {
	// return async (dispatch: (action: any) => void, getState: () => AppState) => {
	// 	dispatch({ type: Action.SAVE_WAITING })

	// 	try {
	// 		const attributes = getState().newReferringPhysicianModal.referringPhysician

	// 		const operations: jsonapi.Op[] = [
	// 			{
	// 				op: "add"
	// 				, ref: {
	// 					lid: 1
	// 					, type: "ReferringPhysician"
	// 				}
	// 				, data: {
	// 					type: "ReferringPhysician"
	// 					, lid: 1
	// 					, attributes
	// 					, relationships: {}
	// 				}
	// 			}
	// 		]

	// 		const data: jsonapi.OperationsRequest = { operations }

	// 		const res = await Axios({ method: "post", url: "/api/referring-physician/operations", data })
	// 		const referringPhysician = res.data.operations[0].data

	// 		const uri = "/api/referring-physician"
	// 		const cache = sessionStorage.getItem(uri)

	// 		if (cache) {
	// 			const parsed = JSON.parse(cache)
	// 			parsed.data.push(referringPhysician)

	// 			sessionStorage.setItem(uri, JSON.stringify(parsed))
	// 		}

	// 		dispatch({ type: Action.SAVE_COMPLETED, data: { referringPhysician } })
	// 		dispatch(close())
	// 	} catch (error) {
	// 		console.log("could not save referring physician: ", error)
	// 		dispatch({ type: Action.SAVE_ERROR, data: { error } })
	// 	}
	// }
}

export const setCode = (code: string): Payload => {
	return { type: Action.SET_CODE, data: { code } }
}

export const setDescription = (description: string): Payload => {
	return { type: Action.SET_DESCRIPTION, data: { description } }
}