import React, { FormEvent } from "react"
import { FormGroup, FormLabel, FormControl, FormText } from "react-bootstrap"

export interface Insurance {
    carrier: string
    address: string
}

export interface SelectInsuranceProps {
    insurance: Insurance
    onChange: (insurance: Insurance) => void
}

const SelectInsurance = (props: SelectInsuranceProps) => {
    const { insurance, onChange } = props


    const handleUpdateCarrier = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...insurance, carrier: e.currentTarget.value })
    }

    const handleUpdateAddress = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...insurance, address: e.currentTarget.value })
    }

    return <>
        <FormGroup>
            <FormLabel>Insurance Carrier</FormLabel>
            <FormControl type="input" value={insurance.carrier} onChange={handleUpdateCarrier} />
        </FormGroup>
        <FormGroup>
            <FormLabel>Insurance Address</FormLabel>
            <FormControl type="input" value={insurance.address} onChange={handleUpdateAddress} />
            <FormText>Optional</FormText>
        </FormGroup>
    </>
}

export default SelectInsurance