import treatmentModalReducer from "./TreatmentModalActions"

export enum ModalType {
	OPENED = "Modal.OPENED"
	, CLOSED = "Modal.CLOSED"
}

interface ModalPayload {
	modalIdentifier: string
	modalProps: any
}

export interface ModalAction {
	type: string
	payload: ModalPayload | null
}

export const open = (modalIdentifier: string, modalProps: any): ModalAction => {
	return { type: ModalType.OPENED, payload: { modalIdentifier, modalProps } }
}

export const close = (): ModalAction => {
	return { type: ModalType.CLOSED, payload: null }
}

export interface ModalState {
	isOpen: boolean
	, modalIdentifier: string
	, modalProps: any
}

const defaultModalState: ModalState = {
	isOpen: false
	, modalIdentifier: ""
	, modalProps: {}
}

const reducer = (prevState: ModalState = defaultModalState, action: ModalAction): ModalState => {
	const state = { ...prevState }

	switch (action.type) {
		case ModalType.OPENED:
			state.isOpen = true
			state.modalIdentifier = action.payload!.modalIdentifier
			state.modalProps = { ...action.payload!.modalProps }
			break
		case ModalType.CLOSED:
			state.isOpen = false
			break
	}

	return treatmentModalReducer(state, action)
}

export default reducer