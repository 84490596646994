import { useState, useEffect } from "react"

export interface Practice {
    id: number
    name: string
    code: string
}

export const usePracticeList = () => {
    const [practices, setPractices] = useState<Practice[]>([])

    useEffect(() => {
        const doFetch = async () => {
            const resp = await fetch(`/v2/practices`)
            const json = await resp.json()

            setPractices(json)
        }

        doFetch()
    }, [])

    return {
        practices
    }
}