import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { Alert } from "react-bootstrap"

interface NotificationItemProps {
    style: | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'

    message: string
    href?: string
}

const NotificationItem = (props: NotificationItemProps) => {
    const [show, setShow] = useState(true)
    useEffect(() => {
        setTimeout(() => setShow(false), 10000)
    }, [])

    const { style, message, href } = props

    const handleDismiss = () => {
        setShow(false)
    }

    return show
        ? <Alert style={{ zIndex: 2000 }} variant={style} onClose={handleDismiss}>
            {
                !href
                    ? <p>{message}</p>
                    : <p><a href={href}>{message}</a></p>
            }
        </Alert>
        : null
}

export interface NotificationListProps {
    notifications: any[]
}

export const NotificationList = (props: NotificationListProps) => {
    const { notifications } = props

    return <div style={{ position: "fixed", right: "15px", top: "15px", zIndex: 100, width: "500px" }}>
        {
            !notifications
                ? null
                : notifications.map((n: any, index: number) => {
                    return <NotificationItem style={n.style} message={n.message} href={n.href} key={index} />
                })
        }
    </div>
}

const mapStateToProps = (state: any, ownProps: any): any => {
    return {
        notifications: state.notificationList.data.map((notification: any) => {
            return notification
        })
    }
}

export default connect(mapStateToProps)(NotificationList)