import { Action as FacilityAction } from "../../actions/preauth/FacilityActions"
import { Action as InsuranceAction } from "../../actions/preauth/InsuranceActions"
import { Action as PhysicianAction } from "../../actions/preauth/PhysicianActions"
import { Action as StatusAction } from "../../actions/preauth/StatusActions"

interface ListState {
	data: any[]
	isLoading: boolean
	isFetched: boolean
}

export interface PreauthState {
	facilityList: ListState
	insuranceList: ListState
	physicianList: ListState
	statusList: ListState
}

export const defaultPreauthState: PreauthState = {
	facilityList: {
		data: []
		, isLoading: false
		, isFetched: false
	}
	, insuranceList: {
		data: []
		, isLoading: false
		, isFetched: false
	}
	, physicianList: {
		data: []
		, isLoading: false
		, isFetched: false
	}
	, statusList: {
		data: []
		, isLoading: false
		, isFetched: false
	}
}

const reducer = (prevState: any, action: any) => {
	const state = { ...prevState }

	for (const key in state) {
		state[key] = { ...prevState[key] }
	}

	switch (action.type) {
		case FacilityAction.FETCH_WAITING:
			state.facilityList.isLoading = true
			break
		case FacilityAction.FETCH_ERROR:
			state.facilityList.isLoading = false
			break
		case FacilityAction.FETCH_COMPLETED:
			state.facilityList.isLoading = false
			state.facilityList.isFetched = true
			state.facilityList.data = action.payload.data
			break

		case InsuranceAction.FETCH_WAITING:
			state.insuranceList.isLoading = true
			break
		case InsuranceAction.FETCH_ERROR:
			state.insuranceList.isLoading = false
			break
		case InsuranceAction.FETCH_COMPLETED:
			state.insuranceList.isLoading = false
			state.insuranceList.isFetched = true
			state.insuranceList.data = action.payload.data
			break
		
		case PhysicianAction.FETCH_WAITING:
			state.physicianList.isLoading = true
			break
		case PhysicianAction.FETCH_ERROR:
			state.physicianList.isLoading = false
			break
		case PhysicianAction.FETCH_COMPLETED:
			state.physicianList.isLoading = false
			state.physicianList.isFetched = true
			state.physicianList.data = action.payload.data
			break

		case StatusAction.FETCH_WAITING:
			state.statusList.isLoading = true
			break
		case StatusAction.FETCH_ERROR:
			state.statusList.isLoading = false
			break
		case StatusAction.FETCH_COMPLETED:
			state.statusList.isLoading = false
			state.statusList.isFetched = true
			state.statusList.data = action.payload.data
			break

	}

	return state
}

export default reducer