import React from "react"
import { useSelector } from "react-redux"
import Select, { ValueType } from "react-select"

import { AppState } from "../../reducers"

interface DiagnosisSelectProps {
    defaultDiagnosis?: string[]
    onChangeDiagnosis?: (dx: string[]) => void
}

const DiagnosisSelect = (props: DiagnosisSelectProps) => {
    const { data } = useSelector((state: AppState) => state.icd10)

    return <Select
        defaultValue={props.defaultDiagnosis?.map((dx: string) => {
            return { value: dx, label: dx }
        })}

        options={data.map((dx: any) => {
            return { value: dx.attributes.code, label: dx.attributes.code, desc: dx.attributes.desc }
        })}

        formatOptionLabel={(option: any, labelMeta: any) => {
            if (labelMeta.context === "menu") {
                return <div>
                    <div>{option.value}</div>
                    <div style={{ fontSize: "smaller", color: "gray" }}>{option.desc}</div>
                </div>
            }

            return option.value
        }}

        onChange={(value: ValueType<any>) => {
            if (props.onChangeDiagnosis) {
                props.onChangeDiagnosis(value as string[])
            }
        }}

        isMulti />
}

export default DiagnosisSelect