import React, { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as Bootstrap from "react-bootstrap"
import moment from 'moment'

import { open } from "../actions/ModalActions"

import ChargeCell from "./ChargeCell"
import { AppState } from "../reducers"
import { byId, byRelationshipId, byType } from "../common/jsonapi-filters"
import { uniqWith, isEqual } from "lodash"
import ChargeListContext from "../contexts/ChargeListContext"
import TreatmentContext from "../contexts/TreatmentContext"

const Fractions = () => {
    const physicianList = useSelector((state: AppState) => state.physician)
    const selectedChargeIds = useSelector((state: AppState) => state.chargeMultiEdit.chargeIds)

    const [charges] = useContext(ChargeListContext)
    const [, included] = useContext(TreatmentContext)

    const dispatch = useDispatch()

    const numFractions = included.filter(byType("Phase")).reduce((sum: number, phase: any) => sum + phase.attributes.fractions, 0)

    const openMultiEditModal = (chargeIds: number[]) => {
        dispatch(open("MultiEditCharges", { chargeIds }))
    }

    const byCategoryId = (...categoryIds: number[]) => (item: any) => categoryIds.indexOf(item.meta.category_id) !== -1
    const byDos = (dos: string) => (item: any) => item.attributes.dos === dos
    const byOrdinal = (ordinal: number) => (item: any) => item.attributes.ordinal === ordinal

    const dates = charges
        .filter(byCategoryId(4, 5, 10, 11, 12))
        .filter(byRelationshipId("Status", 3, false))
        .map((charge: any) => { return { dos: charge.attributes.dos, ordinal: charge.attributes.ordinal } })

    const fractions = charges.filter(byCategoryId(11))
    const weeklies = charges.filter(byCategoryId(4, 10))
    const igrts = charges.filter(byCategoryId(5))
    const technicals = charges.filter(byCategoryId(12))

    return <Bootstrap.Table bordered hover className="charge-table">
        <thead>
            <tr>
                <th colSpan={8}>
                    Fractions {`(${fractions.length} of ${numFractions})`}
                    <Bootstrap.Button size="sm" style={{ float: "right", marginTop: ".37rem" }} disabled={selectedChargeIds.length ? false : true} onClick={(e: any) => {
                        openMultiEditModal(selectedChargeIds)
                    }}>Edit Selected</Bootstrap.Button>
                </th>
            </tr>
            <tr>
                <th></th>
                <th>DOS</th>
                <th>Daily</th>
                <th>IGRT</th>
                <th>MD</th>
                <th>Weekly</th>
                <th>MD</th>
                <th>Technical</th>
            </tr>
        </thead>
        <tbody>
            {
                uniqWith(dates, isEqual)
                    .sort((a: any, b: any) => (new Date(a.dos).getTime() + a.ordinal) - (new Date(b.dos).getTime() + b.ordinal))
                    .map((date: any, index: number) => {
                        const fraction = fractions.filter(byDos(date.dos)).find(byOrdinal(date.ordinal))

                        const igrt = igrts.filter(byDos(date.dos)).find(byOrdinal(date.ordinal))
                        const igrtPhysician = physicianList.data.find(byId(igrt?.relationships.physician.data.id))

                        const weekly = weeklies.filter(byDos(date.dos)).find(byOrdinal(date.ordinal))
                        const weeklyPhysician = physicianList.data.find(byId(weekly?.relationships.physician.data.id))

                        const technical: any = technicals.filter(byDos(date.dos)).find(byOrdinal(date.ordinal))

                        return <tr key={index}>
                            {
                                index % 5
                                    ? null
                                    : <td rowSpan={5} style={{ width: "30px", textAlign: "center", backgroundColor: "white" }}>{index / 5 + 1}</td>
                            }
                            <td>{moment(date.dos).format("MM/DD/YYYY")}</td>
                            <ChargeCell charge={fraction} included={included} />
                            <ChargeCell charge={igrt} included={included} />
                            <td>{igrtPhysician?.attributes.code}</td>
                            <ChargeCell charge={weekly} included={included} />
                            <td>{weeklyPhysician?.attributes.code}</td>
                            <ChargeCell charge={technical} included={included} />
                        </tr>
                    })
            }
        </tbody>
    </Bootstrap.Table>
}

export default Fractions