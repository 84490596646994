import { PhasePayload, PhaseAction } from "../actions/PhaseActions"

export interface PhaseState {
    loading: boolean
    fetched: boolean

    filter: any

    data: any[]
    included: any[]
}

const reducer = (state: PhaseState, payload: PhasePayload): PhaseState => {
    const nextState = { ...state }

    switch (payload.type) {
        case PhaseAction.FETCH_PHASES_WAITING:
            nextState.loading = true
            nextState.filter = { ...payload.data.filter }
            break
        case PhaseAction.FETCH_PHASES_COMPLETED:
            nextState.loading = false
            nextState.fetched = true
            nextState.data = [...payload.data.response.data.data]

            if (payload.data.response.data.included) {
                nextState.included = [...payload.data.response.data.included]
            }
            break
    }

    return nextState
}

export default reducer