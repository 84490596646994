import { useEffect } from "react"

export const useEvent = (eventName: string, handler: (data: any) => void) => {
    useEffect(() => {
        const fn = (e: Event) => handler((e as CustomEvent).detail)

        window.addEventListener(eventName, fn)

        return () => {
            window.removeEventListener(eventName, fn)
        }
    }, [eventName, handler])
}

export const dispatchEvent = (eventName: string, detail: any) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }))
}
