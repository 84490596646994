import React, { FormEvent, useState, useRef } from "react"
import { Modal, FormGroup, FormLabel, Button, FormControl } from "react-bootstrap"
import { useEvent } from "../../hooks/useEvent"


const NewReferringPhysicianModal = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [isValid, setIsValid] = useState(false)
	const [err, setErr] = useState<string | null>(null)
	const formRef = useRef<HTMLFormElement>(null)

	useEvent("openNewReferringPhysicianModal", () => {
		setIsOpen(true)
	})

	const getFieldValue = (fieldName: string): string | undefined => {
		return formRef?.current?.querySelector<HTMLInputElement>(`input[name="${fieldName}"]`)?.value
	}

	const handleChangeField = () => {
		setErr(null)

		if (!getFieldValue("provider.name")) {
			setIsValid(false)
			return
		}

		if (!getFieldValue("provider.external_id")) {
			setIsValid(false)
			return
		}

		setIsValid(true)
	}

	const handleSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		const p = {
			name: getFieldValue("provider.name")
			, code: getFieldValue("provider.code")
			, external_id: Number(getFieldValue("provider.external_id"))
		}

		const resp = await fetch(`/v2/referring-providers`, {
			method: "POST"
			, headers: {
				"Content-Type": "application/json"
			}
			, body: JSON.stringify(p)
		})

		const json = await resp.json()

		if ("error" in json) {
			setErr(json)
			return
		}

		setIsOpen(false)
	}

	const handleCancel = () => {
		setIsOpen(false)
	}

	return <Modal show={isOpen} onHide={handleCancel} className="treatment-modal">
		<form onSubmit={handleSubmitForm} ref={formRef}>
			<Modal.Header>
				<Modal.Title>New Referring Physician</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					!err
						? null
						: <div className="alert alert-danger" role="alert">
							{err}
						</div>
				}
				<FormGroup>
					<FormLabel>Name</FormLabel>
					<FormControl type="text" name="provider.name" onChange={handleChangeField} />
				</FormGroup>
				<FormGroup>
					<FormLabel>Code</FormLabel>
					<FormControl type="text" name="provider.code" onChange={handleChangeField} />
				</FormGroup>
				<FormGroup>
					<FormLabel>External Id (NPI)</FormLabel>
					<FormControl type="number" name="provider.external_id" onChange={handleChangeField} />
				</FormGroup>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleCancel}>Cancel</Button>
				<Button type="submit" disabled={!isValid}>Save</Button>
			</Modal.Footer>
		</form>
	</Modal>
}

export default NewReferringPhysicianModal