import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { Form } from "react-bootstrap"
import Select from "react-select"
import { cloneDeep } from "lodash"
import { AppState } from "../../../reducers";
import ChargeContext from "../../../contexts/ChargeContext"

const Diagnosis = () => {
	const icd10s = useSelector((state: AppState) => state.icd10.data)
	const [charge, setCharge] = useContext(ChargeContext)

	const setDiagnosis = (items: any[]) => {
		const next = cloneDeep(charge)
		next.attributes.diagnosis = []

		if (items !== null) {
			next.attributes.diagnosis = items.map((item: any) => item.value).filter((value: string) => value != "")
		}

		setCharge(next)
	}

	return <Form.Group>
		<Form.Label>Diagnosis</Form.Label>
		<Select
			value={charge.attributes.diagnosis.map((dx: string) => {
				return { value: dx, label: dx }
			})}
			options={icd10s.map((icd10: any) => {
				return { value: icd10.attributes.code, label: icd10.attributes.code, desc: icd10.attributes.desc }
			})}
			formatOptionLabel={(option: any, labelMeta: any) => {
				if (labelMeta.context === "menu") {
					return <div>
						<div>{option.value}</div>
						<div style={{ fontSize: "smaller", color: "gray" }}>{option.desc}</div>
					</div>
				}

				return option.value
			}}
			onChange={setDiagnosis}
			isMulti />
	</Form.Group>
}

export default Diagnosis