import React, { Attributes, useContext } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Table, Nav, Button } from "react-bootstrap"
import moment from 'moment'
import { AppState } from "../reducers"
import * as modalActions from "../actions/ModalActions"
import ChargeCell from "./ChargeCell"

import { byId, byRelationshipId } from "../common/jsonapi-filters"
import TreatmentContext from "../contexts/TreatmentContext"
import ChargeListContext from "../contexts/ChargeListContext"

interface PhaseProps {
	phase: any
	showNewPhase?: boolean
}

const Phase = (props: PhaseProps & Attributes) => {
	const dispatch = useDispatch()
	const [treatment, included] = useContext(TreatmentContext)
	const [charges] = useContext(ChargeListContext)
	const physicianList = useSelector((state: AppState) => state.physician)

	const { phase, showNewPhase } = props

	const byPhase = (phaseId: number) => (item: any) => item.relationships.phase.data.id === phaseId
	const byCategoryIds = (categoryIds: number[], positive: boolean = true) => (item: any) => {
		return positive
			? categoryIds.indexOf(item.meta.category_id) !== -1
			: categoryIds.indexOf(item.meta.category_id) === -1
	}


	return <div>
		<Table bordered hover style={{ marginBottom: 0 }} className="charge-table">
			<thead>
				<tr>
					<th colSpan={6}>
						<div className="clearfix">
							<Nav.Link className="float-left" style={{ padding: 0, display: "inline" }} onClick={() => {
								dispatch(modalActions.open("EditPhase", { phase, treatment }))
							}}>{`${phase.attributes.name} (${phase.attributes.fractions} Fractions)`}</Nav.Link>
							{
								!showNewPhase
									? null
									: <Button size="sm" className="float-right" style={{ marginTop: "0.37rem" }} onClick={() => {
										dispatch(modalActions.open("EditPhase", { treatment }))
									}}>New Phase</Button>
							}

						</div>
					</th>
				</tr>
				<tr>
					<th>Abbr</th>
					<th>DOS</th>
					<th>CPT</th>
					<th>MD</th>
					<th>Units</th>
				</tr>
			</thead>
			<tbody>
				{
					charges
						.filter(byPhase(phase.id))
						.filter(byCategoryIds([4, 5, 10, 11, 12, 13], false))
						.filter(byRelationshipId("Status", 3, false))
						.sort((a: any, b: any): number => new Date(a.attributes.dos).getTime() - new Date(b.attributes.dos).getTime())
						.map((charge: any, index: number) => {
							const physician = physicianList.data.find(byId(charge.relationships.physician.data.id))

							return <tr key={index}>
								<td>{charge.attributes.cpt.abbr}</td>
								<td>{moment(charge.attributes.dos).format("MM/DD/YYYY")}</td>
								<ChargeCell charge={charge} included={included} />
								<td>{physician?.attributes?.code}</td>
								<td>{charge.attributes.units}</td>
							</tr>
						})
				}
			</tbody>
		</Table>
	</div>
}

export default Phase