import React from "react"
import { connect } from "react-redux"
import { Props as SelectProps } from "react-select"
import Select from "./Select"
import { AppState } from "../../reducers"

interface Props {
	onChangeFacility?: (facility: any) => void
	withLabel?: boolean
}

interface StateProps {
	facilities: any[]
}

const FacilitySelect = (props: Props & StateProps & SelectProps) => {
	const { facilities, onChangeFacility, withLabel, ...restProps } = props

	const handleChange = (facility: any) => {
		if (onChangeFacility) {
			onChangeFacility(facility)
		}
	}

	return <Select items={facilities} label={withLabel ? "Facility" : ""} onChange={handleChange} {...restProps} />
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => {
	return {
		facilities: state.facility.data
	}
}

export default connect(mapStateToProps)(FacilitySelect)