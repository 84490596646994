import { useState, useEffect, useCallback } from "react"
import { dispatchEvent } from "./hooks/useEvent"

export class Token {
    tokenString: string = ""

    constructor(tokenString: string) {
        if (tokenString && tokenString.split(".").length === 3) {
            this.tokenString = tokenString
        }
    }

    payload(): any | null {
        const segments = this.tokenString.split(".")

        if (segments.length !== 3) {
            return null
        }

        return JSON.parse(atob(segments[1]))
    }

    tenantId(): number {
        return Number(localStorage.getItem("tenant_id")!)
    }

    isValid(): boolean {
        if (this.tokenString === "") {
            return false
        }

        if (this.payload()?.exp - Math.floor(Date.now() / 1000) < 1) {
            return false
        }

        return true
    }
}

export const useTokens = () => {
    const getAccessToken = useCallback((): Token => {
        return new Token(localStorage.getItem("chargebook_access_token")!)
    }, [])

    const getRefreshToken = useCallback((): Token => {
        return new Token(localStorage.getItem("chargebook_refresh_token")!)
    }, [])

    return {
        getAccessToken
        , getRefreshToken
    }
}

interface AuthorizationCodeFlowOptions {
    clientId: string
    redirectUri: string

    authorizationUrl: string
    accessTokenUrl: string
    userInfoUrl: string
    logoutUrl: string
}

export const useAuthorizationCodeFlow = (options: AuthorizationCodeFlowOptions) => {
    const [initialized, setInitialized] = useState(false)
    const { getAccessToken, getRefreshToken } = useTokens()

    const logout = useCallback(() => {
        localStorage.removeItem("chargebook_access_token")
        localStorage.removeItem("chargebook_refresh_token")
        localStorage.removeItem("chargebook_last_activity")

        window.location.href = `${options.logoutUrl}?redirect_uri=${options.redirectUri}`
    }, [options])

    useEffect(() => {
        const doEffect = async () => {
            if (!options.authorizationUrl) {
                return
            }

            if (!getAccessToken().isValid()) {
                let queryString = new URLSearchParams(window.location.search);
                const code = queryString.get("code")
                const sessionState = queryString.get("session_state")

                if (code && sessionState) {
                    const res = await fetch(`/v2/auth/callback/${localStorage.getItem("client_id")!}?code=${code}&redirect_uri=${options.redirectUri}`)

                    if (res.status !== 200) {
                        logout()
                    }

                    const json = await res.json()

                    localStorage.setItem("chargebook_access_token", json.access_token)
                    localStorage.setItem("chargebook_refresh_token", json.refresh_token)
                } else {
                    window.location.href = `${options.authorizationUrl}?client_id=${options.clientId}&redirect_uri=${options.redirectUri}&response_type=code&scope=openid`
                }
            }

            const restLastActivity = () => {
                localStorage.setItem("chargebook_last_activity", Date.now().toString())
                dispatchEvent("clearTimeoutOverlay", {})
            }

            document.addEventListener("mousemove", restLastActivity, false);
            document.addEventListener("mousedown", restLastActivity, false);
            document.addEventListener("keypress", restLastActivity, false);
            document.addEventListener("touchmove", restLastActivity, false);

            window.setInterval(async () => {
                const lastActivityStr = localStorage.getItem("chargebook_last_activity")

                if (lastActivityStr) {
                    const inactive = (Date.now() - parseInt(lastActivityStr))

                    if (inactive > 840 * 1000) {
                        dispatchEvent("displayTimeoutOverlay", {})

                        if (inactive > 900 * 1000) {
                            logout()
                        }
                    } else {
                        dispatchEvent("clearTimeoutOverlay", {})
                    }
                }
            }, 1000)

            window.setInterval(async () => {
                console.log("refreshing tokens")

                const res = await fetch(`${options.accessTokenUrl}`, {
                    method: "post"
                    , headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                        , 'Accept': 'application/json'
                    }
                    , body: new URLSearchParams({
                        grant_type: "refresh_token"
                        , client_id: options.clientId
                        , refresh_token: getRefreshToken().tokenString
                    })
                })

                if (res.status !== 200) {
                    logout()
                }

                const json = await res.json()

                localStorage.setItem("chargebook_access_token", json.access_token)
                localStorage.setItem("chargebook_refresh_token", json.refresh_token)
            }, 300 * 1000)

            setInitialized(true)
        }

        doEffect()
    }, [])

    return {
        getAccessToken
        , logout
        , initialized
    }
}