import { ChargeMultiEditPayload, ChargeMultiEditAction } from "../actions/ChargeMultiEditActions"

export interface ChargeMultiEditState {
    chargeIds: number[]
}

const reducer = (state: ChargeMultiEditState, payload: ChargeMultiEditPayload): ChargeMultiEditState => {
    const nextState = { ...state }

    switch (payload.type) {
        case ChargeMultiEditAction.CHARGE_MULTI_EDIT_SELECT_ONE_CHARGE:
            if (nextState.chargeIds.indexOf(payload.chargeId!) === -1) {
                nextState.chargeIds.push(payload.chargeId!)
            }
            break
        case ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_ONE_CHARGE:
            nextState.chargeIds.splice(nextState.chargeIds.indexOf(payload.chargeId!), 1)
            break
        case ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_ALL:
            nextState.chargeIds = []
            break
    }

    return nextState
}

export default reducer