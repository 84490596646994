import { useState, useEffect } from "react"
import { Practice } from "./practice";

export interface Facility {
    id: number
    name: string
    code: string
    practice: Practice
}

export interface IFacilityRepo {
    listFacilities: (tenantId: number) => Promise<Facility[]>
}

export const useFacilityList = () => {
    const [facilities, setFacilities] = useState<Facility[]>([])
    const [err, setErr] = useState<string>("")

    useEffect(() => {
        const doFetch = async () => {
            setErr("")

            try {
                const resp = await fetch(`/v2/facilities`)
                const json = await resp.json()

                if ("error" in json) {
                    setErr(json.error)
                } else {
                    setFacilities(json)
                }
            } catch(e) {
                setErr("something went wrong")
            }
        }

        doFetch()
    }, [])

    return {
        facilities
        , err
    }
}

export const defaultFacility: Facility = {
    id: 0
    , name: ''
    , code: ''
    , practice: {
        id: 0
        , name: ''
        , code: ''
    }
}