import React, { useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import { useEvent } from "../../hooks/useEvent"
import PracticeDropdown from "../../components/PracticeDropdown"

const NewFacilityModal = () => {
    const [isOpen, setIsOpen] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)
    const [err, setErr] = useState("")

    useEvent("openNewFacilityModal", () => {
        setErr("")
        setIsOpen(true)
    })

    const handleCreateFacility = async () => {
        const name = formRef?.current?.querySelector<HTMLInputElement>(`input[name="facility.name"]`)?.value
        const code = formRef?.current?.querySelector<HTMLInputElement>(`input[name="facility.code"]`)?.value
        const practiceId = formRef?.current?.querySelector<HTMLInputElement>(`select[name="facility.practice"]`)?.value

        const facility = {
            name
            , code
            , practice: {
                id: Number(practiceId)
            }
        }

        const resp = await fetch(`/v2/facilities`, { method: "POST", body: JSON.stringify(facility) })
        const json = await resp.json()

        if ("error" in json) {
            setErr(json.error)
        } else {
            setIsOpen(false)
        }
    }

    return <Modal show={isOpen}>
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">New Facility</h5>
            </div>
            <div className="modal-body">
                {
                    err
                        ? <div className="alert alert-danger">{err}</div>
                        : null
                }
                <form ref={formRef}>
                    <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input type="text" className="form-control" name="facility.name" />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" name="facility.code" />
                    </div>

                    <div>
                        <label className="form-label">Practice</label>
                        <PracticeDropdown name="facility.practice" onChange={e => console.log(e.currentTarget.value)} />
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={() => { setIsOpen(false) }}>Cancel</button>
                <button className="btn btn-primary" onClick={() => { handleCreateFacility() }}>Create</button>
            </div>
        </div>
    </Modal>
}

export default NewFacilityModal