import { ChargeMultiEditModalPayload, ChargeMultiEditModalAction } from "../actions/ChargeMultiEditModalActions"

const defaultCharge: any = {
    relationships: {
        physician: { data: { id: null } }
        , insurance: { data: { id: null } }
        , facility: { data: { id: null } }
    }
}

export interface ChargeMultiEditModalState {
    show: boolean
    charge: any
    chargeIds: number[]
}

const reducer = (state: ChargeMultiEditModalState, payload: ChargeMultiEditModalPayload): ChargeMultiEditModalState => {
    let nextState = { ...state }

    switch (payload.type) {
        case ChargeMultiEditModalAction.OPENED:
            nextState.show = true
            nextState.chargeIds = [...payload.data.chargeIds]
            nextState.charge = JSON.parse(JSON.stringify(defaultCharge))
            break
        case ChargeMultiEditModalAction.CLOSED:
            nextState.show = false
            break


        case ChargeMultiEditModalAction.SET_FACILITY:
            nextState.charge.relationships.facility.data.id = payload.data.facilityId
            break
        case ChargeMultiEditModalAction.SET_INSURANCE:
            nextState.charge.relationships.insurance.data.id = payload.data.insuranceId
            break
        case ChargeMultiEditModalAction.SET_PHYSICIAN:
            nextState.charge.relationships.physician.data.id = payload.data.physicianId
            break
    }

    return nextState
}

export default reducer
