import React from "react"
import { connect } from "react-redux"
import { Props as SelectProps } from "react-select"
import Select from "./Select"
import { AppState } from "../../reducers"

interface Props {
	onChangeReferringPhysician?: (referringPhysician: any) => void
	withLabel?: boolean
}

interface StateProps {
	referringPhysicians: any[]
}

const ReferringPhysicianSelect = (props: Props & StateProps & SelectProps) => {
	const { referringPhysicians, onChangeReferringPhysician, withLabel, ...restProps } = props

	const handleChange = (referringPhysician: any) => {
		if (onChangeReferringPhysician){
			onChangeReferringPhysician(referringPhysician)
		}
	}

	return <Select items={referringPhysicians} label={withLabel ? "Referring Physician" : ""} onChange={handleChange} {...restProps} />
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => {
	return {
		referringPhysicians: state.referringPhysician.data
	}
}

export default connect(mapStateToProps)(ReferringPhysicianSelect)