import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { Form } from "react-bootstrap"
import Select from "react-select"
import { cloneDeep } from "lodash"
import { byId } from "../../../common/jsonapi-filters";
import { AppState } from "../../../reducers";
import ChargeContext from "../../../contexts/ChargeContext"

const Facility = () => {
	const facilities = useSelector((state: AppState) => state.facility.data)
	const [charge, setCharge] = useContext(ChargeContext)

	const setFacility = (facility: any) => {
		const next = cloneDeep(charge)
		next.relationships.facility.data.id = facility.id
		setCharge(next)
	}

	return <Form.Group>
		<Form.Label>Facility</Form.Label>
		{
			<Select
				value={facilities.find(byId(charge.relationships.facility.data.id))}

				onChange={setFacility}

				options={facilities}

				getOptionLabel={(option: any) => option.attributes.name}
				getOptionValue={(option: any) => option.id}
			/>
		}
	</Form.Group>
}

export default Facility