import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { Form } from "react-bootstrap"
import Select from "react-select"
import { cloneDeep } from "lodash"
import { byId } from "../../../common/jsonapi-filters";
import { AppState } from "../../../reducers";
import ChargeContext from "../../../contexts/ChargeContext"


const SelectStatus = () => {
	const chargeStatuses = useSelector((state: AppState) => state.chargeStatus.data)
	const [charge, setCharge] = useContext(ChargeContext)

	const statusId = () => {
		const defaultId = 0
		const foundId = chargeStatuses.find(byId(charge.relationships.status.data.id))

		if (foundId) {
			return foundId
		}

		return defaultId
	}

	const setStatus = (status: any) => {
		const next = cloneDeep(charge)
		next.relationships.status.data.id = status.id
		setCharge(next)
	}

	return <Form.Group>
		<Form.Label>Status</Form.Label>
		{
			<Select name="form-field-name"
				tabIndex={"6"}
				value={statusId()}
				onChange={setStatus}
				options={[{ id: 0, attributes: { name: "Default" } }, { id: 1, attributes: { name: "Held" } }, { id: 2, attributes: { name: "Completed" } },{ id: 3, attributes: { name: "Deleted" } }]}
				getOptionLabel={(option: any) => option.attributes.name}
				getOptionValue={(option: any) => option.id}
			/>
		}
	</Form.Group>
}

export default SelectStatus