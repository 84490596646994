import Axios from "../common/axios"

enum Type {
    FETCH_PRACTICE_WAITING = "FETCH_PRACTICE_WAITING"
    , FETCH_PRACTICE_COMPLETED = "FETCH_PRACTICE_COMPLETED"
    , FETCH_PRACTICE_ERROR = "FETCH_PRACTICE_ERROR"
}

interface Action {
    type: Type
    payload: any
}

export const fetch = (tenantId: number) => async (dispatch: (payload: Action) => Action, getState: () => any) => {
    const state = getState().practice

    if (state.loading || state.fetched) {
        return
    }

    const uri = `/api/${tenantId}/practice`
    const cache = sessionStorage.getItem(uri)

    if (cache) {
        return dispatch({ type: Type.FETCH_PRACTICE_COMPLETED, payload: { data: JSON.parse(cache) } })
    }

    dispatch({ type: Type.FETCH_PRACTICE_WAITING, payload: {} })

    try {
        const { data, request } = await Axios.get(uri)

        if (request.responseURL.endsWith("/login")) {
            throw new Error("redirected to /login")
        }

        sessionStorage.setItem(uri, JSON.stringify(data))
        dispatch({ type: Type.FETCH_PRACTICE_COMPLETED, payload: { data } })
    } catch (error) {
        dispatch({ type: Type.FETCH_PRACTICE_ERROR, payload: { error } })
    }
}
export interface PracticeState {
    loading: boolean
    fetched: boolean

    data: any[]
    included: any[]
}

const reducer = (prevState: PracticeState, action: Action): PracticeState => {
    const state = { ...prevState }

    switch (action.type) {
        case Type.FETCH_PRACTICE_WAITING:
            state.loading = true
            break
        case Type.FETCH_PRACTICE_COMPLETED:
            state.loading = false
            state.fetched = true

            const { data, included } = action.payload.data

            state.data = [...data]

            if (included) {
                state.included = [...included]
            }
            break
    }

    return state
}

export default reducer