import React from "react"
import { useData, useCustomData } from "../hooks/useData"

function withData(url: string, mapFn?: (item: any) => any) {
    return function <T>(Component: React.ComponentType<T>) {
        return (props: T) => {
            const { data } = useData(url, mapFn)

            return <Component {...props} data={data} />
        }
    }
}

export function withCustomData(url: Promise<string>, mapFn?: (item: any) => any) {
    return function <T>(Component: React.ComponentType<T>) {
        return (props: T) => {
            const { data } = useCustomData(url, mapFn)

            return <Component {...props} data={data} />
        }
    }
}

export default withData