import Axios from "../common/axios"

enum Type {
    WAITING = "FETCH_CPT_MODIFIERS_WAITING"
    , COMPLETED = "FETCH_CPT_MODIFIERS_COMPLETED"
    , ERROR = "FETCH_CPT_MODIFIERS_ERROR"
}

interface Action {
    type: Type
    payload: any
}

export const fetch = () => {
    return async (dispatch: (payload: Action) => Action, getState: () => any) => {
        const state = getState().cptModifier

        if (state.loading || state.fetched) {
            return
        }

        const uri = "/api/cpt-modifier"
        const cache = sessionStorage.getItem(uri)

        if (cache) {
            return dispatch({ type: Type.COMPLETED, payload: { data: JSON.parse(cache) } })
        }

        dispatch({ type: Type.WAITING, payload: {} })

        try {
            const { data, request } = await Axios.get(uri)

            if (request.responseURL.endsWith("/login")) {
                throw new Error("redirected to /login")
            }

            sessionStorage.setItem(uri, JSON.stringify(data))

            dispatch({ type: Type.COMPLETED, payload: { data } })
        } catch (error) {
            dispatch({ type: Type.ERROR, payload: { error } })
        }
    }
}

export interface CPTModifierState {
    loading: boolean
    fetched: boolean

    data: any[]
    included: any[]
}

const reducer = (prevState: CPTModifierState, action: Action): CPTModifierState => {
    const state = { ...prevState }

    switch (action.type) {
        case Type.WAITING:
            state.loading = true
            break
        case Type.COMPLETED:
            state.loading = false
            state.fetched = true

            const { data, included } = action.payload.data

            state.data = [...data]

            if (included) {
                state.included = [...included]
            }
            break
    }

    return state
}

export default reducer
