import React, { useEffect } from "react"
import { connect, useDispatch } from 'react-redux'
import { Tab, Row, Col, Nav, Button, NavItem, NavLink } from "react-bootstrap"
import DocumentHTML from "./DocumentHTML"
import { useTokens } from "../useAuthorizationCodeFlow"

import DocumentEditModal from "./modal/DocumentEditModal"
import DocumentSetStatusModal from "./modal/DocumentSetStatusModal"

import { getTenantId } from "../common/jwt"
import actions from "../actions"

interface DocumentNavProps {
    patientId: number
}

interface DocumentNavStateProps {
    documents: any[]
    versions: any[]

    charges: any[]
    chargeIncluded: any[]
    facilityList: any
}

const DocumentNav = (props: DocumentNavProps & DocumentNavStateProps) => {
    const { patientId, documents, versions, charges, chargeIncluded, facilityList } = props
    const { getAccessToken } = useTokens()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions().document.fetch(getTenantId(), { patient_id: patientId }))
    }, [dispatch, patientId, getAccessToken])

    const showSetStatusModal = (documentId: number) => {
        dispatch(actions().documentSetStatusModal.open(documentId))
    }

    return <div>
        <DocumentEditModal />
        <DocumentSetStatusModal />
        <Tab.Container id="left-tabs-example">
            <Row className="clearfix">
                <Col sm={2}>
                    <Nav variant="pills" style={{ padding: "15px 0 0 15px" }}>
                        {
                            documents.map((document: any) => {
                                return <NavItem key={document.id}><NavLink eventKey={document.id}>{document.attributes.externaludn}</NavLink></NavItem>
                            })
                        }
                    </Nav>
                </Col>
                <Col sm={10} style={{ backgroundColor: "white", paddingLeft: 0 }}>
                    <Tab.Content>
                        {
                            documents.map((document: any) => {
                                let versionIds = document.relationships.documentversion.data.map((data: any) => data.id)

                                let version = (versions.filter((version: any) => versionIds.indexOf(version.id) !== -1).sort((a: any, b: any) => b.id - a.id))[0]
                                const facility = facilityList.data.find((facility: any) => {
                                    return facility.id === document.relationships.facility.data.id
                                })

                                return <Tab.Pane key={document.id} eventKey={document.id}>
                                    <div style={{ padding: "15px", backgroundColor: "#cacaca" }}>
                                        <p style={{ margin: 0 }}>
                                            <span style={{ marginRight: "10px" }}><strong>Attending: </strong>{version.meta.attending_physician_code}</span>
                                            <span style={{ marginRight: "10px" }}><strong>Signing: </strong>{version.meta.signing_physician_code}</span>
                                            <span style={{ marginRight: "10px" }}><strong>Facility: </strong>{facility.attributes.code}</span>
                                            <span style={{ marginRight: "10px" }}><strong>External Edit Date: </strong>{version.attributes.editdate}</span>
                                            <span><strong>External Status: </strong>{version.meta.status_code}</span>
                                            {/* <Button style={{float: "right", marginRight: "15px", marginTop: "-5px"}} size="sm" onClick={() => {
                                            showEditModal(document.id)
                                        }}>Edit</Button> */}

                                            <Button style={{ float: "right", marginRight: "15px", marginTop: "-5px" }} size="sm" onClick={() => {
                                                showSetStatusModal(document.id)
                                            }}>Set Status</Button>
                                        </p>
                                    </div>

                                    <DocumentHTML documentId={document.id} charges={charges} chargeIncluded={chargeIncluded} />
                                </Tab.Pane>
                            })
                        }
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    </div>
}

const mapStateToProps = (state: any, ownProps: DocumentNavProps): DocumentNavStateProps => {
    return {
        documents: state.document.data
        , versions: state.document.included.filter((included: any) => {
            return included.type === "DocumentVersion"
        })

        , charges: state.charge.data
        , chargeIncluded: state.charge.included

        , facilityList: state.facility
        , ...ownProps
    }
}

export default connect(mapStateToProps)(DocumentNav)