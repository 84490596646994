import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Alert, Tab, Tabs, Button, Nav } from "react-bootstrap";
import {
	BsFillExclamationTriangleFill,
	BsFillExclamationOctagonFill,
	BsFillExclamationCircleFill,
} from "react-icons/bs";

import Navbar from "./components/Navbar";
import Fractions from "./components/Fractions";
import Phase from "./components/Phase";
import MIPS from "./components/MIPS";

import ChargeModal from "./components/modal/ChargeModal";
import ChargeMultiEditModal from "./components/modal/ChargeMultiEditModal";

import EditPatientModal from "./components/modal/EditPatientModal";
import EditPhaseModal from "./components/modal/EditPhaseModal";
import EditTreatmentModal from "./components/modal/EditTreatmentModal";
import EditAdmissionsModal from "./components/modal/EditAdmissionsModal";

import * as treatmentActions from "./actions/TreatmentActions";
import * as chargeActions from "./actions/ChargeActions";
import * as modalActions from "./actions/ModalActions";

import DocumentNav from "./components/DocumentNav";

import "./components/TreatmentDetails";
import TreatmentDetails from "./components/TreatmentDetails";
import PreauthList from "./components/preauth/PreauthList";

import { dismiss } from "./actions/AlertActions";
import { open } from "./actions/ModalActions";

import { byType, byId } from "./common/jsonapi-filters";
import { AppState } from "./reducers";
import NewChargeModal from "./components/modal/NewChargeModal";
import NewPreauthModal from "./components/modal/NewPreauthModal";
import TreatmentContext from "./contexts/TreatmentContext";
import Axios from "./common/axios";
import AstriaContext from "./contexts/AstriaContext";
import ChargeListContext from "./contexts/ChargeListContext";
import { useTokens } from "./useAuthorizationCodeFlow"
import { getTenantId } from "./common/jwt"
import { dispatchEvent } from "./hooks/useEvent";
import { Patient } from "./core/entities/patient";
import { fromLegacyTreatment } from "./core/entities/treatment";

const either = (lh: any, rh: any) => (lh ? lh : rh);

const useTreatment = (treatmentId: number) => {
	const eventTimes = useSelector((state: AppState) => state.eventTimes);
	const { getAccessToken } = useTokens()

	const [treatment, setTreatment] = useState({} as any);
	const [included, setIncluded] = useState([] as any[]);

	useEffect(() => {
		const fetchTreatment = async () => {
			try {
				const { status, data } = await treatmentActions.fetchRaw(getTenantId(), {
					id: treatmentId,
				});

				if (status === 200) {
					setTreatment(data.data[0]);
					setIncluded(data.included);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchTreatment();
	}, [treatmentId, eventTimes.patient.length, eventTimes.treatment.length, getAccessToken]);

	return [treatment, included];
};

const useAstria = (
	patient: any,
	treatment: any,
	charges: any[],
	deps: React.DependencyList
) => {
	const { getAccessToken } = useTokens()

	const {
		eventTimes,
		facility: facilityList,
		insurance: insuranceList,
		physician: physicianList,
		referringPhysician: referringPhysicianList,
		cptModifier: cptModifierList,
	} = useSelector((state: AppState) => state);

	const [errors, setErrors] = useState([] as any);
	const [waitingForErrors, setWaitingForErrors] = useState(false);

	useEffect(() => {
		const checkRules = async () => {
			setWaitingForErrors(true);

			const modifiers = (charge: any, cptModifierList: any): string[] => {
				const chargeMod = (mod: string) => {
					return cptModifierList.data.find(
						byId(charge.relationships.modifiers[mod].data?.id)
					)?.attributes.name;
				};

				const cptMod = (mod: string) => {
					return charge.attributes.cpt[mod];
				};

				return [
					either(chargeMod("mod1"), cptMod("mod1")),
					either(chargeMod("mod2"), cptMod("mod2")),
					either(chargeMod("mod3"), cptMod("mod3")),
					either(chargeMod("mod4"), cptMod("mod4")),
				].filter((mod: string) => (mod ? true : false));
			};

			const c = charges.map((charge: any) => {
				const getStatusName = (id: number): string => {
					switch (id) {
						case 1:
							return "Held"
						case 2:
							return "Completed"
						default:
							return "Deleted"
					}
				}

				return {
					id: charge.id,
					dos: `${charge.attributes.dos}:${charge.attributes.ordinal}`,
					pos: "",
					units: Number(charge.attributes.units),
					code: charge.attributes.code,
					mod: modifiers(charge, cptModifierList),
					dx: charge.attributes.diagnosis,
					status: {
						name: getStatusName(charge.relationships.status.data.id)
					},
					facility: {
						name: facilityList.data.find(
							byId(charge.relationships.facility.data.id)
						)?.attributes.name,
						code: facilityList.data.find(
							byId(charge.relationships.facility.data.id)
						)?.attributes.code,
					},
					provider: {
						name: physicianList.data.find(
							byId(charge.relationships.physician.data.id)
						)?.attributes.name,
						code: physicianList.data.find(
							byId(charge.relationships.physician.data.id)
						)?.attributes.code,
					},
					supervisingProvider: {
						name: physicianList.data.find(
							byId(charge.relationships.supervisingphysician.data.id)
						)?.attributes.name,
						code: physicianList.data.find(
							byId(charge.relationships.supervisingphysician.data.id)
						)?.attributes.code,
					},
					referringProvider: {
						name: referringPhysicianList.data.find(
							byId(treatment.relationships?.referringphysician.data.id)
						)?.attributes.name,
						code: referringPhysicianList.data.find(
							byId(treatment.relationships?.referringphysician.data.id)
						)?.attributes.code,
					},
					practice: {
						name: "",
						code: "",
					},
					payer: {
						carrier: insuranceList.data.find(
							byId(charge.relationships.insurance.data.id)
						)?.attributes.name,
						plan: "",
						code: insuranceList.data.find(
							byId(charge.relationships.insurance.data.id)
						)?.attributes.code,
					},
					patient: {
						gender: patient?.attributes.gender,
					},
				};
			});

			const { status, data } = await Axios.post(
				`/remote-astria/api/${localStorage.getItem("tenant_id")!}/adjudicate`,
				c
			);

			if (status === 200) {
				setErrors(data);
			}

			setWaitingForErrors(false);
		};

		if (
			patient?.id &&
			treatment?.id &&
			charges?.length &&
			facilityList.fetched &&
			physicianList.fetched &&
			referringPhysicianList.fetched &&
			insuranceList.fetched &&
			cptModifierList.fetched
		) {
			checkRules();
		}
	}, [
		eventTimes.patient.length,
		eventTimes.treatment.length,
		eventTimes.charge.length,
		facilityList,
		physicianList,
		referringPhysicianList,
		insuranceList,
		cptModifierList,
		charges,
		patient,
		treatment,
		getAccessToken
	]);

	return [errors, waitingForErrors];
};

const useCharges = (where: any) => {
	const eventTimes = useSelector((state: AppState) => state.eventTimes);
	const [charges, setCharges] = useState([] as any[]);
	const { getAccessToken } = useTokens()

	useEffect(() => {
		const fetchCharges = async () => {
			try {
				const { status, data } = await chargeActions.fetchRaw(getTenantId(), where);

				if (status === 200) {
					setCharges(data.data);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchCharges();
	}, [where, eventTimes.charge.length, getAccessToken]);

	return [charges];
};

interface PatientDetailsProps {
	patient: any;
}

const PatientDetails = (props: PatientDetailsProps) => {
	const { patient } = props;

	const handleOpenModal = () => {
		const toNormalPatient = (p: any): Patient => {
			return {
				id: p.id
				, firstName: p.attributes.firstName
				, lastName: p.attributes.lastName
				, middleInitial: p.attributes.middleInitial
				, gender: p.attributes.gender
				, mrn: p.attributes.mrn
				, acct: p.attributes.acct
				, dob: p.attributes.dob
			}
		}

		dispatchEvent("openEditPatientModal", { patient: toNormalPatient(patient) })
	};

	return !patient ? (
		<></>
	) : (
		<>
			<h4 style={{ marginTop: 0 }}>
				<Nav.Link
					style={{ padding: 0, display: "inline" }}
					onClick={handleOpenModal}
				>
					{patient.attributes.lastName}, {patient.attributes.firstName}
				</Nav.Link>
			</h4>
			<p>
				<span style={{ marginRight: "10px" }}>
					<strong>DOB: </strong>
					{patient.attributes.dob}
				</span>
				<span style={{ marginRight: "10px" }}>
					<strong>MRN: </strong>
					{patient.attributes.mrn}
				</span>
				{
					patient.attributes.acct
						? <span style={{ marginRight: "10px" }}>
							<strong>Acct: </strong>
							{patient.attributes.acct}
						</span>
						: null
				}
				<span>
					<strong>Gender: </strong>
					{patient.attributes.gender}
				</span>
			</p>
		</>
	);
};

const AstriaStatusBar = () => {
	const [errors, waiting] = useContext(AstriaContext);

	const divStyle: CSSProperties = {
		float: "left",
		marginTop: "13px",
		marginLeft: "15px",
	};
	const counterStyle: CSSProperties = { marginLeft: "5px" };
	const iconStyle: CSSProperties = { marginTop: "4px" };

	return (
		<div
			style={{
				height: "50px",
				marginLeft: "-15px",
				marginRight: "-15px",
				borderBottom: "solid 1px #d2d2d2",
			}}
		>
			{waiting ? (
				<span style={{ lineHeight: "50px", marginLeft: "15px" }}>
					Loading...
				</span>
			) : (
				<>
					<div style={divStyle}>
						<BsFillExclamationOctagonFill
							style={{ ...iconStyle, color: "rgb(229, 67, 67)" }}
						/>
						<span style={counterStyle}>
							{errors.filter((e: any) => e.type === "Error").length}
						</span>
					</div>

					<div style={divStyle}>
						<BsFillExclamationTriangleFill
							style={{ ...iconStyle, color: "rgb(241, 180, 0)" }}
						/>
						<span style={counterStyle}>
							{errors.filter((e: any) => e.type === "Warning").length}
						</span>
					</div>

					<div style={divStyle}>
						<BsFillExclamationCircleFill
							style={{ ...iconStyle, color: "rgb(22, 135, 255)" }}
						/>
						<span style={counterStyle}>
							{errors.filter((e: any) => e.type === "Notice").length}
						</span>
					</div>
				</>
			)}
		</div>
	);
};

interface Identifiable {
	id: number;
}

const PhaseList = () => {
	const [, included] = useContext(TreatmentContext);

	const phases = included
		.filter(byType("Phase"))
		.sort((a: Identifiable, b: Identifiable) => a.id - b.id);

	return phases.map((phase: any, i: number) => (
		<Phase key={phase.id} phase={phase} showNewPhase={i === 0} />
	));
};

export interface TreatmentProps {
	treatmentId: number;
}

const Treatment = (props: TreatmentProps) => {
	const dispatch = useDispatch();

	const [where] = useState({ treatment_id: props.treatmentId });

	const [treatment, included] = useTreatment(props.treatmentId);
	const [charges] = useCharges(where);

	const patient = included.find(byType("Patient"));
	const [errors, waitingForErrors] = useAstria(patient, treatment, charges, []);

	if (!patient) {
		return <p>No Patient</p>
	}

	if (!treatment) {
		return <p>No Treatment</p>
	}

	const handleOpenEditTreatmentModal = () => {
		dispatchEvent("openEditTreatmentModal", { treatment: fromLegacyTreatment(treatment) })
	}

	return (
		<>
			<Navbar />
			<div className="patient-bar">
				<Row>
					<Col md={6}>
						<PatientDetails patient={patient} />
					</Col>

					<Col>
						<Button
							style={{ float: "right", marginTop: 0 }}
							onClick={(e: any) => {
								dispatch(
									open("NewCharge", {
										treatment,
										charge: defaultCharge(treatment),
										phases: included!.filter(byType("Phase")),
										admissions: included!.filter(byType("Admission")),
									})
								);
							}}
						>
							New Charge
						</Button>

						<Button
							style={{ float: "right", marginTop: 0, marginRight: "10px" }}
							onClick={(e: any) => {
								dispatch(open("NewPreauth", null));
							}}
						>
							New Preauth
						</Button>

						<Button style={{ float: "right", marginTop: 0, marginRight: "10px" }} onClick={handleOpenEditTreatmentModal}>
							Edit
						</Button>
					</Col>
				</Row>
			</div>

			<div className="treatment">
				<TreatmentContext.Provider value={[treatment, included]}>
					<Col style={{ marginBottom: "100px" }}>
						<div style={{ paddingTop: "15px" }}>
							<ChargeModal />
							<NewChargeModal />
							<NewPreauthModal />

							<ChargeMultiEditModal />
							<EditPatientModal />
							<EditPhaseModal />
							<EditTreatmentModal />
							<EditAdmissionsModal />

							<Row style={{ padding: "0 15px" }}>
								<Col md={12}>
									<Alerts alerts={included.filter(byType("Alert"))} />
								</Col>
							</Row>
						</div>

						<ChargeListContext.Provider value={[charges]}>
							<Tabs
								id="treatment-tabs"
								defaultActiveKey="charges"
								variant="pills"
							>
								<Tab eventKey="charges" title="Charges">
									<AstriaContext.Provider value={[errors, waitingForErrors]}>
										<AstriaStatusBar />
										<Row>
											<Col md={4} style={{ padding: "0" }}>
												<PhaseList />
												<MIPS />
											</Col>
											<Col md={6} style={{ padding: "0" }}>
												<Fractions />
											</Col>
											<Col md={2}>
												<TreatmentDetails />
											</Col>
										</Row>
									</AstriaContext.Provider>
								</Tab>
								<Tab eventKey="documents" title="Documents">
									<DocumentNav patientId={patient.id} />
								</Tab>
								<Tab eventKey="preauths" title="Preauths">
									<PreauthList treatment={treatment} />
								</Tab>
							</Tabs>
						</ChargeListContext.Provider>
					</Col>
				</TreatmentContext.Provider>
			</div>
		</>
	);
};

export default Treatment;

interface AlertsProps {
	alerts: any[];
}

const Alerts = (props: AlertsProps) => {
	const dispatch = useDispatch();

	const { alerts } = props;

	return (
		<>
			{alerts
				.filter((alert: any) => alert.attributes.dismissed === false)
				.map((alert: any) => {
					return (
						<Alert
							key={alert.id}
							variant="danger"
							onClose={() => dispatch(dismiss(alert))}
						>
							{alert.attributes.message}
						</Alert>
					);
				})}
		</>
	);
};

const defaultCharge = (treatment: any) => {
	return {
		id: null,
		attributes: {
			units: 1,
			note: "",
			dos: null,
			ordinal: 1,
			flags: 0,
			diagnosis: [...treatment.attributes.diagnosis],
		},
		relationships: {
			phase: { data: { id: treatment.relationships.phase.data[0].id } },
			cpt: { data: { id: null } },
			modifiers: {
				mod1: { data: { id: null } },
				mod2: { data: { id: null } },
				mod3: { data: { id: null } },
				mod4: { data: { id: null } },
			},
			physician: { data: { id: 1 } },
			supervisingphysician: { data: { id: 1 } },
			insurance: { data: { id: treatment.relationships.insurance.data.id } },
			facility: { data: { id: treatment.relationships.facility.data.id } },
			practice: { data: { id: null } },
			document: { data: { id: null } },
			status: { data: { id: 0 } },
			diagnosis: {
				data: [{ id: null, icd10_id: null }],
			},
			mipsreport: {
				data: [{ id: null, measure_id: null, numerator_id: null }],
			},
		},
		dos: [{ date: null, ordinal: 1, otv: false }],
	};
};

