import Axios from "../common/axios";

export enum DocumentAction {
    FETCH_WAITING = "DocumentAction.FETCH_WAITING"
    , FETCH_COMPLETED = "DocumentAction.FETCH_COMPLETED"
    , FETCH_ERROR = "DocumentAction.FETCH_ERROR"

    , FETCH_MORE_WAITING = "DocumentAction.FETCH_MORE_WAITING"
    , FETCH_MORE_COMPLETED = "DocumentAction.FETCH_MORE_COMPLETED"
    , FETCH_MORE_ERROR = "DocumentAction.FETCH_MORE_ERROR"

    , SET_STATUS_WAITING = "DocumentAction.SET_STATUS_WAITING"
    , SET_STATUS_COMPLETED = "DocumentAction.SET_STATUS_COMPLETED"
    , SET_STATUS_ERROR = "DocumentAction.SET_STATUS_ERROR"

    , SET_ASSIGNEE_WAITING = "DocumentAction.SET_ASSIGNEE_WAITING"
    , SET_ASSIGNEE_COMPLETED = "DocumentAction.SET_ASSIGNEE_COMPLETED"
    , SET_ASSIGNEE_ERROR = "DocumentAction.SET_ASSIGNEE_ERROR"

    , UPDATE_WAITING = "DocumentAction.UPDATE_WAITING"
    , UPDATE_COMPLETED = "DocumentAction.UPDATE_COMPLETED"
    , UPDATE_ERROR = "DocumentAction.UPDATE_ERROR"

    , SELECT = "DocumentAction.SELECT"
}

export interface DocumentPayload {
    type: DocumentAction
    , filter?: any
    , response?: any
    , data?: any
}

export class DocumentActions {
    waiting(filter: any): DocumentPayload {
        return { type: DocumentAction.FETCH_WAITING, filter: filter };
    }

    completed(response: any): DocumentPayload {
        return { type: DocumentAction.FETCH_COMPLETED, response: response };
    };

    error(response: any): DocumentPayload {
        return { type: DocumentAction.FETCH_ERROR, response: response };
    }

    select(documentId: number) {
        return { type: DocumentAction.SELECT, response: { selectedId: documentId } };
    }

    fetch(tenantId: number, filter: any, limit: number = 0, offset: number = 0) {
        return (dispatch: (payload: any) => any) => {
            dispatch(this.waiting(filter));

            return Axios.get(`/api/${tenantId}/document`, {
                params: {
                    filter: filter
                    , limit: limit
                    , offset: offset
                }
            })
                .then((r: any) => {
                    dispatch(this.completed(r));
                });
        };
    }

    fetchMore(tenantId: number) {
        return (dispatch: (payload: any) => any, getState: () => any) => {
            const state = getState().document;

            if ((state.pagination.limit * 1) * ((state.pagination.offset * 1) + 1) >= (state.pagination.count * 1)) {
                return;
            }

            dispatch({ type: DocumentAction.FETCH_MORE_WAITING, data: null });

            return Axios.get(`/api/${tenantId}/document`, {
                params: {
                    filter: state.filter
                    , limit: state.pagination.limit
                    , offset: state.pagination.offset + state.pagination.limit
                }
            })
                .then((response: any) => {
                    dispatch({ type: DocumentAction.FETCH_MORE_COMPLETED, data: { response: response.data } });
                })
                .catch((exception: any) => {
                    dispatch({ type: DocumentAction.FETCH_MORE_ERROR, data: { error: exception } });
                });
        };
    }

    update(tenantId: number, document: any) {
        return (dispatch: (payload: DocumentPayload) => DocumentPayload) => {
            dispatch({ type: DocumentAction.UPDATE_WAITING });

            return Axios({
                method: 'post'
                , url: `/api/${tenantId}/document/` + document.id
                , data: JSON.stringify(document)
            })
                .then((r: any) => {
                    dispatch({ type: DocumentAction.UPDATE_COMPLETED, response: r });
                })
                .catch((e: any) => {
                    console.error(e);
                });
        }
    }

    setStatus(tenantId: number, documentId: number, statusId: number, reason: string, dispatchOnComplete: any[] = [], dispatchOnError: any[] = []) {
        return this.setStatusMulti(tenantId, [documentId], statusId, reason, dispatchOnComplete, dispatchOnError);
    }

    setStatusMulti(tenantId: number, documentIds: number[], statusId: number, reason: string, dispatchOnComplete: any[] = [], dispatchOnError: any[] = []) {
        const data: any = {
            type: "ChargeStatus"
            , id: statusId
        };

        return (dispatch: (payload: DocumentPayload) => DocumentPayload) => {
            dispatch({ type: DocumentAction.SET_STATUS_WAITING });

            return Axios({
                method: 'post'
                , url: `/api/${tenantId}/document/` + documentIds.join(",") + '/relationships/status'
                , data: JSON.stringify({ data: data })
            })
                .then((r: any) => {
                    dispatch({ type: DocumentAction.SET_STATUS_COMPLETED, data: { documentIds: documentIds, statusId: statusId } });

                    for (let action of dispatchOnComplete) {
                        dispatch(action);
                    }
                })
                .catch((e: any) => {
                    console.error(e);

                    for (let action of dispatchOnError) {
                        dispatch(action);
                    }
                });
        };
    }
}

