import React, { FormEvent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { ValueType, ActionMeta } from "react-select"
import { updated } from "../../actions/EventTimeActions"


import AsyncSelect from "../formcontrol/AsyncSelect"
import Select from "react-select"

import { Treatment } from "../../core/entities/treatment"
import { useEvent } from "../../hooks/useEvent"
import { isArray } from "lodash"

interface Option {
	label: string
	value: string
}

const EditTreatmentModal = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [isWaiting, setIsWaiting] = useState(false)
	const [treatment, setTreatment] = useState<Treatment | undefined>()
	const [dx, setDx] = useState([] as any[])

	const dispatch = useDispatch()

	// TODO: this should be in the dx component
	useEffect(() => {
		const fetchDx = async () => {
			const resp = await fetch(`/v2/dx`)
			const json = await resp.json()

			setDx(json)
		}

		fetchDx()
	}, [])

	useEvent("openEditTreatmentModal", ({ treatment }) => {
		setIsOpen(true)
		setTreatment(treatment)
	})

	const cancel = () => {
		setIsOpen(false)
	}

	const handleSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		setIsWaiting(true)

		const resp = await fetch(`/v2/treatments`, {
			method: "PUT"
			, headers: {
				'Content-Type': 'application/json'
			}
			, body: JSON.stringify(treatment)
		})

		dispatch(updated("treatment"))
		setIsWaiting(false)
		setIsOpen(false)
	}

	const handleChangeModality = (option: ValueType<Option>) => {
		const modality = { ...treatment!.modality, id: Number((option as Option).value) }
		setTreatment({ ...treatment!, modality })
	}

	const handleChangePayor = (option: ValueType<Option>) => {
		const payor = { ...treatment!.payor, id: Number((option as Option).value) }
		setTreatment({ ...treatment!, payor })
	}

	const handleChangeFacility = (option: ValueType<Option>) => {
		const facility = { ...treatment!.facility, id: Number((option as Option).value) }
		setTreatment({ ...treatment!, facility })
	}

	const handleChangeReferringProvider = (option: ValueType<Option>) => {
		const referringProvider = { ...treatment!.referringProvider, id: Number((option as Option).value) }
		setTreatment({ ...treatment!, referringProvider })
	}

	const handleChangeAssignee = (option: ValueType<Option>) => {
		const assignee = { ...treatment!.assignee, id: Number((option as Option).value) }
		setTreatment({ ...treatment!, assignee })
	}

	const handleChangeStatus = (option: ValueType<Option>) => {
		const status = { ...treatment!.status, id: Number((option as Option).value) }
		setTreatment({ ...treatment!, status })
	}

	const handlChangeAuthNumber = (e: FormEvent<HTMLInputElement>) => {
		setTreatment({ ...treatment!, payorAuthNumber: e.currentTarget.value })
	}

	const handleChangeNote = (e: FormEvent<HTMLInputElement>) => {
		setTreatment({ ...treatment!, note: e.currentTarget.value })
	}

	const handleChangeDiagnosis = (options: ValueType<Option>, meta: ActionMeta) => {
		let dx: string[] = []

		if (isArray(options)) {
			dx = options.map((option: Option) => option.value)
		}

		setTreatment({ ...treatment!, ...{ dx } })
	}


	return <Modal show={isOpen} onHide={cancel}>
		<Form onSubmit={handleSubmitForm}>
			<Modal.Header>
				<Modal.Title>Edit Treatment</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col md={6}>
						<Form.Group>
							<Form.Label>Modality</Form.Label>
							<AsyncSelect url="/v2/modalities" mapFn={mapNameAndId} onChange={handleChangeModality} optionValue={treatment?.modality.id} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Insurance</Form.Label>
							<AsyncSelect url="/v2/payors" mapFn={mapNameAndId} onChange={handleChangePayor} optionValue={treatment?.payor.id} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Facility</Form.Label>
							<AsyncSelect url="/v2/facilities" mapFn={mapNameAndId} onChange={handleChangeFacility} optionValue={treatment?.facility.id} />
						</Form.Group>
					</Col>

					<Col md={6}>
						<Form.Group>
							<Form.Label>Referring Physician</Form.Label>
							<AsyncSelect url="/v2/referring-providers" mapFn={mapNameAndId} onChange={handleChangeReferringProvider} optionValue={treatment?.referringProvider.id} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Insurance Auth Number</Form.Label>
							<Form.Control defaultValue={treatment?.payorAuthNumber} onChange={handlChangeAuthNumber} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Assignee</Form.Label>
							<AsyncSelect url="/v2/users" mapFn={mapNameAndId} onChange={handleChangeAssignee} optionValue={treatment?.assignee.id} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Status</Form.Label>
							<AsyncSelect url="/v2/treatment-statuses" mapFn={mapNameAndId} onChange={handleChangeStatus} optionValue={treatment?.status.id} />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<Form.Group>
							<Form.Label>Diagnosis</Form.Label>
							<Select
								defaultValue={treatment?.dx.map((dx: string) => {
									return { value: dx, label: dx }
								})}
								options={dx.map((icd10: any) => {
									return { value: icd10.code, label: icd10.code, description: icd10.desc }
								})}
								onChange={handleChangeDiagnosis}
								formatOptionLabel={(option: any, labelMeta: any) => {
									if (labelMeta.context === "menu") {
										return <div>
											<div>{option.value}</div>
											<div style={{ fontSize: "smaller", color: "gray" }}>{option.desc}</div>
										</div>
									}

									return option.value
								}}
								isMulti />
						</Form.Group>

						<Form.Group>
							<Form.Label>Note</Form.Label>
							<Form.Control as="textarea" rows="3" defaultValue={treatment?.note} onChange={handleChangeNote} />
						</Form.Group>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={cancel}>Cancel</Button>
				<Button type="submit" disabled={isWaiting}>{isWaiting ? "Saving" : "Save"}</Button>
			</Modal.Footer>
		</Form>
	</Modal>
}

interface Identifiable {
	id: number
}

interface Nameable {
	name: string
}

const mapNameAndId = (item: Identifiable & Nameable): Option => {
	return {
		label: item.name,
		value: String(item.id),
	}
}

export default EditTreatmentModal