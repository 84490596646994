import React, { FormEvent } from "react"
import { FormGroup, FormLabel, FormControl, FormText } from "react-bootstrap"

export interface Facility {
    name: string
    address: string
}

export interface SelectFacilityProps {
    facility: Facility
    onChange: (facility: Facility) => void
}

const SelectFacility = (props: SelectFacilityProps) => {
    const { facility, onChange } = props

    const handleUpdateName = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...facility, name: e.currentTarget.value })
    }

    const handleUpdateAddress = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...facility, address: e.currentTarget.value })
    }

    return <>
        <FormGroup>
            <FormLabel>Facility Name</FormLabel>
            <FormControl type="input" value={facility.name} onChange={handleUpdateName} />
        </FormGroup>
        <FormGroup>
            <FormLabel>Facility Address</FormLabel>
            <FormControl type="input" value={facility.address} onChange={handleUpdateAddress} />
            <FormText>Optional</FormText>
        </FormGroup>
    </>
}

export default SelectFacility