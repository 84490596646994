import React, { useContext } from "react"
import { useDispatch } from "react-redux"
import { Row, Form, Nav } from "react-bootstrap"
import moment from "moment"
import { byType } from "../common/jsonapi-filters"
import { open } from "../actions/ModalActions"
import TreatmentContext from "../contexts/TreatmentContext"
import { useTreatment } from "../hooks/useTreatment"
import { TreatmentRepo } from "../core/entities/treatment"

interface DetailProps {
	label: string
	value: string
	wrap?: boolean
}

////////////////////////////////////////////////////
// Detail
////////////////////////////////////////////////////
const Detail = (props: DetailProps) => {
	const { label, value, wrap } = props

	const style = {
		color: !value || value === "None" ? "red" : "black"
	}

	return (
		<Row style={{ padding: "15px 15px 0 15px" }}>
			<Form.Group style={{ margin: 0 }}>
				<Form.Label style={{ fontWeight: "bolder", ...style }}>{label}</Form.Label>
				<p className={wrap ? "" : "nowrap"} style={style}>{value}</p>
			</Form.Group>
		</Row>
	)
}

interface DetailListProps {
	label: string
	value: string[]
	onClickLabel?: () => void
}

////////////////////////////////////////////////////
// DetailList
////////////////////////////////////////////////////
const DetailList = (props: DetailListProps) => {
	const { label, value, onClickLabel } = props

	return (
		<Row style={{ padding: "15px 15px 0 15px" }}>
			<Form.Group style={{ margin: 0 }}>
				{onClickLabel ? (
					<Form.Label style={{ fontWeight: "bolder" }}>
						<Nav.Link style={{ padding: 0 }} onClick={onClickLabel}>
							{label}
						</Nav.Link>
					</Form.Label>
				) : (
					<Form.Label style={{ fontWeight: "bolder" }}>{label}</Form.Label>
				)}
				<p>
					{value.map((value: string, index: number) => {
						return (
							<li key={index} style={{ listStyle: "none", paddingLeft: 0 }}>
								{value}
							</li>
						)
					})}
				</p>
			</Form.Group>
		</Row>
	)
}

const repo = new TreatmentRepo()

////////////////////////////////////////////////////
// TreatmentDetails
////////////////////////////////////////////////////
const TreatmentDetails = () => {
	const treatmentContext = useContext(TreatmentContext)
	const included = treatmentContext[1]

	const { treatment } = useTreatment(repo, treatmentContext[0].id)

	const dispatch = useDispatch()

	if (!treatment.id) {
		return null
	}

	const admissions = included
		.filter(byType("Admission"))
		.sort((a: any, b: any) => a.id - b.id)
		.map((item: any) => {
			const { admit, discharge } = item.attributes

			return (
				(admit ? moment(admit).format("MM/DD") : "(none)") +
				" to " +
				(discharge ? moment(discharge).format("MM/DD") : "(none)")
			)
		})


	return (
		<div>
			<DetailList
				label="Admissions"
				value={admissions}
				onClickLabel={() => {
					dispatch(open("EditAdmissions", { treatment: treatmentContext[0], admissions: included.filter(byType("Admission")) }))
				}}
			/>
			<Detail label="Facility" value={treatment.facility.name} />
			<Detail label="Modality" value={treatment.modality.name} />
			<Detail label="Insurance" value={treatment.payor.name} />
			<Detail label="Auth Number" value={treatment.payorAuthNumber} />
			<Detail label="Referring Physician" value={treatment.referringProvider.name} />
			<DetailList label="Diagnosis" value={treatment.dx} />
			<Detail label="Status" value={treatment.status.name} />
			<Detail label="Note" value={treatment.note} wrap={true} />
			<Detail label="Created At" value={moment(treatment.createdAt.substring(0, 10)).format("MM/DD/YYYY")} />
			<Detail label="Created By" value={treatment.createdBy.name} />
		</div>
	)
}

export default TreatmentDetails
