import React, { useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import { useEvent } from "../../hooks/useEvent"

const NewPracticeModal = () => {
    const [isOpen, setIsOpen] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)
    const [err, setErr] = useState("")

    useEvent("openNewPracticeModal", () => {
        setErr("")
        setIsOpen(true)
    })

    const handleCreatePractice = async () => {
        const name = formRef?.current?.querySelector<HTMLInputElement>(`input[name="practice.name"]`)?.value
        const code = formRef?.current?.querySelector<HTMLInputElement>(`input[name="practice.code"]`)?.value


        const resp = await fetch(`/v2/practices`, { method: "POST", body: JSON.stringify({ name, code }) })
        const json = await resp.json()

        if ("error" in json) {
            setErr(json.error)
        } else {
            setIsOpen(false)
        }
    }

    return <Modal show={isOpen}>
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">New Practice</h5>
            </div>
            <div className="modal-body">
                {
                    err
                        ? <div className="alert alert-danger">{err}</div>
                        : null
                }
                <form ref={formRef}>
                    <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input type="text" className="form-control" name="practice.name" />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" name="practice.code" />
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={() => { setIsOpen(false) }}>Cancel</button>
                <button className="btn btn-primary" onClick={() => { handleCreatePractice() }}>Create</button>
            </div>
        </div>
    </Modal>
}

export default NewPracticeModal