import Axios from "../common/axios"
import * as _ from "lodash"
import { AlertPayload, AlertAction } from "../actions/AlertActions"

export enum Type {
    FETCH_WAITING = "TreatmentAction.FETCH_TREATMENT_WAITING"
    , FETCH_COMPLETED = "TreatmentAction.FETCH_TREATMENT_COMPLETED"
    , FETCH_ERROR = "TreatmentAction.FETCH_TREATMENT_ERROR"

    , UPDATE_WAITING = "TreatmentAction.UPDATE_TREATMENT_WAITING"
    , UPDATE_COMPLETED = "TreatmentAction.UPDATE_TREATMENT_COMPLETED"
    , UPDATE_ERROR = "TreatmentAction.UPDATE_TREATMENT_ERROR"

    , CREATE_WAITING = "TreatmentAction.ADD_TREATMENT_WAITING"
    , CREATE_COMPLETED = "TreatmentAction.ADD_TREATMENT_COMPLETED"
    , CREATE_ERROR = "TreatmentAction.ADD_TREATMENT_ERROR"
}

export interface Action {
    type: Type
    payload: any
}

export const fetchRaw = (tenantId: number, filter: any, limit: number = 20, offset: number = 0) => {
    const params = {
        filter: filter
        , include: 'patient,diagnosis,phase,admission'
        , limit: limit
        , offset: offset
    }

    return Axios.get(`/api/${tenantId}/treatment`, { params })
}

export const fetch = (tenantId: number, filter: any, limit: number = 20, offset: number = 0) => async (dispatch: (action: Action) => Action, getState: () => any) => {
    const state = getState().treatment

    if (state.loading) {
        return
    }

    dispatch({ type: Type.FETCH_WAITING, payload: { filter } })

    try {
        const params = {
            filter: filter
            , include: 'patient,diagnosis,phase,admission'
            , limit: limit
            , offset: offset
        }

        const { data } = await Axios.get(`/api/${tenantId}/treatment`, { params })

        dispatch({ type: Type.FETCH_COMPLETED, payload: { data } })
    } catch (error) {
        dispatch({ type: Type.FETCH_ERROR, payload: { error } })
    }
}

export const add = (tenantId: number, treatment: any) => async (dispatch: (action: any) => any) => {
    dispatch({ type: Type.CREATE_WAITING })

    try {
        const { data } = await Axios.post(`/api/${tenantId}/treatment`, treatment)
        dispatch({ type: Type.CREATE_COMPLETED, payload: { data } })
    } catch (error) {
        dispatch({ type: Type.CREATE_ERROR, payload: { error } })
    }
}

export const updateRaw = (tenantId: number, treatment: any) => {
    return Axios.post(`/api/${tenantId}/treatment/${treatment.id}`, treatment)
}

export const update = (tenantId: number, treatment: any) => async (dispatch: Function) => {
    dispatch({ type: Type.UPDATE_WAITING })

    try {
        const operations = [
            {
                op: "update"
                , ref: {
                    type: treatment.type
                    , id: treatment.id
                }
                , data: treatment
            }
        ]

        await Axios({ method: 'post', url: `/api/${tenantId}/treatment/operations`, data: JSON.stringify({ operations }) })

        dispatch({ type: Type.UPDATE_COMPLETED, response: { data: { data: [treatment] } } })
    }
    catch (error) {
        console.error(error)
        dispatch({ type: Type.UPDATE_ERROR, response: error })
    }
}

export interface TreatmentState {
    stale: boolean
    , loading: boolean
    , filter: any
    , data: any[]
    , included: any[]
    , pagination: any
}

export const defaultTreatmentState: TreatmentState = {
    data: []
    , included: []
    , loading: false
    , pagination: {
        limit: 0
        , offset: 0
        , count: 0
    }
    , stale: false
    , filter: {
        modality_id: 0
        , facility_id: 0
        , assignee_id: 0
        , status_id: 0
    }
}

const reducer = (prevState: TreatmentState, action: Action | Action | AlertPayload): TreatmentState => {
    const state = _.cloneDeep(prevState)

    switch (action.type) {
        case Type.FETCH_WAITING:
            state.stale = false
            state.loading = true
            state.filter = { ...action.payload.filter }
            break
        case Type.FETCH_COMPLETED:
            state.stale = false
            state.loading = false

            const { data, included, pagination } = action.payload.data

            state.data = [...data]
            state.included = [...included]
            state.pagination = { ...pagination }
            break
        case Type.UPDATE_COMPLETED:
            {
                const { data, included, pagination } = action.payload.data

                for (const treatment of data) {
                    const index = state.data.findIndex((dd: any) => dd.id === treatment.id && dd.type === treatment.type)

                    if (index === -1) {
                        state.data.push(treatment)
                    } else {
                        state.data.splice(index, 1, treatment)
                    }
                }

                if (included) {
                    for (const item of included) {
                        const index = state.included.findIndex((ii: any) => ii.id === item.id && ii.type === item.type)

                        if (index === -1) {
                            state.included.push(item)
                        } else {
                            state.included.splice(index, 1, item)
                        }
                    }
                }

                if (pagination) {
                    state.pagination = { ...pagination }
                }
            }
            break
        case Type.CREATE_COMPLETED:
            state.stale = true
            break
        case AlertAction.DISMISS_COMPLETED:
            const index = state.included.findIndex((included: any) => included.type === action.data.type && included.id === action.data.id)
            state.included.splice(index, 1, action.data)
            break

    }

    return state
}

export default reducer