import React, { useContext, FormEvent } from "react"
import { Form } from "react-bootstrap"
import { cloneDeep } from "lodash"
import ChargeContext from "../../../contexts/ChargeContext"

const Phase = (props: any) => {
	const [charge, setCharge] = useContext(ChargeContext)
	const { phases } = props

	const setPhase = (e: FormEvent<HTMLSelectElement>) => {
		const next = cloneDeep(charge)
		next.relationships.phase.data.id = Number(e.currentTarget.value)
		setCharge(next)
	}

	return <Form.Group controlId="formValidationNull" >
		<Form.Label>Phase</Form.Label>
		<Form.Control as="select" tabIndex={"5"} value={charge.relationships.phase.data.id} onChange={setPhase}>
			{
				phases.map((item: any, index: number) => {
					return <option key={index} value={item.id}>{item.attributes.name}</option>
				})
			}
		</Form.Control>
	</Form.Group>
}

export default Phase