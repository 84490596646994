import Axios, { AxiosError, AxiosRequestConfig } from "axios"

const onResponseError = (error: AxiosError) => {
	const { response } = error

	console.error(`Axios ${response?.status} ${response?.statusText} ${response?.request.responseURL}`)

	return Promise.reject(error)
}

Axios.interceptors.response.use((response: any) => response, onResponseError);

Axios.interceptors.request.use((config: AxiosRequestConfig) => {
	// const accessToken = new Token(localStorage.getItem("chargebook_access_token") || "")

	// if (accessToken.isValid()) {
	// 	const headers = {
	// 		"Authorization": `Bearer ${accessToken.tokenString}`
	// 	}

	// 	config.headers = { ...config.headers, ...headers }
	// }

	return config;
})

export default Axios