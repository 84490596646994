import React, { useState } from "react"
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Row, Col } from "react-bootstrap"

import "./DateRange.css"

export interface DateRangeProps {
    startDate?: string
    endDate?: string

    dateFormat?: string

    onChangeStartDate?: (date: string) => void
    onChangeEndDate?: (date: string) => void
}

const DateRange = (props: DateRangeProps) => {
    let { dateFormat, onChangeStartDate, onChangeEndDate } = props

    const [startDate, setStartDate] = useState<string | undefined>(props.startDate)
    const [endDate, setEndDate] = useState<string | undefined>(props.endDate)

    const handleChangeStartDate = (date: Date | null) => {
        const formatted = date ? moment(date).format(dateFormat) : ""

        setStartDate(formatted)

        if (onChangeStartDate) {
            onChangeStartDate(formatted)
        }
    }

    const handleChangeEndDate = (date: Date | null) => {
        const formatted = date ? moment(date).format(dateFormat) : ""

        setEndDate(formatted)

        if (onChangeEndDate) {
            onChangeEndDate(formatted)
        }
    }

    return <Row style={{ marginBottom: "10px" }}>
        <Col md={5}>
            <DatePicker
                className="DateRangeStartDate"
                name="startDate"

                selectsStart={true}

                dateFormat={dateFormat}
                selected={startDate ? moment(startDate).toDate() : null}
                startDate={startDate ? moment(startDate).toDate() : null}
                endDate={endDate ? moment(endDate).toDate() : null}

                onChange={handleChangeStartDate} />
        </Col>

        <Col md={5}>
            <DatePicker
                className="DateRangeEndDate"
                name="endDate"

                selectsEnd={true}

                dateFormat={dateFormat}
                selected={endDate ? moment(endDate).toDate() : null}
                startDate={startDate ? moment(startDate).toDate() : null}
                endDate={endDate ? moment(endDate).toDate() : null}

                onChange={handleChangeEndDate}
            />
        </Col>
    </Row>
}

export default DateRange
