import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { Form } from "react-bootstrap"
import Select from "react-select"
import { cloneDeep } from "lodash"
import { byId } from "../../../common/jsonapi-filters";
import { AppState } from "../../../reducers";
import ChargeContext from "../../../contexts/ChargeContext"

const SupervisingPhysician = () => {
	const facilities = useSelector((state: AppState) => state.facility.data)
	const physicians = useSelector((state: AppState) => state.physician.data)
	const [charge, setCharge] = useContext(ChargeContext)

	const setSupervisingPhysician = (supervisingPhysician: any) => {
		const next = cloneDeep(charge)
		next.relationships.supervisingphysician.data.id = supervisingPhysician.id
		setCharge(next)
	}

	const byPracticeId = (practiceId: number) => (physician: any) => {
		if (physician.id === 1) {
			return true
		}

		for (let rel of physician.relationships.practice.data) {
			if (rel.id === practiceId) {
				return true
			}
		}

		return false
	}

	return <Form.Group>
		<Form.Label>Supervising Physician</Form.Label>
		{
			<Select
				value={physicians.find(byId(charge.relationships.supervisingphysician.data.id))}

				onChange={setSupervisingPhysician}

				options={physicians.filter(byPracticeId(facilities.find(byId(charge.relationships.facility.data.id)).relationships.practice.data.id))}

				getOptionLabel={(option: any) => option.attributes.name}
				getOptionValue={(option: any) => option.id}
			/>
		}
	</Form.Group>
}

export default SupervisingPhysician