import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Treatment, TreatmentRepo } from "../core/entities/treatment"
import { AppState } from "../reducers"
import { useTokens } from "../useAuthorizationCodeFlow"

export const useTreatment = (repo: TreatmentRepo, treatmentId: number) => {
    const eventTimes = useSelector((state: AppState) => state.eventTimes)

    const [treatment, setTreatment] = useState({} as Treatment)
    const [isFetching, setIsFetching] = useState(false)

    const { getAccessToken } = useTokens()

    useEffect(() => {
        const fetch = async () => {
            setIsFetching(true)

            const treatment = await repo.getTreatmentById(treatmentId)

            setIsFetching(false)

            setTreatment(treatment)
        }

        fetch()
    }, [treatmentId, repo, eventTimes.treatment.length, getAccessToken])

    return {
        treatment
        , isFetching
    }
}
