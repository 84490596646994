import React, { useState, useEffect, useContext, FormEvent } from "react"
import { Col, FormControl, Button, Card, Row, Form, Alert } from "react-bootstrap"
import moment, { now } from "moment"

import Axios from "../../common/axios";
import { Token, useTokens } from "../../useAuthorizationCodeFlow"
import AuthContext from "../../contexts/AuthContext"
import EditPreauthModal from "../modal/EditPreauthModal"
import { useDispatch, useSelector } from "react-redux"
import { open } from "../../actions/ModalActions"
import { AppState } from "../../reducers"

import "./PreauthList.css"

interface ListProps {
	treatment: any
}

const PreauthList = (props: ListProps) => {
	const [preauths, setPreauths] = useState([] as any[])
	const { getAccessToken } = useTokens()
	const eventTimes = useSelector((state: AppState) => state.eventTimes)

	useEffect(() => {
		const fetch = async () => {
			const { status, data } = await Axios.get(`/remote-preauth/api/${localStorage.getItem("tenant)_id")!}/preauth/treatment_id/${props.treatment.id}`)
			if (status === 200) {
				setPreauths(data)
			}
		}

		fetch()
	}, [eventTimes.preauth.length, props.treatment.id, getAccessToken])

	return <>
		<EditPreauthModal />
		<table className="table" style={{ marginTop: "20px" }}>
			{
				preauths.map((preauth: any) => {
					return <PreauthRow key={preauth.UUID} preauth={preauth} preauths={preauths} setPreauths={setPreauths} />
				})
			}
		</table>
	</>

}

const PreauthRow = (props: any) => {
	const dispatch = useDispatch()
	const { preauth, preauths, setPreauths } = props
	const [getAccessToken] = useContext(AuthContext)

	const openModal = (preauth: any) => () => {
		dispatch(open("EditPreauth", { preauth }))
	}

	const monthDayYear = (date: string) => {
		if (date) {
			return moment(date.substring(0, 10)).format("MM/DD/YYYY")
		}

		return ""
	}

	const savePreauth = async (preauth: any) => {
		try {
			const { status } = await Axios.post(`/remote-preauth/api/${localStorage.getItem("tenant)_id")!}/preauth/save`, preauth)

			if (status === 200) {
				const nextPreauths = [...preauths]
				const index = nextPreauths.findIndex((p: any) => p.UUID === preauth.UUID)

				if (index !== -1) {
					nextPreauths[index] = preauth
					setPreauths(nextPreauths)
				}
			}
		}
		catch (e) {
			console.error("could not save notes:", e)
		}
	}

	const addNote = (text: string) => {
		const note = {
			text
			, author: getAccessToken().payload()?.name
			, createdAt: moment().format("YYYY-MM-DD HH:mm:ss")
		}

		preauth.notes.push(note)

		savePreauth(preauth)
	}

	const deleteNote = (index: number) => () => {
		const p = { ...preauth }

		p.notes.splice(index, 1)
		savePreauth(p)
	}

	return <tbody>
		<tr>
			<th>Modalities</th>
			<th>Fractions</th>
			<th>Insurance</th>
			<th>Facility</th>
			<th>Sim Date</th>
			<th>Start Date</th>
			<th>Date Range</th>
			<th>Authorization</th>
			<th>Status</th>
			<th>Edit</th>
		</tr>
		<tr key={preauth.UUID} id={`preauth-${preauth.UUID}`}>
			<td>
				<div><b>{preauth.modalities.join(", ")}</b></div>
			</td>
			<td>
				<div>{preauth.fractions}</div>
			</td>
			<td>
				<div>{preauth.insurance.carrier}</div>
				{/* <div>{preauth.authorizationNumber}</div> */}
			</td>
			<td>
				<div>{preauth.facility.name}</div>
			</td>
			<td>{monthDayYear(preauth.simulationDate)}</td>
			<td>{monthDayYear(preauth.treatmentStartDate)}</td>
			<td>
				<p>{monthDayYear(preauth.startDate)} - {monthDayYear(preauth.endDate)}</p>
			</td>
			<td>
				<div>{preauth.authorizationNumber}</div>
			</td>
			<td>
				<div><b>{preauth.status.name}</b></div>
				{
					preauth.completedBenefitCheck
						? "Compl. Benefit Check"
						: null
				}
			</td>
			<td>
				<Button size="sm" onClick={openModal(preauth)}>Edit</Button>
			</td>
		</tr>
		<tr>
			<td colSpan={9}>
				{
					preauth.notes.map((note: any, index: number) => {
						return <div className="comment" key={index}>
							<div>
								<b>{note.author}</b> at <em>{note.createdAt}</em>

								{
									getAccessToken().payload()?.name == note.author
										? <button className="btn btn-sm btn-primary" style={{ float: "right" }} onClick={deleteNote(index)}>Delete</button>
										: null
								}
							</div>
							<div>{note.text}</div>
						</div>
					})
				}
				<AddNote onSubmit={addNote} />
			</td>
		</tr>
	</tbody>

}
interface AddNoteProps {
	onSubmit: (noteText: string) => void
}

const AddNote = (props: AddNoteProps) => {
	const { onSubmit } = props
	const [noteText, setNoteText] = useState("")

	const submit = (e: FormEvent) => {
		e.preventDefault()
		onSubmit(noteText)
		setNoteText("")
	}

	return <Form onSubmit={submit}>
		<Form.Group>
			<textarea className="form-control" placeholder="Type a new comment..." value={noteText} onChange={(e: FormEvent<HTMLTextAreaElement>) => setNoteText(e.currentTarget.value)}></textarea>
		</Form.Group>

		<Button type="submit" size="sm">Comment</Button>
	</Form>
}

export default PreauthList