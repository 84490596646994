import React, { useState, useEffect, useCallback, FormEvent } from "react"
import { Table, Row, Col, FormGroup, FormLabel, Button, Form } from "react-bootstrap"
import Axios from "./common/axios"
import moment from "moment"
import Navbar from "./components/Navbar"
import Select from "react-select"
import { setState, getState } from "./state/urlState"
import { useTokens } from "./useAuthorizationCodeFlow"
import DateInput from "./components/DateInput"
import DateRange from "./components/DateRange"

const PreauthList = () => {
	const [facilityOptions, setFacilityOptions] = useState<any[]>([])
	const [statusOptions, setStatusOptions] = useState<any[]>([])

	const [preauthFilter] = useState({})
	const [preauthData, setPreauthData] = useState<any[]>([] as any[])
	const { getAccessToken } = useTokens()

	const benefitCheckOptions = [{ label: "All", value: "0" }, { label: "Completed", value: "1" }, { label: "Not Completed", value: "2" }]
	const sortOptions = [
		{ value: "", label: "None" }
		, { value: "asc(treatment_start_date)", label: "Treatment Start Date (Asc)" }
		, { value: "desc(treatment_start_date)", label: "Treatment Start Date (Desc)" }
		, { value: "asc(simulation_date)", label: "Simulation Date (Asc)" }
		, { value: "desc(simulation_date)", label: "Simulation Date (Desc)" }
		, { value: "desc(insurance->>'carrier')", label: "Insurance (Asc)" }
		, { value: "desc(insurance->>'carrier')", label: "Insurance (Desc)" }
	]

	const downloadCsv = useCallback(() => {
		let url = `/remote-preauth/api/${getAccessToken().tenantId()}/preauth/csv`
		const nextPreauthFilter: any = { ...getState() }

		let query = ""

		for (const filterKey in nextPreauthFilter) {
			if (nextPreauthFilter[filterKey]) {
				if (!query.length) {
					query += "?"
				} else {
					query += "&"
				}

				query += (filterKey + "=" + nextPreauthFilter[filterKey])
			}
		}

		Axios({
			url: url + query,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			var fileURL = window.URL.createObjectURL(new Blob([response.data]));
			var fileLink = document.createElement('a');

			fileLink.href = fileURL;
			fileLink.setAttribute('download', 'preauths.csv');
			document.body.appendChild(fileLink);

			fileLink.click();
		});
	}, [getAccessToken, preauthFilter])

	const fetchPreauths = async (filterKey?: string, filterValue?: string) => {
		console.log("state", getState())

		let url = `/remote-preauth/api/${getAccessToken().tenantId()}/preauth`
		const nextPreauthFilter: any = { ...{ sort: "asc(simulation_date)" }, ...getState() }

		if (filterKey) {
			nextPreauthFilter[filterKey] = filterValue
			setState(nextPreauthFilter)
		}

		let query = ""

		for (const filterKey in nextPreauthFilter) {
			if (nextPreauthFilter[filterKey]) {
				if (!query.length) {
					query += "?"
				} else {
					query += "&"
				}

				query += (filterKey + "=" + nextPreauthFilter[filterKey])
			}
		}

		const { status, data } = await Axios.get(url + query)

		if (status === 200) {
			console.log("setting preauths", data)
			setPreauthData(data)
		}
	}

	const fetchFacilityOptions = useCallback(async () => {
		const { status, data } = await Axios.get(`/remote-preauth/api/${localStorage.getItem("tenant)_id")!}/facility`)

		if (status === 200) {
			setFacilityOptions(data.map((f: string) => {
				return {
					label: f
					, value: f
				}
			}))
		}
	}, [getAccessToken])

	const fetchStatusOptions = useCallback(async () => {
		const { status, data } = await Axios.get("/remote-preauth/api/status")

		if (status === 200) {
			setStatusOptions(data.map((s: any) => {
				return {
					label: s.name
					, value: s.UUID
				}
			}))
		}
	}, [])


	useEffect(() => {
		fetchPreauths()
		fetchFacilityOptions()
		fetchStatusOptions()
	}, [fetchFacilityOptions, fetchStatusOptions])

	return <>
		<Navbar />
		<div className="body">
			<form style={{ backgroundColor: "#f9f9f9", padding: "15px", borderBottom: "solid 1px #999" }}>
				<Row>
					<Col md={2}>
						<FormGroup>
							<FormLabel>Search</FormLabel>
							<Form.Control
								onChange={(e: FormEvent<HTMLInputElement>) => {
									(window as any).searchQuery = e.currentTarget.value

									clearTimeout((window as any).searchTimeout);

									(window as any).searchTimeout = setTimeout(() => {
										fetchPreauths("search", (window as any).searchQuery)
									}, 500);
								}}

								defaultValue={String(getState().search ? getState().search : "")}
							/>
						</FormGroup>
					</Col>

					<Col md={2}>
						<FormGroup>
							<FormLabel>Facility</FormLabel>
							<Select
								onChange={(option: any) => {
									if (!option) {
										fetchPreauths("facilityName", "")
									} else {
										fetchPreauths("facilityName", option.map((o: any) => o.value).join("~"))
									}
								}}

								value={facilityOptions.filter((value: any) => {
									let facilityNames: any[] | undefined = getState().facilityName?.split("~")

									if (facilityNames) {
										for (const facilityName of facilityNames) {
											if (facilityName === value.value) {
												return true
											}
										}
									}

									return false
								})}

								options={[{ label: "All", value: "" }].concat(facilityOptions)}

								isMulti={true}
							/>
						</FormGroup>
					</Col>

					<Col md={2}>
						<FormGroup>
							<FormLabel>Status</FormLabel>
							<Select
								onChange={(option: any) => {
									if (!option) {
										fetchPreauths("status", "")
									} else {
										fetchPreauths("status", option.map((o: any) => o.value).join("~"))
									}
								}}

								value={statusOptions.filter((value: any) => {
									let uuids: any[] | undefined = getState().status?.split("~")

									if (uuids) {
										for (const uuid of uuids) {
											if (uuid === value.value) {
												return true
											}
										}
									}

									return false
								})}

								options={[{ label: "All", value: "" }].concat(statusOptions)}

								isMulti={true}
							/>
						</FormGroup>
					</Col>

					<Col md={1}>
						<FormGroup>
							<FormLabel>Benefit Check</FormLabel>
							<Select
								onChange={(option: any) => {
									fetchPreauths("completedBenefitCheck", option.value)
								}}

								value={benefitCheckOptions.find((value: any) => {
									if (getState().completedBenefitCheck === value.value) {
										return true
									}

									return false
								})}

								options={benefitCheckOptions}
							/>
						</FormGroup>
					</Col>

					<Col md={2}>
						<FormGroup>
							<FormLabel>Sort</FormLabel>
							<Select
								defaultValue={{ value: "", label: "None" }}

								onChange={(option: any) => {
									fetchPreauths("sort", option.value)
								}}

								value={sortOptions.find((value: any) => {
									if (getState().sort === value.value) {
										return true
									}

									return false
								})}

								options={sortOptions}
							/>
						</FormGroup>
					</Col>

					<Col md={2}>
						<FormGroup>
							<FormLabel>Sim Date Range</FormLabel>
							<DateRange
								onChangeStartDate={(date: string) => {
									fetchPreauths("simulationDateStart", date.substring(0, 10))
								}} 

								onChangeEndDate={(date: string) => {
									fetchPreauths("simulationDateEnd", date.substring(0, 10))
								}}
							/>
						</FormGroup>
					</Col>


					<Col md={1}>
						<Button onClick={downloadCsv} style={{ marginTop: "31px" }}><img src="/download_white.svg" width="24" height="24" /></Button>
					</Col>
				</Row>
			</form>
			<Table>
				<thead>
					<tr>
						<th></th>
						<th>Patient</th>
						<th>Modalities</th>
						<th>Insurance</th>
						<th>Facility</th>
						<th>Auth</th>
						<th>Sim Date</th>
						<th>Start Date</th>
						<th>Date Range</th>
						<th>Status</th>
						<th>CBC</th>
					</tr>
				</thead>
				<tbody>
					{
						preauthData.map((preauth: any) => <PreauthRow preauth={preauth} />)
					}
				</tbody>
			</Table>
		</div>
	</>
}

interface PreauthRowProps {
	preauth: any
}

const PreauthRow = (props: PreauthRowProps) => {
	const [showNotes, setShowNotes] = useState(false)

	const { preauth } = props

	const formattedDate = (date: string) => {
		if (date) {
			return moment(date.substring(0, 10)).format("MM/DD/YYYY")
		}

		return ""
	}

	const getStyle = (preauth: any) => {
		if (preauth.status.name === "Not Required" || preauth.status.name === "Cancelled" || preauth.status.name === "Approved") {
			return {}
		}


		const diff = moment(preauth.treatmentStartDate).diff(moment.now(), 'days')

		if (diff < 4) {
			return {
				backgroundColor: "#f8d7da"
				, color: "#721c24"
			}
		}

		return {}
	}

	return <>
		<tr key={preauth.UUID} style={getStyle(preauth)}>
			<td>
				<a onClick={() => setShowNotes(!showNotes)} style={{ opacity: preauth.notes.length ? "0.75" : "0.2" }} role="button">
					<img src="/right-arrow.png" width={16} className={"caret" + (showNotes ? " rotate-90" : "")} />
				</a>
			</td>
			<td>
				<div><b><a href={`/tx/${preauth.treatmentID}`}>{preauth.patient.lastName}, {preauth.patient.firstName}</a></b></div>
				<div>{preauth.patient.accountNumber}</div>
			</td>
			{/* <td></td> */}
			<td>{preauth.modalities.join(",")}</td>
			<td>
				<div>{preauth.insurance.carrier}</div>
				{/* <div title="authorization number">{preauth.authorizationNumber}</div> */}
			</td>
			<td>{preauth.facility.name}</td>
			<td>{preauth.authorizationNumber}</td>
			<td>{formattedDate(preauth.simulationDate)}</td>
			<td>{formattedDate(preauth.treatmentStartDate)}</td>
			<td>{formattedDate(preauth.startDate)} - {formattedDate(preauth.endDate)}</td>
			{/* <td>{preauth.completedBenefitCheck ? "Yes" : ""}</td> */}
			<td>
				<div>{preauth.status.name}</div>
			</td>
			<td>{preauth.completedBenefitCheck ? "Yes" : ""}</td>
		</tr>
		{
			showNotes
				? <tr>
					<td></td>
					<td colSpan={12}>
						{
							preauth.notes.map((note: any) => {
								return <div>
									<div><b>{note.author}</b> at <em>{note.createdAt}</em></div>
									<div>{note.text}</div>
								</div>
							})
						}
					</td>
				</tr>
				: null
		}
	</>
}

export default PreauthList
