import { useState, useCallback, useEffect } from "react"
import { useSelector } from "react-redux"

import { Patient, defaultPatient } from "./patient"
import { Facility, defaultFacility } from "./facility"
import { Payor, defaultPayor } from "./payor"
import { ReferringProvider, defaultReferringProvider } from "./referringProvider"
import { Phase, defaultPhase } from "./phase"
import { TreatmentStatus, defaultTreatmentStatus } from "./treatmentStatus"
import { Modality, defaultModality } from "./modality"
import { User, defaultUser } from "./user"
import { Admission } from "./admission"

import axios from "../../common/axios"
import { useTokens } from "../../useAuthorizationCodeFlow"
import { useParams } from "../../hooks/useParams"

export interface Treatment {
    id: number
    patient: Patient
    facility: Facility
    payor: Payor
    payorAuthNumber: string
    referringProvider: ReferringProvider
    initialPhase: Phase
    status: TreatmentStatus
    modality: Modality
    assignee: User
    createdBy: User
    createdAt: string
    dx: string[]
    note: string
    admissions: Admission[]
    startDate?: string
    endDate?: string
}

export interface TreatmentFilter {
    facilityId: number
    statusId: number
    assigneeId: number
    search: string

    limit: number
    offset: number
}

export const fromLegacyTreatment = (legacy: any): Treatment => {
    const treatment = { ...defaultTreatment }

    treatment.id = legacy.id

    // attributes
    treatment.note = legacy.attributes.note
    treatment.dx = legacy.attributes.diagnosis
    treatment.payorAuthNumber = legacy.attributes.insurance_auth_number


    // relationships
    treatment.patient.id = legacy.relationships.patient.data.id
    treatment.modality.id = legacy.relationships.modality.data.id
    treatment.facility.id = legacy.relationships.facility.data.id
    treatment.payor.id = legacy.relationships.insurance.data.id
    treatment.status.id = legacy.relationships.status.data.id
    treatment.assignee.id = legacy.relationships.assignee.data.id
    treatment.referringProvider.id = legacy.relationships.referringphysician.data.id

    return treatment
}

export const defaultTreatmentFilter = (): TreatmentFilter => {
    return {
        facilityId: 0
        , statusId: 0
        , assigneeId: 0
        , search: ""
        , limit: 25
        , offset: 0
    }
}

export class TreatmentRepo {
    async getTreatmentById(id: number): Promise<Treatment> {
        const resp = await axios.get(`/v2/treatments/${id}`)
        return resp.data
    }

    async listTreatments(filter: TreatmentFilter): Promise<Treatment[]> {
        const resp = await axios.get(`/v2/treatments`, { params: filter })
        return resp.data
    }
}

export const useTreatment = (treatmentId: number) => {
    const eventTimes = useSelector((state: any) => state.eventTimes)

    const [treatment, setTreatment] = useState({} as Treatment)
    const [isFetching, setIsFetching] = useState(false)

    const { getAccessToken } = useTokens()

    useEffect(() => {
        const doFetch = async () => {
            setIsFetching(true)

            const resp = await fetch(`/v2/treatments/${treatmentId}`)
            const json = await resp.json()

            setIsFetching(false)

            setTreatment(json)
        }

        doFetch()
    }, [treatmentId, eventTimes.treatment.length, getAccessToken])

    return {
        treatment
        , isFetching
    }
}

export const useTreatmentList = () => {
    const [treatments, setTreatments] = useState([] as Treatment[])
    const { params, setParams } = useParams(defaultTreatmentFilter())

    const [isFetching, setIsFetching] = useState(false)
    const [isFetchingMore, setIsFetchingMore] = useState(false)

    const [nextPage, setNextPage] = useState(1)

    const fetchMore = async () => {
        setIsFetchingMore(true)

        const resp = await fetch(`/v2/treatments?${new URLSearchParams({ ...params, offset: params.limit * nextPage } as any).toString()}`)
        const json = await resp.json()

        setIsFetchingMore(false)
        setNextPage(nextPage + 1)

        setTreatments([...treatments, ...json])
    }

    const doFetch = useCallback(async (filter: TreatmentFilter) => {
        setIsFetching(true)

        const resp = await fetch(`/v2/treatments?${new URLSearchParams(filter as any).toString()}`)
        const json = await resp.json()

        setIsFetching(false)
        setNextPage(1)

        setTreatments(json)
    }, [])


    useEffect(() => {
        doFetch(params)
    }, [fetch, params])

    return {
        treatments
        , filter: params
        , setFilter: setParams
        , fetchMore
        , isFetching
        , isFetchingMore
    }
}

const defaultTreatment: Treatment = {
    id: 0
    , patient: defaultPatient
    , facility: defaultFacility
    , payor: defaultPayor
    , payorAuthNumber: ''
    , referringProvider: defaultReferringProvider
    , initialPhase: defaultPhase
    , status: defaultTreatmentStatus
    , modality: defaultModality
    , assignee: defaultUser
    , createdBy: defaultUser
    , createdAt: ''
    , dx: []
    , note: ''
    , admissions: []
}