import React, { useState, useEffect, FocusEvent, ChangeEvent } from "react"
import { FormControl, FormControlProps } from "react-bootstrap"
import moment from "moment"

export interface DateInputProps {
    startDate?: string
    dateFormat?: string
    tabIndex?: number
    autoFocus?: boolean
    name?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    onChangeDate?: (date: string) => void
}

const DateInput = (props: DateInputProps & FormControlProps) => {
    let { startDate, dateFormat, onChangeDate, name, ...restProps } = props
    const [input, setInput] = useState(startDate)

    useEffect(() => {
        setInput(startDate)
    }, [startDate])

    if (!dateFormat) {
        dateFormat = "L"
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.currentTarget.value)

        if (props.onChange) {
            props.onChange(e)
        }
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        try {
            let tokens = input?.split("/")

            if (tokens?.length === 2) {
                tokens = [...tokens.map((token: string) => token.substring(0, 2)), moment().format("YYYY")]
            }

            const formatted = moment(tokens?.join("/")).format("L")

            setInput(formatted)
            if (onChangeDate) {
                onChangeDate(formatted)
            }
        } catch (e) {
            console.log(e)
        }
    }

    return <FormControl value={input ? input : ""} onChange={handleChange} onBlur={handleBlur} name={name} tabIndex={props.tabIndex}  />
}

export default DateInput
