import React, { useState, useEffect, useContext, FormEvent } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal, ModalBody, ModalFooter, Button, Row, Col, Form, FormGroup, FormLabel, FormControl, Tabs, Tab, ModalTitle } from "react-bootstrap"
import Select from "react-select"
import DatePicker from "react-datepicker"
import moment from "moment"

import Axios from "../../common/axios"

import { close } from "../../actions/ModalActions"
import { updateRaw } from "../../actions/TreatmentActions"
import { AppState } from "../../reducers"

import TreatmentContext from "../../contexts/TreatmentContext"

import SelectDiagnosis from "./PreauthModal/SelectDiagnosis"
import SelectPhysician, { Physician } from "./PreauthModal/SelectPhysician"
import SelectPatient, { Patient } from "./PreauthModal/SelectPatient"
import SelectFacility, { Facility } from "./PreauthModal/SelectFacility"
import SelectInsurance, { Insurance } from "./PreauthModal/SelectInsurance"
import SelectStatus from "./PreauthModal/SelectStatus"

import "react-datepicker/dist/react-datepicker.css"
import "./PreauthModal.css"
import { updated } from "../../actions/EventTimeActions"
import { useTokens } from "../../useAuthorizationCodeFlow"
import { getTenantId } from "../../common/jwt"

const EditPreauthModal = () => {
    const show = useSelector((state: AppState) => state.modal.isOpen && state.modal.modalIdentifier === "EditPreauth")
    const modalProps = useSelector((state: AppState) => state.modal.modalProps)
    const dispatch = useDispatch()
    const { getAccessToken } = useTokens()
    const [isSaveWaiting, setIsSaveWaiting] = useState(false)
    const [treatment] = useContext(TreatmentContext)
    const [preauth, setPreauth] = useState(modalProps.preauth)

    useEffect(() => {
        if (show) {
            setPreauth(modalProps.preauth)
        }
    }, [show, modalProps.preauth])

    const setModalities = (option: any) => {
        setPreauth({ ...preauth, modalities: option.map((obj: any) => obj.value) })
    }

    const setFractions = (e: FormEvent<HTMLInputElement>) => {
        setPreauth({ ...preauth, fractions: Number(e.currentTarget.value) })
    }

    const setAuthorizationNumber = (e: FormEvent<HTMLInputElement>) => {
        setPreauth({ ...preauth, authorizationNumber: e.currentTarget.value })
    }

    const setSimulationDate = (date: Date) => {
        if (!date) {
            setPreauth({ ...preauth, simulationDate: null })
            return
        }

        setPreauth({ ...preauth, simulationDate: moment(date).format("YYYY-MM-DD") })
    }

    const setTreatmentStartDate = (date: Date) => {
        if (!date) {
            setPreauth({ ...preauth, treatmentStartDate: null })
            return
        }

        setPreauth({ ...preauth, treatmentStartDate: moment(date).format("YYYY-MM-DD") })
    }

    const setStartDate = (date: Date) => {
        if (!date) {
            setPreauth({ ...preauth, startDate: null })
            return
        }

        setPreauth({ ...preauth, startDate: moment(date).format("YYYY-MM-DD") })
    }

    const setEndDate = (date: Date) => {
        if (!date) {
            setPreauth({ ...preauth, endDate: null })
            return
        }

        setPreauth({ ...preauth, endDate: moment(date).format("YYYY-MM-DD") })
    }

    const setPhysician = (physician: Physician) => {
        setPreauth({ ...preauth, physician })
    }

    const setInsurance = (insurance: Insurance) => {
        setPreauth({ ...preauth, insurance })
    }

    const setFacility = (facility: Facility) => {
        setPreauth({ ...preauth, facility })
    }

    const setDiagnosis = (diagnosis: string[]) => {
        setPreauth({ ...preauth, diagnosis })
    }

    const setPatient = (patient: Patient) => {
        setPreauth({ ...preauth, patient })
    }

    const setStatus = (statusUUID: string, status: any) => {
        setPreauth({ ...preauth, statusUUID, status })
    }

    const toggleCompletedBenefitCheck = () => {
        setPreauth({ ...preauth, completedBenefitCheck: !preauth.completedBenefitCheck })
    }

	const valid = (preauth: any): boolean => {
		const statusUUIDs = [
			'3f40efb4-4fd0-4de9-81b1-f131a759610d' // Auto approved
			, 'a59e7bef-8062-446f-ba27-0b731f67d2ef' // Submitted
			, '53f81284-c844-4d6a-a87d-568c2558de80' // Not Required
			, '4c27d5d3-a9c2-4c7c-abc4-0e7c7f4cee46' // Approved
		]

		if (preauth.authorizationNumber === "" && statusUUIDs.includes(preauth.statusUUID)) {
			return false
		}

		return true;
	}

    const saveAndClose = () => {
        setIsSaveWaiting(true)

        const save = async () => {
            try {
                const { status, data } = await Axios.post(`/remote-preauth/api/${localStorage.getItem("tenant)_id")!}/preauth/save`, preauth)

                if (status === 200) {
                    const result = await updateRaw(getTenantId(), { id: treatment.id, preauth_uuids: [...treatment.attributes.preauth_uuids, data.UUID] })

                    if (result.status === 200) {
                        dispatch(close())
                        dispatch(updated("preauth"))
                    }
                }
            } catch (e: any) {
                alert(e.response.data)
            }
        }

        save()

        setIsSaveWaiting(false)
    }

    if (!show || !preauth) {
        return null
    }

    const dateString = (str: any) => {
        return str !== null && str.length > 0
    }

    return <Modal show={show} onHide={() => { }} className={"preauth-modal"}>
        <Modal.Header>
            <ModalTitle>Edit Preauth</ModalTitle>
        </Modal.Header>
        <ModalBody>
            <Tabs defaultActiveKey={1} id="preauth-tabs" variant="pills">
                <Tab eventKey={1} title="Preauth">
                    <div style={{ marginTop: "15px" }}>

                        <Row>
                            <Col md={9}>
                                <FormGroup>
                                    <FormLabel>Modalities</FormLabel>
                                    <Select
                                        isMulti
                                        options={[
                                            "2D",
                                            "3D",
                                            "3D Boost",
                                            "Electron",
                                            "IGRT",
                                            "DIBH",
                                            "IMRT",
                                            "SRS",
                                            "SBRT",
                                            "CK – 3D",
                                            "CK – IMRT",
                                            "GK",
                                            "LDR",
                                            "HDR",
                                            "IORT",
                                            "XOFIGO",
                                            "Accuboost",
                                            "Sirspheres",
                                            "Eye Plaque",
                                            "Space Oars",
                                            "Superficial",
                                            "Fid Markers",
                                            "MRI",
                                            "CT",
                                            "PET",
                                            "MRA",
                                            "Bone Scan",
                                            "VS",
                                            "IMRT Boost",
                                            "Y-90",
                                        ].map(dx => { return { label: dx, value: dx } })}
                                        value={preauth.modalities.map((m: string) => { return { label: m, value: m } })}
                                        onChange={setModalities}
                                    />

                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <FormLabel>Fractions</FormLabel>
                                    <FormControl
                                        type="input"
                                        value={preauth.fractions}
                                        onChange={setFractions} />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <FormLabel>Diagnosis</FormLabel>
                            <SelectDiagnosis diagnosis={preauth.diagnosis} onChange={setDiagnosis} />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Authorization Number</FormLabel>
                            <FormControl
                                type="input"
                                value={preauth.authorizationNumber}
                                onChange={setAuthorizationNumber} />
                        </FormGroup>

                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Completed Benefit Check" checked={preauth.completedBenefitCheck} onClick={toggleCompletedBenefitCheck} />
                        </Form.Group>

                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <FormLabel>Simulation Date</FormLabel>
                                    <DatePicker
                                        className="form-control"
                                        selected={!dateString(preauth.simulationDate) ? null : moment(preauth.simulationDate.substring(0, 10)).toDate()}
                                        onChange={setSimulationDate}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={4} style={{ marginLeft: "15px" }}>
                                <FormGroup>
                                    <FormLabel>Treatment Start Date</FormLabel>
                                    <DatePicker
                                        className="form-control"
                                        selected={!dateString(preauth.treatmentStartDate) ? null : moment(preauth.treatmentStartDate.substring(0, 10)).toDate()}
                                        onChange={setTreatmentStartDate}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormLabel>Valid Date Range</FormLabel>
                            </Col>

                            <Col md={4}>
                                <DatePicker
                                    className="form-control"
                                    selected={!dateString(preauth.startDate) ? null : moment(preauth.startDate.substring(0, 10)).toDate()}
                                    selectsStart
                                    startDate={!dateString(preauth.startDate) ? null : moment(preauth.startDate.substring(0, 10)).toDate()}
                                    endDate={!dateString(preauth.endDate) ? null : moment(preauth.endDate.substring(0, 10)).toDate()}
                                    onChange={setStartDate}
                                />
                            </Col>
                            <Col md={4} style={{ marginLeft: "15px" }}>
                                <DatePicker
                                    className="form-control"
                                    selected={!dateString(preauth.endDate) ? null : moment(preauth.endDate.substring(0, 10)).toDate()}
                                    selectsEnd
                                    startDate={!dateString(preauth.startDate) ? null : moment(preauth.startDate.substring(0, 10)).toDate()}
                                    endDate={!dateString(preauth.endDate) ? null : moment(preauth.endDate.substring(0, 10)).toDate()}
                                    onChange={setEndDate}
                                    minDate={!dateString(preauth.startDate) ? null : moment(preauth.startDate.substring(0, 10)).toDate()}
                                />
                            </Col>
                        </Row>

                        <FormGroup>
                            <FormLabel>Status</FormLabel>
                            <SelectStatus statusUUID={preauth.statusUUID} onChange={setStatus} />
                        </FormGroup>
                    </div>
                </Tab>
                <Tab eventKey={2} title="Facility">
                    <div style={{ marginTop: "15px" }}>
                        <SelectFacility facility={preauth.facility} onChange={setFacility} />
                    </div>
                </Tab>
                <Tab eventKey={3} title="Insurance">
                    <div style={{ marginTop: "15px" }}>
                        <SelectInsurance insurance={preauth.insurance} onChange={setInsurance} />
                    </div>
                </Tab>
                <Tab eventKey={4} title="Physician">
                    <div style={{ marginTop: "15px" }}>
                        <SelectPhysician physician={preauth.physician} onChange={setPhysician} />
                    </div>
                </Tab>
                <Tab eventKey={5} title="Patient">
                    <div style={{ marginTop: "15px" }}>
                        <SelectPatient patient={preauth.patient} onChange={setPatient} />
                    </div>
                </Tab>
            </Tabs>
        </ModalBody>
        <ModalFooter>
            <Button variant="secondary" onClick={() => dispatch(close())}>Cancel</Button>
            <Button variant="primary" disabled={isSaveWaiting || !valid(preauth)} onClick={saveAndClose}>{isSaveWaiting ? "Saving..." : "Save"}</Button>
        </ModalFooter>
    </Modal>
}

export default EditPreauthModal