import React from "react"
import { Form } from "react-bootstrap"
import Select, { Props } from "react-select"

interface MySelectProps {
    items: any[]
    label?: string
    showAll?: boolean
    defaultId?: number | string
}

const MySelect = (props: MySelectProps & Props) => {
    let { items, label, showAll, defaultId, ...restProps } = props

    let defaultValue = null

    if (showAll === true) {
        items = [{ id: 0, attributes: { name: "All" } }, ...items]
        defaultValue = items[0].id
    }

    if (defaultId) {
        for (let item of items) {
            if (String(defaultId) === String(item.id)) {
                defaultValue = item
                break
            }
        }
    }

    return !label
        ? <Select
            options={items}

            defaultValue={defaultValue}

            getOptionLabel={(option: any) => option.attributes.name}
            getOptionValue={(option: any) => option.id}

            {...restProps}
        />
        : <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Select
                options={items}

                defaultValue={defaultValue}

                getOptionLabel={(option: any) => option.attributes.name}
                getOptionValue={(option: any) => option.id}

                {...restProps}
            />
        </Form.Group>
}

export default MySelect
