import React from "react"
import Navbar from "../../components/Navbar"
import NewPracticeModal from "./NewPracticeModal"
import { dispatchEvent } from "../../hooks/useEvent"
import { Practice, usePracticeList } from "../../core/entities/practice"



const PracticeList = () => {
    const { practices } = usePracticeList()

    const openNewPracticeModal = () => {
        dispatchEvent("openNewPracticeModal", {})
    }

    return <>
        <Navbar />
        <NewPracticeModal />
        <div className="body">
            <div style={{ padding: "1em" }}>
                <button className="btn btn-primary" onClick={openNewPracticeModal}>New Practice</button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        practices.map((p: Practice) => {
                            return <tr key={p.id}>
                                <td>{p.name}</td>
                                <td>{p.code}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
}

export default PracticeList