import { ChargeActions } from "./ChargeActions"

export enum ChargeMultiEditModalAction {
    OPENED = "ChargeMultiEditModalAction.OPENED"
    , CLOSED = "ChargeMultiEditModalAction.CLOSED"

    , SAVE_WAITING = "ChargeMultiEditModalAction.SAVE_WAITING"
    , SAVE_COMPLETED = "ChargeMultiEditModalAction.SAVE_COMPLETED"
    , SAVE_ERROR = "ChargeMultiEditModalAction.SAVE_ERROR"

    , SET_INSURANCE = "ChargeMultiEditModalAction.SET_INSURANCE"
    , SET_FACILITY = "ChargeMultiEditModalAction.SET_FACILITY"
    , SET_PHYSICIAN = "ChargeMultiEditModalAction.SET_PHYSICIAN"
}

export interface ChargeMultiEditModalPayload {
    type: ChargeMultiEditModalAction;
    data: any;
}

export class ChargeMultiEditModalActions {
    open(chargeIds: number[]) {
        return { type: ChargeMultiEditModalAction.OPENED, data: { chargeIds: chargeIds } };
    }

    close() {
        const chargeIds: number[] = [];
        return { type: ChargeMultiEditModalAction.CLOSED, data: { chargeIds: chargeIds } };
    }

    save(tenantId: number, chargeIds: number[], charge: any) {
        return (dispatch: (action: any) => void) => {
            dispatch({ type: ChargeMultiEditModalAction.SAVE_WAITING, data: {} });

            const chargeActions = new ChargeActions();

            const dispatchOnComplete: any[] = [
                { type: ChargeMultiEditModalAction.SAVE_COMPLETED, data: {} }
                , { type: ChargeMultiEditModalAction.CLOSED, data: {} }
            ];

            const dispatchOnError: any[] = [
                { type: ChargeMultiEditModalAction.SAVE_ERROR, data: {} }
            ];

            dispatch(chargeActions.updateMulti(tenantId, chargeIds, charge, dispatchOnComplete, dispatchOnError));
        }
    }

    setInsurance(insuranceId: number) {
        return { type: ChargeMultiEditModalAction.SET_INSURANCE, data: { insuranceId: insuranceId } };
    }

    setFacility(facilityId: number) {
        return { type: ChargeMultiEditModalAction.SET_FACILITY, data: { facilityId: facilityId } };
    }

    setPhysician(physicianId: number) {
        return { type: ChargeMultiEditModalAction.SET_PHYSICIAN, data: { physicianId: physicianId } };
    }
}