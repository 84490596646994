import React, { useEffect, useState } from "react"
import * as B from "react-bootstrap"
import { RTFJS } from 'rtf.js';

import Axios from "../common/axios"
import { getTenantId } from "../common/jwt"
import { useTokens } from "../useAuthorizationCodeFlow"

import ChargeCell from "./ChargeCell"

const useDocument = (documentId: number) => {
    const [doc, setDoc] = useState<HTMLElement | undefined>()
    const { getAccessToken } = useTokens()

    useEffect(() => {
        const fetchRtf = async () => {
            const stringToArrayBuffer = (str: string) => {
                const buffer = new ArrayBuffer(str.length);
                const bufferView = new Uint8Array(buffer);
                for (let i = 0; i < str.length; i++) {
                    bufferView[i] = str.charCodeAt(i);
                }
                return buffer;
            }

            RTFJS.loggingEnabled(false)

            const resp = await Axios.get(`/api/${getTenantId()}/document/rtf/${documentId}`)
            const d = new RTFJS.Document(stringToArrayBuffer(resp.data), {});

            const div = document.createElement("div")
            div.append(...await d.render())

            setDoc(div)
        }

        if (documentId) {
            fetchRtf()
        }

    }, [documentId, getAccessToken])

    return {
        doc
    }
}

export interface DocumentHTMLProps {
    documentId: number

    charges: any[]
    chargeIncluded: any[]
}

const DocumentHTML = (props: DocumentHTMLProps) => {
    const { doc } = useDocument(props.documentId)

    let docHtml: string = ""
    if (doc?.innerHTML) {
        docHtml = doc?.innerHTML
    }

    const chargesForDocument = props.charges.filter((charge: any) => {
        return charge.meta.document_id === props.documentId
    })

    return <B.Row style={{ padding: 0, margin: 0 }}>
        <B.Col md={9} style={{ padding: 0, margin: 0 }}>
            <div dangerouslySetInnerHTML={{ __html: docHtml }}></div>
        </B.Col>
        <B.Col md={3} style={{ padding: 0, margin: 0, height: "100%", background: "rgb(245, 245, 245)" }}>
            <B.Table bordered hover>
                <thead>
                    <tr>
                        <th>DOS</th>
                        <th>CPT</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        chargesForDocument.map((charge: any) => {
                            return <tr key={charge.id}>
                                <td>{charge.attributes.dos}</td>
                                <ChargeCell charge={charge} />
                            </tr>
                        })
                    }
                </tbody>
            </B.Table>

        </B.Col>
    </B.Row>
}

export default DocumentHTML
