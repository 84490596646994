export enum ChargeMultiEditAction {
    CHARGE_MULTI_EDIT_SELECT_ONE_CHARGE = "ChargeMultiEditAction.CHARGE_MULTI_EDIT_SELECT_ONE_CHARGE"
    , CHARGE_MULTI_EDIT_UNSELECT_ONE_CHARGE = "ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_ONE_CHARGE"

    , CHARGE_MULTI_EDIT_SELECT_MULTIPLE_CHARGES = "ChargeMultiEditAction.CHARGE_MULTI_EDIT_SELECT_MULTIPLE_CHARGES"
    , CHARGE_MULTI_EDIT_UNSELECT_MULTIPLE_CHARGES = "ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_MULTIPLE_CHARGES"

    , CHARGE_MULTI_EDIT_UNSELECT_ALL = "ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_ALL"
}

export interface ChargeMultiEditPayload {
    type: ChargeMultiEditAction;
    chargeId?: number;
    chargeIds?: number[];
}

export class ChargeMultiEditActions {
    select(chargeId: number) {
        return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_SELECT_ONE_CHARGE, chargeId: chargeId };
    }

    unselect(chargeId: number) {
        return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_ONE_CHARGE, chargeId: chargeId };
    }

    selectMultiple(chargeIds: number[]) {
        return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_SELECT_MULTIPLE_CHARGES, chargeIds: chargeIds };
    }

    unselectMultiple(chargeIds: number[]) {
        return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_MULTIPLE_CHARGES, chargeIds: chargeIds };
    }

    unselectAll() {
        return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_ALL };
    }
}

export const select = (chargeId: number) => {
    return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_SELECT_ONE_CHARGE, chargeId: chargeId };
}

export const unselect = (chargeId: number) => {
    return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_ONE_CHARGE, chargeId: chargeId };
}

export const selectMultiple = (chargeIds: number[]) => {
    return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_SELECT_MULTIPLE_CHARGES, chargeIds: chargeIds };
}

export const unselectMultiple = (chargeIds: number[]) => {
    return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_MULTIPLE_CHARGES, chargeIds: chargeIds };
}

export const unselectAll = () => {
    return { type: ChargeMultiEditAction.CHARGE_MULTI_EDIT_UNSELECT_ALL };
}