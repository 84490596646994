import React, { useState, useEffect } from "react"
import Select from "react-select"

import Axios from "../../../common/axios"

export interface Status {
    UUID: string
    name: string
    bootstrapStyle: string
}

export interface StatusOption {
    value: string
    label: string
}

export interface SelectStatusProps {
    statusUUID: string
    onChange: (statusUUID: string, status: Status | undefined) => void
    additionalOptions?: StatusOption[]
}


const SelectStatus = (props: SelectStatusProps) => {
    const { statusUUID, onChange, additionalOptions } = props
    const [statuses, setStatuses] = useState([] as Status[])
    const [statusOptions, setStatusOptions] = useState([] as StatusOption[])

    useEffect(() => {
        const fetch = async () => {
            const res = await Axios.get("/remote-preauth/api/status")

            const { status, data } = res

            if (status === 200) {
                setStatuses(data)
                setStatusOptions(data.filter((s: Status) => s.name !== "Open").map((s: Status) => {
                    return {
                        value: s.UUID
                        , label: s.name
                    }
                }))
            }
        }
        
        fetch()
    }, [])
    
    const handleSelectStatus = (option: any) => {
        onChange(option.value, statuses.find((s: Status) => s.UUID === option.value))
    }

    const value = statusOptions.find((option: any) => option.value === statusUUID)

    console.log("statuses:", statusOptions)
    console.log("status uuid:", statusUUID)
    console.log("status value:", value)

    return <Select
        options={additionalOptions ? additionalOptions.concat(statusOptions) : statusOptions}

        value={value}

        onChange={handleSelectStatus}
    />
}

export default SelectStatus