import Axios from "../common/axios";
import { AppState } from '../reducers';


enum Type {
    WAITING = "FETCH_DOCUMENT_STATUS_WAITING"
    , COMPLETED = "FETCH_DOCUMENT_STATUS_COMPLETED"
    , ERROR = "FETCH_DOCUMENT_STATUS_ERROR"
}

interface Action {
    type: Type
    payload: any
}

export const fetch = () => async (dispatch: (action: Action) => Action, getState: () => AppState) => {
    const state = getState().documentStatusList

    if (state.loading || state.fetched) {
        return
    }

    const uri = "/api/document-status"
    const cache = sessionStorage.getItem(uri)

    if (cache) {
        return dispatch({ type: Type.COMPLETED, payload: { data: JSON.parse(cache) } })
    }

    dispatch({ type: Type.WAITING, payload: {} })

    try {
        const { data, request } = await Axios.get(uri, {})

        if (request.responseURL.endsWith("/login")) {
            throw new Error("redirected to /login")
        }

        sessionStorage.setItem(uri, JSON.stringify(data))

        dispatch({ type: Type.COMPLETED, payload: { data } })
    } catch (error) {
        dispatch({ type: Type.ERROR, payload: { error } })
    }
}

export interface DocumentStatusState {
    loading: boolean
    fetched: boolean

    data: any[]
}

const reducer = (prevState: DocumentStatusState, action: Action): DocumentStatusState => {
    const state = { ...prevState }

    switch (action.type) {
        case Type.WAITING:
            state.loading = true
            break
        case Type.COMPLETED:
            state.loading = false
            state.fetched = true

            const { data } = action.payload.data

            state.data = [...data]
            break
    }

    return state
}

export default reducer