import React from "react"
import { connect } from "react-redux"
import { Props as SelectProps } from "react-select"
import Select from "./Select"
import { AppState } from "../../reducers"

interface Props {
	onChangeUser?: (user: any) => void
	withLabel?: boolean
}

interface StateProps {
	users: any[]
}

const UserSelect = (props: Props & StateProps & SelectProps) => {
	const { users, onChangeUser, withLabel, ...restProps } = props

	const handleChange = (user: any) => {
		if (onChangeUser) {
			onChangeUser(user)
		}
	}

	return <Select items={users} label={withLabel ? "Assignee" : ""} onChange={handleChange} {...restProps} />
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => {
	return {
		users: state.user.data
	}
}

export default connect(mapStateToProps)(UserSelect)