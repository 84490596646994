import moment from 'moment'
import * as _ from "lodash"

import { ChargeModalPayload, ChargeModalAction } from "../../actions/modal/ChargeModalActions"
// import { Identifiable } from '../../components/modal/ChargeModal';

// const defaultCharge: any = {
//     id: null
//     , attributes: {
//         units: 1
//         , note: ""
//         , dos: null
//         , ordinal: 1
//         , flags: 0
//     }
//     , relationships: {
//         phase: { data: { id: null } }
//         , cpt: { data: { id: null } }
//         , modifiers: {
//             mod1: { data: { id: null } }
//             , mod2: { data: { id: null } }
//             , mod3: { data: { id: null } }
//             , mod4: { data: { id: null } }
//         }
//         , physician: { data: { id: 1 } }
//         , insurance: { data: { id: 1 } }
//         , facility: { data: { id: 1 } }
//         , practice: { data: { id: null } }
//         , document: { data: { id: null } }
//         , status: { data: { id: 0 } }
//         , diagnosis: {
//             data: [
//                 { id: null, icd10_id: null }
//             ]
//         }
//         , mipsreport: {
//             data: [
//                 { id: null, measure_id: null, numerator_id: null }
//             ]
//         }
//     }
//     , dos: [
//         { date: null, ordinal: 1 }
//     ]
// }

const defaultCharge = (treatment: any) => {
    return {
        id: null
        , attributes: {
            units: 1
            , note: ""
            , dos: null
            , ordinal: 1
            , flags: 0
            , diagnosis: [...treatment.attributes.diagnosis]
        }
        , relationships: {
            phase: { data: { id: treatment.relationships.phase.data[treatment.relationships.phase.data.length - 1].id } }
            , cpt: { data: { id: null } }
            , modifiers: {
                mod1: { data: { id: null } }
                , mod2: { data: { id: null } }
                , mod3: { data: { id: null } }
                , mod4: { data: { id: null } }
            }
            , physician: { data: { id: 1 } }
            , insurance: { data: { id: treatment.relationships.insurance.data.id } }
            , facility: { data: { id: treatment.relationships.facility.data.id } }
            , practice: { data: { id: null } }
            , document: { data: { id: null } }
            , status: { data: { id: 0 } }
            , mipsreport: {
                data: [
                    { id: null, measure_id: null, numerator_id: null }
                ]
            }
        }
        , dos: [
            { date: null, ordinal: 1 }
        ]
    }
}

const defaultValidation: any = {
    phase: null
    , code: null
    , units: null
    , physician: null
    , insurance: null
    , facility: null
    , practice: null
    , dos: null
    , diagnosis: null
}

interface Identifiable {
    id: number
    lid?: number
}

export interface State {
    show: boolean
    charge: any

    cpt: any

    validation: any
    validationErrorMessage: string

    waiting: boolean
}

export const defaultState: State = {
    show: false
    , charge: defaultCharge

    , cpt: null

    , validation: defaultValidation
    , validationErrorMessage: ""

    , waiting: false
}

export const reducer = (state: State, payload: ChargeModalPayload): State => {
    const nextState = _.cloneDeep(state)

    switch (payload.type) {
        case ChargeModalAction.OPENED:
            nextState.show = true

            nextState.validation = { ...defaultValidation }
            nextState.validationErrorMessage = ""

            if (payload.data.charge) {
                nextState.charge = _.cloneDeep(payload.data.charge)
            } else {
                nextState.charge = _.cloneDeep(defaultCharge(payload.data.treatment))

                if (payload.data.document) {
                    nextState.charge.relationships.document.data.id = payload.data.document.id
                }
            }
            break
        case ChargeModalAction.CLOSED:
            nextState.show = false
            break

        case ChargeModalAction.DUPLICATED:
            nextState.charge.id = null
            nextState.charge.attributes.flags = 0

            nextState.charge.dos = [
                { date: nextState.charge.attributes.dos, ordinal: nextState.charge.attributes.ordinal }
            ]

            for (let diagnosis of nextState.charge.relationships.diagnosis.data) {
                diagnosis.id = null
            }

            for (let mipsreport of nextState.charge.relationships.mipsreport.data) {
                mipsreport.id = null
            }
            break

        case ChargeModalAction.VALIDATION_ERROR:
            nextState.validation = { ...payload.data.validation }
            nextState.waiting = false
            break
        case ChargeModalAction.VALIDATION_ERROR_MESSAGE:
            nextState.validationErrorMessage = payload.data.validationErrorMessage
            nextState.waiting = false
            break

        case ChargeModalAction.SAVE_WAITING:
            nextState.waiting = true
            break
        case ChargeModalAction.SAVE_COMPLETED:
        case ChargeModalAction.SAVE_ERROR:
            nextState.waiting = false
            break


        // attributes
        case ChargeModalAction.SET_UNITS:
            nextState.charge.attributes.units = payload.data.units
            nextState.validation.units = null
            break
        case ChargeModalAction.SET_NOTE:
            nextState.charge.attributes.note = payload.data.note
            break

        case ChargeModalAction.SET_IS_PLACEHOLDER:
            nextState.charge.attributes.flags |= 0x8
            break

        // relationships
        case ChargeModalAction.SET_STATUS:
            nextState.charge.relationships.status.data.id = payload.data.statusId
            break
        case ChargeModalAction.SET_PHASE:
            nextState.charge.relationships.phase.data.id = payload.data.phaseId
            nextState.validation.phase = null
            break
        case ChargeModalAction.SET_CPT:
            nextState.charge.relationships.cpt.data.id = payload.data.cpt.id
            nextState.cpt = payload.data.cpt

            if (nextState.cpt.attributes.isPlaceholder) {
                nextState.charge.relationships.status.data.id = 1
            }

            nextState.validation.code = null
            break
        case ChargeModalAction.SET_MOD1:
            nextState.charge.relationships.modifiers.mod1.data = { ...{ id: payload.data.modId } }
            break
        case ChargeModalAction.SET_MOD2:
            nextState.charge.relationships.modifiers.mod2.data = { ...{ id: payload.data.modId } }
            break
        case ChargeModalAction.SET_MOD3:
            nextState.charge.relationships.modifiers.mod3.data = { ...{ id: payload.data.modId } }
            break
        case ChargeModalAction.SET_MOD4:
            nextState.charge.relationships.modifiers.mod4.data = { ...{ id: payload.data.modId } }
            break
        case ChargeModalAction.SET_INSURANCE:
            nextState.charge.relationships.insurance.data.id = payload.data.insuranceId
            nextState.validation.insurance = null
            break
        case ChargeModalAction.SET_PHYSICIAN:
            nextState.charge.relationships.physician.data.id = payload.data.physicianId
            nextState.validation.physician = null
            break
        case ChargeModalAction.SET_FACILITY:
            nextState.charge.relationships.facility.data.id = payload.data.facilityId
            nextState.validation.facility = null
            break
        case ChargeModalAction.SET_PRACTICE:
            nextState.charge.relationships.practice.data.id = payload.data.practiceId
            nextState.validation.practice = null
            break
        case ChargeModalAction.SET_DOCUMENT:
            nextState.charge.relationships.document.data.id = payload.data.documentId
            break

        // relationships (array)
        case ChargeModalAction.SET_DOS:
            if (nextState.charge.id) {
                nextState.charge.attributes.dos = payload.data.date
            } else {
                nextState.charge.dos[payload.data.index].date = payload.data.date
            }

            nextState.validation.dos = null
            break
        case ChargeModalAction.SET_ORDINAL:
            if (nextState.charge.id) {
                nextState.charge.attributes.ordinal = payload.data.ordinal
            } else {
                nextState.charge.dos[payload.data.index].ordinal = payload.data.ordinal
            }
            break
        case ChargeModalAction.SET_MIPS_MEASURE:
            nextState.charge.relationships.mipsreport.data[payload.data.index].measure_id = payload.data.measureId
            break
        case ChargeModalAction.SET_MIPS_NUMERATOR:
            nextState.charge.relationships.mipsreport.data[payload.data.index].numerator_id = payload.data.numeratorId
            break
        case ChargeModalAction.SET_DIAGNOSIS:
            nextState.charge.relationships.diagnosis.data[payload.data.index].icd10_id = payload.data.icd10Id
            nextState.validation.diagnosis = null
            break

        // relationships added
        case ChargeModalAction.ADDED_DOS:
            const date = moment(nextState.charge.dos[nextState.charge.dos.length - 1].date).add(1, "day").format("MM/DD/YYYY")

            nextState.charge.dos.push({ date: date, ordinal: 1 })
            break
        case ChargeModalAction.ADDED_DIAGNOSIS:
            const max = (sum: number, elt: Identifiable) => {
                if (elt.id > sum) {
                    return elt.id
                }

                if (elt.lid! > sum) {
                    return elt.lid
                }

                return sum
            }

            const maxLid = nextState.charge.relationships.diagnosis.data.reduce(max, 0)
            nextState.charge.relationships.diagnosis.data.push({ id: null, lid: maxLid + 1, icd10_id: null })
            break
        case ChargeModalAction.ADDED_MIPS_REPORT:
            nextState.charge.relationships.mipsreport.data.push({ id: null, measure_id: null, numerator_id: null })
            break


        case ChargeModalAction.REMOVED_DIAGNOSIS:
            nextState.charge.relationships.diagnosis.data.splice(payload.data.index, 1)
            break
    }

    return nextState
}

