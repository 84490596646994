import React, { useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import Navbar from "./components/Navbar"
import { useEvent, dispatchEvent } from "./hooks/useEvent"

import { DX, useDXList } from "./core/entities/dx"

const NewDXModal = () => {
    const [isOpen, setIsOpen] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)
    const [err, setErr] = useState("")

    useEvent("openNewDXModal", () => {
        setErr("")
        setIsOpen(true)
    })

    const handleCreateDX = async () => {
        const code = formRef?.current?.querySelector<HTMLInputElement>(`input[name="code"]`)?.value
        const description = formRef?.current?.querySelector<HTMLInputElement>(`input[name="description"]`)?.value

        const res = await fetch(`/v2/dx`, { method: "POST", body: JSON.stringify({ code, description }) })
        const json = await res.json()

        if ("error" in json) {
            setErr(json.error)
        } else {
            setIsOpen(false)
        }
    }

    return <Modal show={isOpen}>
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">New DX</h5>
            </div>
            <div className="modal-body">
                {
                    err
                        ? <div className="alert alert-danger">{err}</div>
                        : null
                }
                <p>A new dx code may take a few minutes to appear in dropdown lists due to multiple caching layers.</p>
                <form ref={formRef}>
                    <div className="mb-3">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" name="code" />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Description</label>
                        <input type="text" className="form-control" name="description" />
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={() => { setIsOpen(false) }}>Cancel</button>
                <button className="btn btn-primary" onClick={() => { handleCreateDX() }}>Create</button>
            </div>
        </div>
    </Modal>
}

const DXList = () => {
    const { dxs, err } = useDXList()

    return <>
        <Navbar />
        <NewDXModal />
        <div className="body">
            {err}
            <div style={{ padding: "1em" }}>
                <button className="btn btn-primary" onClick={() => { dispatchEvent("openNewDXModal", {}) }}>New DX</button>
            </div>
            <table className="table">
                <thead>
                    <th>Code</th>
                    <th>Description</th>
                </thead>
                <tbody>
                    {
                        dxs.map((dx: DX) => {
                            return <tr key={dx.id}>
                                <td>{dx.code}</td>
                                <td>{dx.description}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
}

export default DXList