// "Static"
import facilityReducer, { FacilityState } from "../actions/FacilityActions"
import physicianReducer, { PhysicianState } from "../actions/PhysicianActions"
import practiceReducer, { PracticeState } from "../actions/PracticeActions"
import cptReducer, { CPTState } from "../actions/CPTActions"
import referringPhysicianReducer, { ReferringPhysicianState } from "../actions/ReferringPhysicianActions"
import cptModifierReducer, { CPTModifierState } from "../actions/CPTModifierActions"
import chargeStatusReducer, { ChargeStatusState } from "../actions/ChargeStatusActions"
import documentStatusReducer, { DocumentStatusState } from "../actions/DocumentStatusActions"
import documentTypeListReducer, { DocumentTypeState } from "../actions/DocumentTypeActions"
import insuranceReducer, { InsuranceState } from "../actions/InsuranceActions"
import mipsMeasureReducer, { MIPSMeasureState } from "../actions/MIPSMeasureActions"
import icd10Reducer, { ICD10State } from "../actions/ICD10Actions"
import modalityReducer, { ModalityState } from "../actions/ModalityActions"
import treatmentStatusReducer, { TreatmentStatusState } from "../actions/TreatmentStatusActions"
import userReducer, { UserState } from "../actions/UserActions"

// Business
import treatmentReducer, { TreatmentState, defaultTreatmentState } from "../actions/TreatmentActions"

import chargeReducer, { ChargeState, defaultChargeState } from "./ChargeReducer"
import phaseReducer, { PhaseState } from "./PhaseReducer"
import * as chargeModal from "./modal/ChargeModalReducer"
import chargeMultiEditReducer, { ChargeMultiEditState } from "./ChargeMultiEditReducer"
import notificationListReducer, { NotificationListState } from "./NotificationListReducer"
import documentReducer, { State as DocumentState } from "./DocumentReducer"
import preauthReducer, { PreauthState, defaultPreauthState } from "./preauth/PreauthReducer"
import eventTimesReducer from "../actions/EventTimeActions"

// Modal 
import modalReducer, { ModalState } from "../actions/ModalActions"
import chargeMultiEditModalReducer, { ChargeMultiEditModalState } from "./ChargeMultiEditModalReducer"
import documentEditModalReducer, { DocumentEditModalState } from "./DocumentEditModalReducer"
import documentSetStatusModalReducer, { DocumentSetStatusModalState } from "./modal/DocumentSetStatusModalReducer"
import chargeSetStatusModalReducer, { ChargeSetStatusModalState } from "./modal/ChargeSetStatusModalReducer"
import * as newReferringPhysicianModal from "./modal/NewReferringPhysician"
import * as newICD10Modal from "./modal/NewICD10"

// New way of doing things
import { defaultListState } from "../reducers/ListState"

export interface AppState {
    // "Static"
    modality: ModalityState
    facility: FacilityState
    mipsMeasure: MIPSMeasureState
    physician: PhysicianState
    referringPhysician: ReferringPhysicianState
    practice: PracticeState
    insurance: InsuranceState
    cpt: CPTState
    cptModifier: CPTModifierState
    icd10: ICD10State
    documentStatusList: DocumentStatusState
    documentTypeList: DocumentTypeState
    chargeStatus: ChargeStatusState
    treatmentStatus: TreatmentStatusState
    user: UserState

    // Business
    treatment: TreatmentState
    charge: ChargeState
    chargeMultiEdit: ChargeMultiEditState
    phase: PhaseState

    // Modal
    modal: ModalState
    chargeModal: chargeModal.State
    chargeMultiEditModal: ChargeMultiEditModalState
    documentEditModal: DocumentEditModalState
    documentSetStatusModal: DocumentSetStatusModalState
    chargeSetStatusModal: ChargeSetStatusModalState
    newICD10Modal: newICD10Modal.State

    notificationList: NotificationListState
    document: DocumentState


    newReferringPhysicianModal: newReferringPhysicianModal.State

    // New way of doing things
    preauth: PreauthState

    eventTimes: {
        patient: string[]
        treatment: string[]
        charge: string[]
        preauth: string[]
    }
}


let defaultState: AppState = {
    // "Static"
    modality: defaultListState
    , facility: defaultListState
    , mipsMeasure: defaultListState
    , physician: defaultListState
    , referringPhysician: { data: [], included: [], loading: false, fetched: false, search: "" }
    , practice: defaultListState
    , insurance: defaultListState
    , cpt: defaultListState
    , cptModifier: defaultListState
    , icd10: { data: [], included: [], loading: false, fetched: false, query: "" }
    , chargeStatus: { data: [], loading: false, fetched: false }

    , treatment: defaultTreatmentState
    , charge: defaultChargeState
    , chargeModal: chargeModal.defaultState
    , phase: { data: [], included: [], filter: {}, loading: false, fetched: false }
    , chargeMultiEdit: { chargeIds: [] }
    , chargeMultiEditModal: { show: false, chargeIds: [], charge: {} }

    , user: defaultListState
    , notificationList: { data: [] }
    , document: { data: [], included: [], selectedId: 0, loading: false, fetched: false, filter: {}, pagination: { limit: 0, offset: 0, count: 0 } }
    , documentEditModal: { show: false, documentId: 0 }


    , documentSetStatusModal: { show: false, documentId: 0 }
    , chargeSetStatusModal: { show: false, chargeId: 0 }

    , treatmentStatus: { data: [], loading: false, fetched: false }

    , newReferringPhysicianModal: newReferringPhysicianModal.defaultState
    , newICD10Modal: newICD10Modal.defaultState

    // New way of doing things
    , documentStatusList: defaultListState
    , documentTypeList: defaultListState
    , modal: { isOpen: false, modalIdentifier: "", modalProps: {} }
    , preauth: defaultPreauthState
    , eventTimes: {
        patient: []
        , treatment: []
        , charge: []
        , preauth: []
    }

}

const reducer = (state: AppState = defaultState, payload: any): AppState => {
    const nextState: AppState = {
        modality: modalityReducer(state.modality, payload)
        , facility: facilityReducer(state.facility, payload)
        , mipsMeasure: mipsMeasureReducer(state.mipsMeasure, payload)
        , physician: physicianReducer(state.physician, payload)
        , referringPhysician: referringPhysicianReducer(state.referringPhysician, payload)
        , practice: practiceReducer(state.practice, payload)
        , insurance: insuranceReducer(state.insurance, payload)
        , treatment: treatmentReducer(state.treatment, payload)
        , charge: chargeReducer(state.charge, payload)
        , chargeMultiEdit: chargeMultiEditReducer(state.chargeMultiEdit, payload)
        , phase: phaseReducer(state.phase, payload)
        , cpt: cptReducer(state.cpt, payload)
        , cptModifier: cptModifierReducer(state.cptModifier, payload)
        , icd10: icd10Reducer(state.icd10, payload)
        , chargeModal: chargeModal.reducer(state.chargeModal, payload)
        , chargeMultiEditModal: chargeMultiEditModalReducer(state.chargeMultiEditModal, payload)
        , user: userReducer(state.user, payload)
        , notificationList: notificationListReducer(state.notificationList, payload)
        , document: documentReducer(state.document, payload)
        , documentEditModal: documentEditModalReducer(state.documentEditModal, payload)
        , documentSetStatusModal: documentSetStatusModalReducer(state.documentSetStatusModal, payload)
        , chargeSetStatusModal: chargeSetStatusModalReducer(state.chargeSetStatusModal, payload)
        , chargeStatus: chargeStatusReducer(state.chargeStatus, payload)
        , treatmentStatus: treatmentStatusReducer(state.treatmentStatus, payload)
        , newReferringPhysicianModal: newReferringPhysicianModal.reducer(state.newReferringPhysicianModal, payload)
        , newICD10Modal: newICD10Modal.reducer(state.newICD10Modal, payload)

        // New way of doing things
        , documentStatusList: documentStatusReducer(state.documentStatusList, payload)
        , documentTypeList: documentTypeListReducer(state.documentTypeList, payload)
        , modal: modalReducer(state.modal, payload)
        , preauth: preauthReducer(state.preauth, payload)
        , eventTimes: eventTimesReducer(state.eventTimes, payload)
    }

    return nextState
}

export default reducer